import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'

import { Box, MarginBoxProps, NumberValues } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { Text, TextSize } from '../../base/Text'
import { ThemeVariant } from '../../base/ThemeVariant'

interface LabeledTextElementProps extends SeleniumProps, MarginBoxProps {
    label: ReactNode
    variant?: ThemeVariant
    size?: LabeledTextElementSize
    reversed?: boolean
    spacing?: NumberValues
}

export enum LabeledTextElementSize {
    Small,
    Large,
}

const fontSizes: Record<LabeledTextElementSize, { top: TextSize; bottom: TextSize }> = {
    [LabeledTextElementSize.Small]: {
        top: 12,
        bottom: 12,
    },
    [LabeledTextElementSize.Large]: {
        top: 14,
        bottom: 13,
    },
}

const fontColors: Record<ThemeVariant, { top: Color; bottom: Color }> = {
    [ThemeVariant.White]: {
        top: Color.Black,
        bottom: Color.Grey,
    },
    [ThemeVariant.Black]: {
        top: Color.White,
        bottom: Color.WidgetSecondary,
    },
}

export const LabeledTextElement: FCWithChildren<PropsWithChildren<LabeledTextElementProps>> = ({
    label,
    children,
    variant = ThemeVariant.White,
    size = LabeledTextElementSize.Large,
    reversed = false,
    spacing = 2,
    ...margins
}) => {
    const topColor = reversed ? fontColors[variant].bottom : fontColors[variant].top
    const bottomColor = reversed ? fontColors[variant].top : fontColors[variant].bottom
    return (
        <Box as='section' {...margins}>
            <Text
                wordBreak
                weight='regular'
                size={fontSizes[size].top}
                lineHeight='large'
                color={topColor}
                selectable
            >
                {label}
            </Text>
            {children && (
                <Text
                    paddingTop={spacing}
                    wordBreak
                    weight='regular'
                    lineHeight='large'
                    size={fontSizes[size].bottom}
                    color={bottomColor}
                    selectable
                >
                    {children}
                </Text>
            )}
        </Box>
    )
}
