import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { getPrivacyPolicyUrl } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import zod from 'zod'

import { removeAccount } from '../../core/removeAccount/actions'
import { RemoveAccountDialog } from './RemoveAccountDialog'

export const DeleteAccount = () => {
    const form = useForm({
        resolver: zodResolver(
            zod.object({
                email: zod
                    .string({
                        required_error: translate('remove-account.delete-account.required-email'),
                    })
                    .email({ message: translate('remove-account.delete-account.invalid-email') }),
            }),
        ),
    })
    const history = useHistory()
    const dispatch = useDispatch()

    const onSubmit = (email: string) => {
        dispatch(removeAccount(email))
    }

    return (
        <RemoveAccountDialog
            title={translate('remove-account.delete-account.title')}
            description={translate('remove-account.delete-account.description')}
        >
            <Box column rowGap={24}>
                <Controller
                    name='email'
                    render={({ field, fieldState }) => (
                        <>
                            <CleanableInput
                                {...field}
                                onClear={() => field.onChange('')}
                                placeholder={translate('remove-account.delete-account.placeholder')}
                            />
                            {fieldState.error && (
                                <Text size={14} color={Color.Red}>
                                    {fieldState.error.message}
                                </Text>
                            )}
                        </>
                    )}
                    control={form.control}
                />

                <Box alignContent='flex-start' columnGap={12} row>
                    <SvgImage style={{ minWidth: 20 }} svg={SvgIcon.InfoCircle} />
                    <Box row>
                        <Text size={14} color={Color.ToastFont}>
                            {translate('remove-account.delete-account.info')}
                            <Link target='_blank' href={getPrivacyPolicyUrl()}>
                                {translate('remove-account.delete-account.link')}
                            </Link>
                        </Text>
                    </Box>
                </Box>
                <Box justify='center' row columnGap={12}>
                    <FormButton
                        onClick={() => {
                            history.push('/')
                        }}
                        label={translate('remove-account.delete-account.cancel')}
                        variant={FormButtonVariant.GreyOutlined}
                        fullWidth
                    />
                    <FormButton
                        onClick={() => {
                            form.handleSubmit(data => {
                                onSubmit(data.email)
                            })()
                        }}
                        variant={FormButtonVariant.Primary}
                        label={translate('remove-account.delete-account.button')}
                        fullWidth
                    />
                </Box>
            </Box>
        </RemoveAccountDialog>
    )
}

const Link = styled.a`
    color: ${Color.LinkText};
    padding-left: 4px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`
