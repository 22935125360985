import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { getDelaysHelpConfig } from '../../help/delays/delaysHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Delays } from './Delays'
import { DelaysTile } from './DelaysTile'

export const getDelaysConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 3, h: 3 },
            maxSize: { w: 4, h: 4 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 6 },
            maxSize: { w: 12, h: 12 },
        },
    },
    renderer: Delays,
    tileRenderer: DelaysTile,
    helpConfig: getDelaysHelpConfig(),
})
