import { cx } from 'aos-components/src/components/base/ClassNames'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PropsWithChildren, PureComponent } from 'react'
import styled from 'styled-components'

export class BaseNotificationItem extends PureComponent<
    PropsWithChildren<BaseNotificationItemProps>
> {
    public render() {
        const { title, children, leftColumn, read, onClick } = this.props
        return (
            <BorderBox
                maxFullWidth
                className={cx(
                    'notification-list-item padding-horizontal--x-large padding-vertical--large',
                    'container container--horizontal container--align-start',
                    read ? 'read' : 'not-read',
                )}
                style={{
                    backgroundColor: read ? 'transparent' : Color.BackgroundPrimary,
                    cursor: onClick ? 'pointer' : 'default',
                }}
                onClick={onClick}
            >
                {leftColumn && (
                    <Box className='notification-list-item__l-column' shrink={0}>
                        {leftColumn}
                    </Box>
                )}
                <Text className='text-box' flex={1} lineHeight='standard'>
                    <Text color={Color.White} size={14}>
                        {title}
                    </Text>
                    <Text color={Color.WidgetSecondary} size={12}>
                        {children}
                    </Text>
                </Text>
            </BorderBox>
        )
    }
}

interface BaseNotificationItemProps {
    title: string | JSX.Element
    read: boolean
    leftColumn?: JSX.Element
    onClick?(): void
}

const BorderBox = styled(Box)`
    border-bottom: 1px solid ${Color.WidgetSecondary};
    &.not-read::before {
        display: block;
        position: absolute;
        top: 24px;
        right: 30px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${Color.Primary};
        content: '';
    }
`
