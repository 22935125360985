import { RunwayMode } from 'aos-services/src/services/runways/types/RunwayMode'
import React, { PureComponent } from 'react'

export class RunwayArrow extends PureComponent<RunwayArrowProps> {
    public render() {
        const visualConfig = this.visualConfig
        return (
            <path
                transform={`translate(0, ${this.props.top}) ${this.props.transform ?? ''}`}
                d={this.path}
                fill={visualConfig[0]}
                stroke={visualConfig[1]}
                strokeWidth={visualConfig[2]}
            />
        )
    }

    private get visualConfig(): [string, string, number] {
        switch (this.props.mode) {
            case RunwayMode.ARRIVAL:
                return ['#FF6D00', 'none', 0]

            case RunwayMode.DEPARTURE:
                return ['#417EFF', 'none', 0]

            default:
                return ['none', '#417EFF', 2]
        }
    }

    private get path() {
        const mode = this.props.mode
        const inversed = this.props.inversed
        const filled = mode === RunwayMode.ARRIVAL || mode === RunwayMode.DEPARTURE

        if (filled && !inversed) {
            return 'M12 12L24 0H0z'
        } else if (filled && inversed) {
            return 'M12 0l12 12H0z'
        } else if (!filled && !inversed) {
            return 'M12 10.586L21.586 1H2.414L12 10.586z'
        } else {
            return 'M12 1.414L21.586 11H2.414L12 1.414z'
        }
    }
}

interface RunwayArrowProps {
    mode: RunwayMode
    inversed: boolean
    top: number
    transform?: string
}
