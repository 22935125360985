import { cx } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

export class TrainTimeTableLegend extends PureComponent {
    public render() {
        return (
            <Box paddingBottom={40}>
                <Text paddingBottom={20} color={Color.White} size={12}>
                    {translate('train-details.timetable.legend')}
                </Text>
                {this.renderLegendItem(
                    'blue',
                    translate('train-details.timetable.unknown-position'),
                )}
                {this.renderLegendItem('red', translate('train-details.timetable.delayed'))}
                {this.renderLegendItem('orange', translate('train-details.timetable.risk'))}
                {this.renderLegendItem('green', translate('train-details.timetable.on-time'))}
            </Box>
        )
    }

    private renderLegendItem = (itemColorClass: string, itemLabel: string) => (
        <Box row paddingBottom={8}>
            <Box marginRight={8}>
                <Box
                    className={cx(
                        'train-information__delay-marker',
                        'train-information__delay-marker--small',
                        `train-information__delay-marker--${itemColorClass}`,
                    )}
                />
            </Box>
            <Text textAlign='left' color={Color.TextSecondary} size={12}>
                {itemLabel}
            </Text>
        </Box>
    )
}
