import {
    DashboardItemStatuses,
    getStatusForItemTypeAndHealth,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import {
    getHealthForItemType,
    WidgetHealths,
} from 'aos-services/src/services/widgetHealths/type/DashboardItemHealths'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'

import { getStatusDashboardConfig } from './StatusDashboardConfig'
import { DashboardItemsWeb } from './StatusDashboardItemConfig'

interface StatusDashboardOverviewProps {
    items: DashboardItemType[]
    statuses: DashboardItemStatuses
    healths: WidgetHealths
    isAosAdmin: boolean
    onDismissAlert(v: DashboardItemType): void
}

export class StatusDashboardOverview extends PureComponent<StatusDashboardOverviewProps> {
    public render() {
        return <Box className='dashboard-overview'>{this.props.items.map(this.getItemContent)}</Box>
    }

    private getItemContent = (type: DashboardItemsWeb, index: number) => {
        const { statuses, healths } = this.props
        const TileWidget = getStatusDashboardConfig()[type]?.tileRenderer
        const itemHealth = getHealthForItemType(healths, type)
        const itemStatus = getStatusForItemTypeAndHealth(statuses, itemHealth, type)

        return TileWidget ? (
            <Box key={type}>
                <TileWidget
                    id={index}
                    onDismissAlert={this.props.onDismissAlert}
                    onChangeSize={() => {
                        throw new Error('Not supported in overview')
                    }}
                    itemStatus={itemStatus}
                    itemState={{ type }}
                    sizes={{}}
                    itemHealth={itemHealth}
                    isAosAdmin={this.props.isAosAdmin}
                />
            </Box>
        ) : null
    }
}
