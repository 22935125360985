import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isAosAdminSelector } from 'aos-services/src/core/auth/state'
import { changeAirport, searchContact } from 'aos-services/src/core/contactList/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { useDebounceValue } from 'aos-ui-common/src/components/hooks/useDebounce'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AirportDropdown } from '../../../components/inputs/AirportDropdown'
import { QuickSearch } from '../../../components/inputs/QuickSearch'
import { openContactModal } from '../../../core/contactList/actions'
import { currentAirportSelector } from '../../../core/contactList/selectors'

export const ContactListHeader = () => {
    const filterAirport = useSelector(currentAirportSelector)
    const isAosAdmin = useSelector(isAosAdminSelector)
    const dispatch = useDispatch()

    const [search, setSearch] = useState<string>('')
    const searchValue = useDebounceValue(search, 800)

    useEffect(() => {
        dispatch(searchContact(searchValue))
    }, [searchValue])

    return (
        <Header variant={HeaderVariant.Dialog} paddingLeft={30} paddingRight={16}>
            <Text>{translate('contact-list.title')}</Text>
            <Box row columnGap={12} flex={1} justify='flex-end'>
                <AirportDropdown
                    value={filterAirport}
                    onChange={item => dispatch(changeAirport(item))}
                />
                <QuickSearch
                    small={false}
                    variant={ThemeVariant.Black}
                    value={search}
                    onChange={setSearch}
                />
                {isAosAdmin && (
                    <More iconVariant={IconVariant.Smart} marginLeft={16}>
                        <MoreItem onClick={() => dispatch(openContactModal(null))}>
                            {translate('contact-list.add-contact')}
                        </MoreItem>
                    </More>
                )}
            </Box>
        </Header>
    )
}
