import { Box } from 'aos-ui/src/components/base/Box'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React from 'react'

import { EventExtendedFormProps } from './EventFormProps'

export const AtcEventForm = (eventExtendedFormProps: EventExtendedFormProps) => {
    const renderIncidentType = () => (
        <LabeledInput
            keyPrefix='create-event-modal.incident-type'
            marginBottom={12}
            onChangeText={(incidentType: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        incidentType: incidentType,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.incidentType}
        />
    )

    const renderAircraftModel = () => (
        <LabeledInput
            keyPrefix='create-event-modal.aircraft-type'
            marginBottom={12}
            onChangeText={(aircraftModel: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        aircraftModel: aircraftModel,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.aircraftModel}
            halfWidth
            svg={SvgIcon.Flights}
        />
    )

    const renderRoute = () => (
        <LabeledInput
            keyPrefix='create-event-modal.route'
            marginBottom={12}
            onChangeText={(route: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: { ...eventExtendedFormProps.currentEvent.atcFields, route: route },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.route}
            halfWidth
            svg={SvgIcon.Route}
        />
    )

    const renderFlightNumber = () => (
        <LabeledInput
            keyPrefix='create-event-modal.flight-number'
            marginBottom={12}
            onChangeText={(flightNumber: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        flightNumber: flightNumber,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.flightNumber}
            halfWidth
            svg={SvgIcon.Flights}
        />
    )

    const renderNumberOfPax = () => (
        <LabeledInput
            keyPrefix='create-event-modal.number-of-people'
            marginBottom={12}
            onChangeText={(numberOfPax: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        numberOfPax: numberOfPax,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.numberOfPax}
            halfWidth
            svg={SvgIcon.OperationalForecastPassengers}
        />
    )

    const renderNumberOfCrew = () => (
        <LabeledInput
            keyPrefix='create-event-modal.number-of-crew'
            marginBottom={12}
            onChangeText={(numberOfCrew: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        numberOfCrew: numberOfCrew,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.numberOfCrew}
            halfWidth
            svg={SvgIcon.OperationalForecastPassengers}
        />
    )

    const renderFuel = () => (
        <LabeledInput
            keyPrefix='create-event-modal.fuel'
            marginBottom={12}
            onChangeText={(fuel: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        fuel: fuel,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.fuel}
            halfWidth
            svg={SvgIcon.Gas}
        />
    )

    const renderDgr = () => (
        <LabeledInput
            keyPrefix='create-event-modal.dgr'
            marginBottom={12}
            onChangeText={(dgr: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        dgr: dgr,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.dgr}
            svg={SvgIcon.Atc}
        />
    )

    const renderMoreInfo = () => (
        <LabeledTextArea
            keyPrefix='create-event-modal.more-info'
            marginBottom={12}
            onChangeText={(moreInfo: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        moreInfo: moreInfo,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.moreInfo}
            resizeable
        />
    )

    const renderEta = () => (
        <LabeledInput
            keyPrefix='create-event-modal.eta'
            onChangeText={(eta: string | undefined) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        eta: eta,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.eta}
            marginBottom={12}
        />
    )

    const renderLocationName = () => (
        <LabeledInput
            keyPrefix='create-event-modal.location-name'
            marginBottom={12}
            onChangeText={(locationName: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        locationName: locationName,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.locationName}
            halfWidth
            svg={SvgIcon.Location}
        />
    )

    const renderCallSign = () => (
        <LabeledInput
            keyPrefix='create-event-modal.callsign'
            marginBottom={12}
            onChangeText={(callSign: string) =>
                eventExtendedFormProps.changeForm({
                    atcFields: {
                        ...eventExtendedFormProps.currentEvent.atcFields,
                        callSign: callSign,
                    },
                })
            }
            value={eventExtendedFormProps.currentEvent.atcFields?.callSign}
            halfWidth
            svg={SvgIcon.Flights}
        />
    )

    return (
        <>
            <Box row justify='space-between'>
                {renderEta()}
                {renderLocationName()}
            </Box>
            {renderIncidentType()}
            <Box row justify='space-between'>
                {renderAircraftModel()}
                {renderRoute()}
            </Box>
            <Box row justify='space-between'>
                {renderCallSign()}
                {renderFlightNumber()}
            </Box>
            <Box row justify='space-between'>
                {renderNumberOfPax()}
                {renderNumberOfCrew()}
            </Box>
            {renderFuel()}
            {renderDgr()}
            {renderMoreInfo()}
        </>
    )
}
