import { Color } from 'aos-ui-common/src/styles/Color'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BlueLink = styled(Link)`
    color: ${Color.LinkText};
    padding-left: 4px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`
