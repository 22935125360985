import { Color } from 'aos-ui-common/src/styles/Color'

export enum TowingStatus {
    Scheduled = 'SCHEDULED',
    InProgress = 'IN_PROGRESS',
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED',
    TimesUpdated = 'TIMES_UPDATED',
    StandsUpdates = 'STANDS_UPDATED',
    StandsAndTimesUpdated = 'STANDS_AND_TIMES_UPDATED',
    StandsConfirmed = 'STANDS_CONFIRMED',
}

export const towingStatusColor: Record<TowingStatus, Color> = {
    [TowingStatus.Scheduled]: Color.TextSecondary,
    [TowingStatus.InProgress]: Color.White,
    [TowingStatus.Cancelled]: Color.Magenta,
    [TowingStatus.Completed]: Color.SeaBlue,
    [TowingStatus.TimesUpdated]: Color.Primary,
    [TowingStatus.StandsUpdates]: Color.Primary,
    [TowingStatus.StandsAndTimesUpdated]: Color.Primary,
    [TowingStatus.StandsConfirmed]: Color.Primary,
}
