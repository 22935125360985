import { recordEntries } from 'aos-helpers/src/helpers/Record'
import { flatten } from 'lodash'

import { runwayConfiguration } from '../../flightInformation/types/AosAirport'

export const emptyCombination: RunwayCombination = runwayConfiguration.runways
    .flatMap(runway => runway.values)
    .reduce((acc, code) => {
        acc[code] = []
        return acc
    }, {} as RunwayCombination)

export const RunwayMode = runwayConfiguration.allowedStates
export type RunwayMode = keyof typeof RunwayMode

const runwayModeSort = {
    [RunwayMode.DEPARTURE]: 1,
    [RunwayMode.CONDITIONAL]: 2,
    [RunwayMode.ARRIVAL]: 3,
}
export const compareModes = (a: RunwayMode, b: RunwayMode) => runwayModeSort[a] - runwayModeSort[b]

export type Runway = (typeof runwayConfiguration.runways)[number]['values'][number]

export const isSectionActive = (runwayModes: RunwayMode[]) =>
    runwayModes.some(mode => mode !== RunwayMode.Conditional)

export type RunwayCombination = Record<Runway, RunwayMode[]>

export const isRunwayActive = (combination: RunwayCombination, runway: [Runway, Runway]) =>
    isSectionActive(combination[runway[0]]) || isSectionActive(combination[runway[1]])

export const isRunwayMode = (
    combination: RunwayCombination,
    runway: [Runway, Runway],
    mode: RunwayMode,
) => sectionHasMode(combination[runway[0]], mode) || sectionHasMode(combination[runway[1]], mode)

export const getInactiveRunways = (combination: RunwayCombination) =>
    flatten(runwayConfiguration.runways.filter(r => !isRunwayActive(combination, r.values)))

export const getRunwaysByMode = (combination: RunwayCombination, mode: RunwayMode): Runway[] =>
    recordEntries(combination)
        .filter(c => c[1].indexOf(mode) >= 0)
        .map(c => c[0])

const sectionHasMode = (runwayModes: RunwayMode[], mode: RunwayMode) =>
    runwayModes.some(m => m === mode)
