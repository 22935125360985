import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const title = (suffix: string) => translate(`dashboard.help.weather.${suffix}`)
const note = (suffix: string) => translate(`dashboard.help.weather.${suffix}.note`)

export const getWeatherHelpConfig = (): HelpModalConfig => ({
    title: translate('dashboard.help.weather.title'),
    items: [
        {
            title: title('forecast'),
            renderer: lazy(() => import('./renderers/WeatherForecastHelp')),
        },
        {
            title: title('conditions'),
            renderer: lazy(() => import('./renderers/WeatherConditionsHelp')),
        },
        {
            title: title('conditions-lvp'),
            note: note('conditions-lvp'),
            renderer: lazy(() => import('./renderers/WeatherConditionsLvpHelp')),
        },
        {
            title: title('wind-and-clouds'),
            renderer: lazy(() => import('./renderers/WeatherWindAndCloudsHelp')),
        },
        {
            title: title('wind-and-clouds-gusts'),
            note: note('wind-and-clouds-gusts'),
            renderer: lazy(() => import('./renderers/WeatherWindAndCloudsGustsHelp')),
        },
        {
            title: title('wind-and-clouds-directions'),
            note: note('wind-and-clouds-directions'),
            renderer: lazy(() => import('./renderers/WeatherWindAndCloudsDirectionsHelp')),
        },
    ],
})
