import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { taskManagerTabs } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { Link } from '../../../core/Links'

interface TasksHeaderFilterProps {
    value: FilterType
}

export const TasksHeaderFilter: FC<TasksHeaderFilterProps> = ({ value }) => (
    <HeaderFilter
        items={filterTypes}
        value={value}
        valueFormatter={filterType => (
            <NavLink key={linksList[filterType]} to={linksList[filterType]}>
                {translateFilter(filterType)}
            </NavLink>
        )}
    />
)

export enum FilterType {
    Timeline = 'Timeline',
    List = 'List',
    ShiftNotes = 'ShiftNotes',
    FireFighters = 'FireFighters',
    Reports = 'Reports',
    FOD = 'FOD',
}

const translateFilter = translateEnum<FilterType>(FilterType, 'tasks.filter-type')

const SubgroupFilterType = taskManagerTabs.reduce((acc, key) => {
    if (key in FilterType) {
        acc[key] = key
    }
    return acc
}, {} as { [key: string]: string })

type SubgroupFilterType = typeof SubgroupFilterType

const filterTypes = EnumValues.getValues<FilterType>(SubgroupFilterType).filter(
    i => i !== FilterType.Reports,
)

const linksList: Record<FilterType, string> = {
    [FilterType.List]: Link.TasksList,
    [FilterType.Timeline]: Link.TasksTimeline,
    [FilterType.Reports]: Link.TasksReports,
    [FilterType.ShiftNotes]: Link.TasksShiftNotes,
    [FilterType.FireFighters]: Link.TasksFireFighters,
    [FilterType.FOD]: Link.FOD,
}
