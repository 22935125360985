import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

interface NumberItemProps {
    value: number
}

interface ItemProps extends SeleniumProps {
    isShow: boolean
}

const Item: FCWithChildren<ItemProps> = props => (
    <ItemBox isShow={props.isShow} paddingLeft={8} data-test-id={props.seleniumLocation}>
        {props.children}
    </ItemBox>
)

const ItemBox = styled(Box)<{ isShow: boolean }>`
    opacity: ${p => (p.isShow ? 1 : 0)};
`

const NumberItem: FCWithChildren<NumberItemProps> = props => (
    <Item isShow={props.value > 0}>
        <ItemIconBox row justify='flex-end'>
            <Text lineHeightPx={24} color={Color.TextSecondary} size={12} paddingRight={4}>
                {props.value}
            </Text>
            <Box shrink={0}>{props.children}</Box>
        </ItemIconBox>
    </Item>
)

const ItemIconBox = styled(Box)`
    position: relative;
    width: 34px;
    height: 24px;
    overflow: visible;
`

interface DarkListItemIconsSectionProps {
    config: [Svg, number | boolean][]
}

export const DarkListItemIconsSection: FCWithChildren<DarkListItemIconsSectionProps> = ({
    config,
}) => {
    const item = ([icon, v]: [Svg, number | boolean], index: number) => {
        if (typeof v === 'boolean') {
            return (
                <Item isShow={v} key={index}>
                    <Icon
                        svg={icon}
                        iconSize={BlockSize.Std}
                        iconVariant={IconVariant.BlackGrey}
                        block
                    />
                </Item>
            )
        } else {
            return (
                <NumberItem value={v} key={index}>
                    <Icon
                        svg={icon}
                        iconSize={BlockSize.Std}
                        iconVariant={IconVariant.BlackGrey}
                        block
                    />
                </NumberItem>
            )
        }
    }

    return (
        <Box overflow='hidden' shrink={0} flex={1 / 3} row>
            {config.filter(q => q[1]).map(item)}
        </Box>
    )
}
