import { runwayCombinationSelector } from 'aos-services/src/core/statusDashboardData/runway/selectors'
import { emptyCombination } from 'aos-services/src/services/runways/types/RunwayMode'
import { RunwaysState } from 'aos-services/src/services/runways/types/RunwaysState'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { RunwaysItemState } from '../../../../services/statusDashboard/types/itemStates/RunwaysItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { RunwayItem } from './partial/RunwayItem'
import { RunwayClosurePlan } from './RunwaysClosurePlan'
import { RunwaysContent } from './RunwaysContent'
import { RunwaysGraph } from './RunwaysGraph'
import { RunwaysMetar } from './RunwaysMetar'

export class RunwaysClass extends PureComponent<RunwaysProps & RunwaysOwnProps, {}> {
    public render() {
        return (
            <RunwayItem
                itemProps={pickDashboardItemProps(this.props)}
                runwaysState={this.props.runwaysState}
                syncId={this.props.id}
            >
                {this.renderContent()}
            </RunwayItem>
        )
    }

    private renderContent() {
        const { runwaysState, metar } = this.props
        if (!runwaysState) {
            return null
        }
        const combination = runwaysState.combinationDetails ?? emptyCombination
        return (
            <ItemContainer vertical horizontalSpacing autoHeight>
                <Box column relative flex='auto'>
                    <RunwaysContent lastChange={runwaysState.lastChanged} />
                    <RunwaysGraph combination={combination} />
                </Box>
                <RunwaysMetar metar={metar} />
                {runwaysState.runwayClosure && (
                    <RunwayClosurePlan runwayClosure={runwaysState.runwayClosure} />
                )}
            </ItemContainer>
        )
    }
}

type RunwaysOwnProps = StatusDashboardItemCommonProps<RunwaysItemState>

interface RunwaysProps {
    runwaysState?: RunwaysState
    metar: string
}

export const Runways = connect<RunwaysProps, {}, RunwaysOwnProps>(
    runwayCombinationSelector,
    {},
)(RunwaysClass)
