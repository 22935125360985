import { EnumValues } from 'enum-values'
import { fromPairs, includes, invert, kebabCase } from 'lodash'

export type EnumValue = string | number

interface AnyStringEnum {
    [index: string]: EnumValue
}

interface AnyNumericEnum {
    [index: number]: EnumValue
}

export type AnyEnum = AnyStringEnum | AnyNumericEnum

export const enumToLabel =
    <T extends AnyEnum>(enumEntity: T, prefix: string) =>
    (enumValue: EnumValue | null): string => {
        if (enumValue === null || enumValue === undefined) {
            return 'text.empty'
        }
        if (prefix.includes('{enum}')) {
            return prefix.replace('{enum}', enumToName(enumEntity)(enumValue))
        }
        return `${prefix}.${enumToName(enumEntity)(enumValue)}`
    }

export const enumToName =
    <T extends AnyEnum>(enumEntity: T) =>
    (enumValue: EnumValue): string =>
        kebabCase(getEnumName(enumEntity)(enumValue))

export const enumToClassModifier =
    <T extends AnyEnum>(enumEntity: T, prefix: string) =>
    (enumValue: EnumValue): string =>
        `${prefix}--${enumToName(enumEntity)(enumValue)}`

export const getEnumName =
    <T extends AnyEnum>(enumEntity: T) =>
    (enumValue: EnumValue): string => {
        if (typeof enumValue === 'string') {
            return (invert(enumEntity) as any)[enumValue as any]
        }
        return (enumEntity as any)[enumValue]
    }

export const isValidEnumEntry =
    <T extends AnyEnum>(enumEntity: T) =>
    <S extends EnumValue>(enumValue: EnumValue): enumValue is S =>
        getEnumName(enumEntity)(enumValue) !== undefined

export const enumToNumericMap = <T extends AnyEnum, P>(e: T): Map<P, number> =>
    new Map(EnumValues.getValues(e).map((v, index) => [v, index] as any))

export const enumValueToLabel = (prefix: string, v: string) => `${prefix}.${kebabCase(v)}`

export const enumHasValue = <T extends AnyEnum>(enumEntity: T) => {
    const enumValues = EnumValues.getValues(enumEntity)
    return (name: string) => includes(enumValues, name)
}

export const getInitialValuesForEnumRecord = <T extends EnumValue, U>(
    e: any,
    value: U,
): Record<T, U> => fromPairs(EnumValues.getValues(e).map(name => [name, value])) as Record<T, U>

export const enumRecord = <T extends EnumValue, U>(
    e: any,
    valueMapper: f.Func1<T, U>,
): Record<T, U> =>
    fromPairs(EnumValues.getValues<T>(e).map(name => [name, valueMapper(name)])) as Record<T, U>

export const enumToObjectMap = <T>(enumEntity: T): { [key: string]: T[keyof T] } =>
    fromPairs(
        EnumValues.getNamesAndValues(enumEntity).map(({ name, value }) => [
            name,
            value as unknown as T[keyof T],
        ]),
    )

export const enumUnion = <T extends EnumValue, S extends EnumValue>(
    enumEntity1: AnyEnum,
    enumEntity2: AnyEnum,
): (T | S)[] =>
    EnumValues.getValues<T | S>(enumEntity1).concat(EnumValues.getValues<T | S>(enumEntity2))

export const groupByEnum =
    <Source, Result, E extends AnyEnum, EV extends EnumValue>(
        enumEntity: E,
        enumGetter: f.Func1<Source, EV>,
        aggregator: f.Func1<Source[], Result>,
    ) =>
    (data: Source[]): Record<EV, Result> =>
        enumRecord(enumEntity, (type: EV) => aggregator(data.filter(d => enumGetter(d) === type)))

/**
 * Helper to produce an array of enum descriptors.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends AnyEnum>(enumeration: T): (keyof T)[] {
    return (Object.keys(enumeration) as Array<keyof T>)
        .filter(key => isNaN(Number(key)))
        .filter(key => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string')
        .map(key => key)
}

export const isPartOfEnum = <T extends object>(
    value: string | number | symbol,
    enumType: T,
): value is keyof T => Object.values(enumType).includes(value as T[keyof T])

export const createEnumFromKeys = <T extends Record<string, string | number | Object>>(
    obj: T,
): { [K in keyof T]: K } =>
    obj &&
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            [key]: key,
        }),
        {} as { [K in keyof T]: K },
    )

export const createEnumFromRecord = <T extends Record<string, string>>(obj: T) => {
    return Object.freeze(obj) as { [K in keyof T]: T[K] }
}
