export enum FlightInfoField {
    flightId = 'flightId',
    hapt = 'hapt',
    prfix = 'prfix',
    sffix = 'sffix',
    fltnr = 'fltnr',
    cflight = 'cflight',
    flightType = 'flightType',
    sdt = 'sdt',
    acreg = 'acreg',
    actype = 'actype',
    mfltnr = 'mfltnr',
    naflt = 'naflt',
    handl = 'handl',
    routes = 'routes',
    park = 'park',
    gate = 'gate',
    irmsg = 'irmsg',
    prm = 'prm',
    tobt = 'tobt',
    est = 'est',
    pest = 'pest',
    appr = 'appr',
    act = 'act',
    ablk = 'ablk',
    eibt = 'eibt',
    tsat = 'tsat',
    bltarea = 'bltarea',
    belt = 'belt',
    bltInd = 'bltInd',
    fibag = 'fibag',
    labag = 'labag',
    paxTotal = 'paxTotal',
    callsign = 'callsign',
    chkarea = 'chkarea',
    chkdsk = 'chkdsk',
    door = 'door',
    rwy = 'rwy',
    airline = 'airline',
    aircraft = 'aircraft',
    key = 'key',
    isDelayedBasedOnEstimated = 'isDelayedBasedOnEstimated',
    isMainAirport = 'isMainAirport',
    cancelled = 'cancelled',
    bag = 'bag',
}
