import { DateTime } from 'aos-helpers/src/helpers/Time'
import { EnumValues } from 'enum-values'
import { identity, mapValues, pick, take } from 'lodash'
import { createSelector } from 'reselect'

import { AlertType } from '../../../dataaccess/alerts/types/AlertsStateDto'
import {
    findTimePointInTime,
    NullableTimeValuePoint,
    TimeValuePoint,
} from '../../../services/airportStatus/base/types/TimePoint'
import { TimeRangeFilter } from '../../../services/airportStatus/base/types/TimeRangeFilter'
import {
    offsetTimeByForecastTimePoint,
    WeatherCategory,
    WeatherCurrentTimePoint,
    WeatherForecastTimePoint,
    WeatherMetric,
    WeatherSeries,
    WeatherTimePoint,
    WeatherValueSeries,
} from '../../../services/airportStatus/weather/types/WeatherMetrics'
import {
    WeatherOtherSeriesType,
    WeatherValueSeriesType,
} from '../../../services/airportStatus/weather/types/WeatherSeriesType'
import { WeatherState } from '../../../services/airportStatus/weather/types/WeatherState'
import {
    WeatherFilters,
    WeatherTimeRange,
} from '../../../services/statusDashboard/types/filters/WeatherFilters'
import { alertTypeSelector } from '../../alerts/selectors'
import { isMainAirportSelector } from '../../auth/state'
import { ItemStateAware } from '../flights/selectors/common'
import { StatusDashboardDataStateAware } from '../state'

const weatherSeriesForTimeRangeFilter = (
    weatherSeries: WeatherValueSeries,
    filter: TimeRangeFilter,
): WeatherSeries =>
    mapValues(weatherSeries, (wp: TimeValuePoint[]) => take(wp, filter)) as unknown as WeatherSeries

const weatherCategoryForTimePoint = (
    weatherMetrics: WeatherState,
    timePoint: WeatherTimePoint,
): WeatherCategory => {
    if (timePoint === WeatherCurrentTimePoint.Current) {
        return (
            weatherMetrics.current[WeatherOtherSeriesType.WeatherCategory] ?? WeatherCategory.Normal
        )
    }
    return forecastWeatherCategory(
        weatherMetrics.forecasts,
        offsetTimeByForecastTimePoint(weatherMetrics.lastUpdate, timePoint),
    )
}

const forecastWeatherCategory = (weatherSeries: WeatherSeries, atTime: DateTime) => {
    const categoryPoint = findTimePointInTime(
        weatherSeries[WeatherOtherSeriesType.WeatherCategory],
        atTime,
    )

    return categoryPoint?.value ?? WeatherCategory.Normal
}

const weatherMetricsForTimePoint = (
    weatherMetrics: WeatherState,
    timePoint: WeatherTimePoint,
): WeatherMetric => {
    if (timePoint === WeatherCurrentTimePoint.Current) {
        return weatherMetrics.current
    }
    return forecastWeatherMetrics(weatherMetrics, timePoint)
}

function forecastWeatherMetrics(
    weatherMetrics: WeatherState,
    timePoint: WeatherForecastTimePoint,
): WeatherMetric {
    const targetTime = offsetTimeByForecastTimePoint(weatherMetrics.lastUpdate, timePoint)
    const valueMetrics = mapValues<WeatherValueSeries, number | undefined>(
        pick(weatherMetrics.forecasts, EnumValues.getValues(WeatherValueSeriesType)),
        (points: NullableTimeValuePoint[]) => {
            const point = findTimePointInTime(points, targetTime)
            return point?.value ?? undefined
        },
    )
    const weatherCategorySeries = weatherMetrics.forecasts[WeatherOtherSeriesType.WeatherCategory]
    const weatherCategoryTimePoint = findTimePointInTime(weatherCategorySeries, targetTime)
    return {
        ...valueMetrics,
        [WeatherOtherSeriesType.WeatherCategory]:
            weatherCategoryTimePoint?.value ?? WeatherCategory.Normal,
    }
}

export const weatherTabSelector = (_: any, ownProps: ItemStateAware<WeatherFilters>) =>
    ownProps.itemState.timePoint
export const weatherTimeRangeTabSelector = (_: any, ownProps: ItemStateAware<WeatherFilters>) =>
    ownProps.itemState.timeRange
export const weatherMetricsSelector = (state: StatusDashboardDataStateAware) =>
    state.statusDashboardData.weatherMetrics

export const weatherMerger = (
    timePointFilter: WeatherTimePoint,
    timeRangeFilter: TimeRangeFilter,
    weatherMetrics: WeatherState,
    weatherAlert: boolean,
    lvpAlert: boolean,
    isMainAirport: boolean,
) => {
    const weatherSeriesMetric = weatherMetricsForTimePoint(weatherMetrics, timePointFilter)
    return {
        isMainAirport,
        weatherAlert,
        lvpAlert,
        timePointFilter,
        timeRangeFilter,
        weatherSeries: weatherSeriesForTimeRangeFilter(weatherMetrics.forecasts, timeRangeFilter),
        weatherSeriesMetric,
        weatherCategory: weatherCategoryForTimePoint(weatherMetrics, timePointFilter),
        noDataWarning: !Object.values(weatherSeriesMetric).some(identity),
        weatherInfo: weatherMetrics.latestInfo,
    }
}

export const weatherForecastSelector = createSelector(
    weatherMetricsSelector,
    (weatherMetrics: WeatherState) => weatherMetrics.latestInfo,
)

export const weatherDataSelector = createSelector(
    weatherTabSelector,
    weatherTimeRangeTabSelector,
    weatherMetricsSelector,
    alertTypeSelector(AlertType.WA),
    alertTypeSelector(AlertType.LVP),
    isMainAirportSelector,
    weatherMerger,
)

export const weatherTileDataSelector = createSelector(
    () => WeatherCurrentTimePoint.Current,
    () => WeatherTimeRange.Range12,
    weatherMetricsSelector,
    alertTypeSelector(AlertType.WA),
    alertTypeSelector(AlertType.LVP),
    isMainAirportSelector,
    weatherMerger,
)
