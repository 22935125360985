import { Color } from 'aos-ui-common/src/styles/Color'
import DOMPurify from 'dompurify'
import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { MarginBoxProps, marginStyleGenerator } from '../../base/Box'
import { textStyleBuilder } from '../../base/Text'
import { ThemeVariant } from '../../base/ThemeVariant'

export interface InputStyleWrapperProps extends MarginBoxProps {
    variant?: ThemeVariant
    disabled?: boolean
    small?: boolean
}

interface AreaStyleWrapperProps extends InputStyleWrapperProps {
    resizeable?: boolean
    rows?: number
}

const variantConfig: Record<ThemeVariant, CSSObject> = {
    [ThemeVariant.White]: {
        'border': `1px solid ${Color.DisabledText}`,
        ...textStyleBuilder({ color: Color.Grey2, size: 12, weight: 'regular' }),
        '::placeholder': textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [ThemeVariant.Black]: {
        'background': Color.DarkInput,
        ...textStyleBuilder({ color: Color.White, size: 12, weight: 'regular' }),
        '::placeholder': textStyleBuilder({ color: Color.TextSecondary, size: 12 }),
    },
}

const disabledConfig: Record<ThemeVariant, CSSObject> = {
    [ThemeVariant.White]: {
        background: Color.Grey1,
        ...textStyleBuilder({ color: Color.ChartBase, size: 12 }),
    },
    [ThemeVariant.Black]: {},
}

type InputProps<T, X> = React.DetailedHTMLProps<React.InputHTMLAttributes<T>, T> & X

const SafeInput = <T extends HTMLElement, X>(
    Component: React.FunctionComponent<InputProps<T, X>>,
) => {
    return forwardRef<T, InputProps<T, X>>((props, ref) => {
        const { onChange, ...rest } = props
        const purify = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange!({
                ...event,
                target: { ...event.target, value: DOMPurify.sanitize(event.target.value) },
            } as React.ChangeEvent<HTMLInputElement & T>)
        }

        return (
            <Component
                {...(rest as InputProps<T, X>)}
                onChange={purify}
                ref={ref as React.ForwardedRef<T>}
            />
        )
    })
}

const BaseInput = styled.input<InputStyleWrapperProps>(
    ({ variant = ThemeVariant.White, disabled, small, ...rest }) => {
        const margins = marginStyleGenerator(rest)
        const styles: CSSObject = {
            padding: '12px',
            borderRadius: '5px',
            height: small ? '36px' : '40px',
            width: '100%',
        }
        let overrideStyles: CSSObject = {}

        if (disabled) {
            overrideStyles = disabledConfig[variant]
        }

        return { ...styles, ...margins, ...variantConfig[variant], ...overrideStyles }
    },
)

export const Input = SafeInput<HTMLInputElement, InputStyleWrapperProps>(BaseInput)

const TextareaBase = styled.textarea<AreaStyleWrapperProps>(
    ({ variant = ThemeVariant.White, disabled, resizeable, ...rest }) => {
        const margins = marginStyleGenerator(rest)
        const styles: CSSObject = {
            padding: '12px',
            borderRadius: '5px',
            height: 'auto',
            resize: 'none',
            width: '100%',
        }
        let overrideStyles: CSSObject = {}

        if (disabled) {
            overrideStyles = disabledConfig[variant]
        }
        if (resizeable) {
            styles.maxHeight = '400px'
            styles.resize = 'vertical'
        }

        return { ...styles, ...margins, ...variantConfig[variant], ...overrideStyles }
    },
)

export const Textarea = SafeInput<HTMLTextAreaElement, AreaStyleWrapperProps>(TextareaBase)
