import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'

import { OperationalBlock } from '../../../core/statusDashboardData/operationalForecast/selectors'
import { OperationalForecastGroup } from '../../flightInformation/types/AosAirport'

export { OperationalForecastGroup }

export enum OperationalForecastMode {
    Normal = 'NORMAL',
    Warning = 'WARNING',
    Alert = 'ALERT',
}

const modeOrder: Record<OperationalForecastMode, number> = {
    [OperationalForecastMode.Normal]: 1,
    [OperationalForecastMode.Warning]: 2,
    [OperationalForecastMode.Alert]: 3,
}

export const operationalForecastModeColors: Record<OperationalForecastMode, Color> = {
    [OperationalForecastMode.Normal]: Color.Green,
    [OperationalForecastMode.Warning]: Color.Yellow,
    [OperationalForecastMode.Alert]: Color.Red,
}
export interface OperationalItem {
    title: string
    description: string
    mode: OperationalForecastMode
    group: OperationalForecastGroup
}

export interface OperationalForecast {
    lastUpdated?: DateTime
    items: OperationalItem[]
}

export interface OperationalForecastGroupItems {
    group: OperationalForecastGroup
    data: OperationalItem[]
    mode: OperationalForecastMode
}

export const operationalHighestMode = (items: OperationalItem[]): OperationalForecastMode =>
    items
        .filter(Boolean)
        .sort((a, b) => modeOrder[a.mode] - modeOrder[b.mode])
        .pop()?.mode || OperationalForecastMode.Normal

export const operationalHighestModeByGroup = (
    items: OperationalItem[],
    group: OperationalForecastGroup,
) => operationalHighestMode(items.filter(Boolean).filter(i => i.group === group))

export const translateOperationalForecastGroup = translateEnum<OperationalForecastGroup>(
    OperationalForecastGroup,
    'dashboard.operational-forecast.group',
)

export const translateOperationalForecastMode = translateEnum<OperationalForecastMode>(
    OperationalForecastMode,
    'dashboard.operational-forecast.mode',
)

export const getBlocksForForecastItems = (items: OperationalItem[]) =>
    EnumValues.getValues<OperationalForecastGroup>(OperationalForecastGroup).map(
        group =>
            [
                group,
                operationalHighestModeByGroup(items, group),
                items.filter(Boolean).filter(i => i.group === group),
            ] as OperationalBlock,
    )

export const getGroupsForForecastItems = (items: OperationalItem[]) =>
    EnumValues.getValues<OperationalForecastGroup>(OperationalForecastGroup)
        .map(
            group =>
                ({
                    group,
                    mode: operationalHighestModeByGroup(items, group),
                    data: items.filter(Boolean).filter(i => i.group === group),
                } as OperationalForecastGroupItems),
        )
        .filter(b => b.data.length > 0)

export const emptyForecast: OperationalForecast = {
    items: [],
}
