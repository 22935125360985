import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

interface CurrentTimeProps extends BoxProps {
    date: DateTime
    utc?: boolean
    onClick(): void
}

export const CurrentTime: FCWithChildren<CurrentTimeProps> = ({
    date,
    utc,
    onClick,
    ...boxProps
}) => (
    <Box row cursor='pointer' onClick={onClick} {...boxProps}>
        <Icon
            iconVariant={IconVariant.Black}
            iconSize={BlockSize.Large}
            svg={SvgIcon.CurrentTime}
        />
        <Text paddingLeft={8} color={Color.TextTheme} size={14}>
            {`${formatTime(date)} ${utc ? 'UTC' : ''}`}
        </Text>
    </Box>
)
