import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosUnitTaskTemplate } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import {
    atcEventSeverityTitle,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import { SelectableItem } from 'aos-ui/src/components/list/SelectableItem'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { TemplateItemProps } from '../../TemplatesList'

export const UnitTaskTemplateItem: FC<TemplateItemProps<AosUnitTaskTemplate>> = ({
    checklistTemplate,
    ...props
}) => {
    const onClick = () => props.onSelect(checklistTemplate)
    const onExportChecklist = () => props.onExport(checklistTemplate.id)
    return (
        <Box key={checklistTemplate.id} data-test-id='checklist-item-box'>
            <SelectableItem
                onClick={onClick}
                isSelected={props.isSelected}
                paddingHorizontal={30}
                paddingVertical={20}
                selectedColor={Color['BackgroundPrimary']}
            >
                <MoreHover justify='space-between' row>
                    <Box column rowGap={8} alignItems='flex-start'>
                        <BorderedChip
                            size={14}
                            weight='regular'
                            paddingVertical={6}
                            paddingHorizontal={12}
                            border={severityColorsMapping[checklistTemplate.severity]}
                            marginRight={12}
                        >
                            {atcEventSeverityTitle(checklistTemplate.severity)}
                        </BorderedChip>
                        <Box row>
                            <Text size={12} color={Color.TransparentGrey}>
                                {`${translate('tasklist-templates.tasks', {
                                    tasks: checklistTemplate.numberOfTasks,
                                })} | ${translate('tasklist-templates.units', {
                                    units: checklistTemplate.numberOfUnits,
                                })}`}
                            </Text>
                        </Box>
                    </Box>
                    <More>
                        <MoreItem
                            onClick={onExportChecklist}
                            disabled={props.exportingChecklistTemplates}
                        >
                            {translate('checklist-templates.more.export')}
                        </MoreItem>
                    </More>
                </MoreHover>
            </SelectableItem>
        </Box>
    )
}
