import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { EditButton } from 'aos-ui/src/components/buttons/EditButton'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { StatusTile } from '../../common/StatusTile'
import { FirefightersTime } from './FirefighterTime'

interface FirefightersTileProps {
    item: FirefighterShift
    parentWidth: number
    onEdit?(shift: FirefighterShift): void
}

export const FirefightersTile: FC<FirefightersTileProps> = ({ item, parentWidth, onEdit }) => {
    const isValid = item.validUntil.isAfter(dateTime())

    return (
        <StatusTile
            status={isValid ? 'ok' : 'neutral'}
            title={
                <>
                    <Box flex={1} columnGap={12} row>
                        <Box wrap row>
                            <FirefightersTime date={item.validFrom} />

                            <Icon
                                iconSize={BlockSize.Std}
                                svg={SvgIcon.Forward}
                                iconVariant={IconVariant.Grey}
                                marginHorizontal={12}
                            />

                            <FirefightersTime date={item.validUntil} />
                        </Box>
                        <Box flex={1}>
                            <Text size={14} color={Color.TextSecondary}>
                                {translate('firefighters.list.reported-by', {
                                    by: item.reporterName,
                                })}
                            </Text>
                        </Box>
                        <Box>
                            {isValid && onEdit && <EditButton onClick={() => onEdit(item)} />}
                        </Box>
                    </Box>
                </>
            }
        >
            <TrucksGrid parentWidth={parentWidth} paddingHorizontal={20} paddingVertical={12}>
                {item.fireFighterSquads.map(squad => (
                    <Box key={squad.id} marginBottom={8} alignItems='flex-start' row>
                        <Box flex={1}>
                            <Text paddingTop={8} size={14} color={Color.TextSecondary}>
                                {squad.fireTruck?.callSign}
                            </Text>
                        </Box>
                        <Box flex={2}>
                            {squad.fireFighters.map((user, index: number) => (
                                <Text
                                    key={index + user.truckUserName}
                                    paddingTop={8}
                                    paddingBottom={8}
                                    size={12}
                                >
                                    <Box columnGap={16} rowGap={6} row wrap>
                                        <Box>{user.truckUserName}</Box>
                                        <Box columnGap={16}>
                                            {user.kat12 && (
                                                <UserBadge>
                                                    {translate('firefighters.list.kat12')}
                                                </UserBadge>
                                            )}
                                            {user.lp3 && (
                                                <UserBadge>
                                                    {translate('firefighters.list.lp3')}
                                                </UserBadge>
                                            )}
                                            {user.lp5 && (
                                                <UserBadge>
                                                    {translate('firefighters.list.lp5')}
                                                </UserBadge>
                                            )}
                                        </Box>
                                    </Box>
                                </Text>
                            ))}
                        </Box>
                    </Box>
                ))}
            </TrucksGrid>
        </StatusTile>
    )
}

const GRID_BP = 800

const TrucksGrid = styled(Box)<{ parentWidth: number }>`
    display: grid;
    grid-template-columns: ${p => (p.parentWidth < GRID_BP ? '1fr' : '1fr 1fr')};
`

const UserBadge = styled(Box)`
    display: inline-block;
    vertical-align: middle;
    border: 1px solid ${Color.FireFighterTileBorder};
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 10px;
`
