import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { EventMapUserLayerVisibilityState } from 'aos-services/src/core/eventUserLayersVisibility/state'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { EventFormPayload, EventPayload } from 'aos-services/src/services/events/input/EventPayload'
import { LayersVisibility } from 'aos-services/src/services/map/types/LayersVisibility'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { MapFormAwareModal } from 'aos-ui-map/src/components/map/MapFormAwareModal'
import { closeMapAction, openMapAction } from 'aos-ui-map/src/core/actions'
import { MapState } from 'aos-ui-map/src/core/state'
import React, { FC } from 'react'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { eventModalsMapParentAction } from '../../core/eventModals/actions'
import {
    EventModalExtendedFormValidationState,
    EventModalSimpleFormValidationState,
} from '../../core/eventModals/state'
import { EventExtendedForm } from './partial/EventExtendedForm'
import { EventFormType } from './partial/EventFormProps'
import { EventMap } from './partial/EventMap'
import { EventSimpleForm } from './partial/EventSimpleForm'

export interface EventModalsProps {
    isOpen: boolean
    isEventAdmin: boolean
    modalKind: ModalKind
    keyPrefix: string
    currentEvent: EventFormPayload
    parentFeedInId?: number
    simpleForm: FormValidation<EventModalSimpleFormValidationState>
    extendedForm: FormValidation<EventModalExtendedFormValidationState>
    isEditingEvent?: boolean
    map: MapState
    attachmentsState: AttachmentsState
    groups: AosUserGroup[]
    bimLayers: BimLayersState
    eventMapUserLayerVisibility: EventMapUserLayerVisibilityState
    formType: EventFormType
}

export interface EventModalsDispatchProps {
    changeFormValue(v: EventFormPayload): void
    setFormPristine(v: boolean): void
    saveLocation(): void
    hideAction(): void

    setLayersVisibility(m: LayersVisibility): void
    switchMap(m: MapVariant): void
    resetLocation(): void
    pickLocation(v: AosLocation): void

    saveAction(v: [EventFormPayload, number | undefined]): void
    toggleModeAction?(): void
    attachmentsAction(a: AttachmentsAction): void
    setEventMapUserLayersVisibilityAction(v: EventMapUserLayerVisibilityState): void
}

export const EventModalsSimpleFormClass: FC<
    EventModalsProps & EventModalsDispatchProps
> = props => {
    const {
        modalKind,
        isOpen,
        isEventAdmin,
        keyPrefix,
        simpleForm,
        map,
        hideAction,
        saveLocation,
        setLayersVisibility,
        switchMap,
        toggleModeAction,
        setFormPristine,
        saveAction,
        currentEvent,
        parentFeedInId,
        changeFormValue,
        attachmentsAction,
        attachmentsState,
        groups,
        bimLayers,
        eventMapUserLayerVisibility,
        setEventMapUserLayersVisibilityAction,
        formType,
        resetLocation,
    } = props

    const changeUserMapLayersVisibility = (v: string[]) => {
        setEventMapUserLayersVisibilityAction({
            ...eventMapUserLayerVisibility,
            list: v,
        })
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...eventMapUserLayerVisibility,
            list: [
                ...eventMapUserLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setEventMapUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    const submitForm = () => {
        if (!simpleForm.valid) {
            setFormPristine(false)
        } else {
            saveAction([currentEvent, parentFeedInId])
            hideAction()
        }
    }
    const changeForm = (v: Partial<EventPayload>) => {
        changeFormValue({ ...currentEvent, ...v })
    }
    return (
        <MapFormAwareModal
            map={() => (
                <SyncWrapper
                    onEnter={eventModalsMapParentAction(openMapAction())}
                    onLeave={eventModalsMapParentAction(closeMapAction())}
                >
                    <EventMap />
                </SyncWrapper>
            )}
            content={({ setView }) => (
                <EventSimpleForm
                    setViewMode={setView}
                    changeForm={changeForm}
                    currentEvent={currentEvent}
                    attachmentsAction={attachmentsAction}
                    attachmentsState={attachmentsState}
                    form={simpleForm}
                    isEventAdmin={isEventAdmin}
                    location={map.pickedEvent || undefined}
                    groups={groups}
                    formType={formType}
                />
            )}
            modalKind={modalKind}
            isOpen={isOpen}
            keyPrefix={keyPrefix}
            form={simpleForm}
            mapState={map}
            hideAction={hideAction}
            saveLocation={saveLocation}
            setLayersVisibility={setLayersVisibility}
            switchMap={switchMap}
            toggleModeAction={!isEventAdmin ? toggleModeAction : undefined}
            submitForm={submitForm}
            hasPickedLocation={!!map.pickedEvent}
            bimLayers={bimLayers}
            userLayerVisibility={eventMapUserLayerVisibility}
            setUserLayersVisibilityAction={v => changeUserMapLayersVisibility(v)}
            changeFloorLayerVisibility={v => changeFloorLayerVisibility(v)}
            resetSelectedLocation={() => resetLocation()}
        />
    )
}

export const EventModalsExtendedFormClass: FC<
    EventModalsProps & EventModalsDispatchProps
> = props => {
    const {
        modalKind,
        isOpen,
        isEventAdmin,
        keyPrefix,
        extendedForm,
        map,
        hideAction,
        setLayersVisibility,
        switchMap,
        toggleModeAction,
        setFormPristine,
        saveAction,
        currentEvent,
        parentFeedInId,
        changeFormValue,
        attachmentsState,
        attachmentsAction,
        resetLocation,
        groups,
        bimLayers,
        eventMapUserLayerVisibility,
        setEventMapUserLayersVisibilityAction,
        formType,
    } = props

    const submitForm = () => {
        if (!extendedForm.valid) {
            setFormPristine(false)
        } else {
            saveAction([currentEvent, parentFeedInId])
            hideAction()
        }
    }
    const changeForm = (v: Partial<EventPayload>) => {
        changeFormValue({ ...currentEvent, ...v })
    }
    const saveLocation = () => {
        props.saveLocation()
    }

    const changeUserMapLayersVisibility = (v: string[]) => {
        setEventMapUserLayersVisibilityAction({
            ...eventMapUserLayerVisibility,
            list: v,
        })
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...eventMapUserLayerVisibility,
            list: [
                ...eventMapUserLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setEventMapUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    return (
        <MapFormAwareModal
            map={() => (
                <SyncWrapper
                    onEnter={eventModalsMapParentAction(openMapAction())}
                    onLeave={eventModalsMapParentAction(closeMapAction())}
                >
                    <EventMap />
                </SyncWrapper>
            )}
            content={({ setView }) => (
                <EventExtendedForm
                    setViewMode={setView}
                    changeForm={changeForm}
                    currentEvent={currentEvent}
                    attachmentsAction={attachmentsAction}
                    attachmentsState={attachmentsState}
                    form={extendedForm}
                    location={map.pickedEvent || undefined}
                    isEditingEvent={props.isEditingEvent}
                    resetLocation={resetLocation}
                    isEventAdmin={isEventAdmin}
                    groups={groups}
                    formType={formType}
                />
            )}
            modalKind={modalKind}
            isOpen={isOpen}
            keyPrefix={keyPrefix}
            form={extendedForm}
            mapState={map}
            hideAction={hideAction}
            saveLocation={saveLocation}
            setLayersVisibility={setLayersVisibility}
            switchMap={switchMap}
            toggleModeAction={!isEventAdmin ? toggleModeAction : undefined}
            submitForm={submitForm}
            hasPickedLocation={!!map.pickedEvent}
            bimLayers={bimLayers}
            userLayerVisibility={eventMapUserLayerVisibility}
            setUserLayersVisibilityAction={v => changeUserMapLayersVisibility(v)}
            changeFloorLayerVisibility={v => changeFloorLayerVisibility(v)}
            resetSelectedLocation={() => resetLocation()}
        />
    )
}
