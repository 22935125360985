import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import {
    AosChecklistUnits,
    mapChecklistUnitToUserRole,
} from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import { AosUnitTaskItem } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import {
    ATCDashboardTag,
    EditableChecklist,
    TaskChangeObject,
    TaskOrMessageTemplate,
} from 'aos-services/src/services/events/types/AosEventChecklist'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { PlusButton } from 'aos-ui/src/components/buttons/PlusButton'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import { chain } from 'lodash'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../../core/state'
import { ApocChecklist } from './ApocChecklist'
import { UnitTaskChecklist } from './UnitTaskChecklist'

export const EventChecklists: FC<EventChecklistsProps> = ({
    checklists,
    unitTasks,
    changeTab,
    eventId,
    toggleTask,
    isEditable,
    toggleChecklist,
    openChecklistSelection,
    changeTask,
    copyMessageTemplate,
    isEventAdmin,
    isAtc,
    isOpen,
}) => {
    const user = useSelector((state: State) => state.auth.userProfile)

    const [checklistHeader, setChecklistHeader] = useState<AosChecklistFilter>(
        isEventAdmin ? AosChecklistFilter.EventChecklist : AosChecklistFilter.UnitTasks,
    )

    const unitItems: AosChecklistUnits[] = useMemo(
        () =>
            chain(unitTasks)
                .map(q => q.unit)
                .uniq()
                .value(),
        [unitTasks],
    )

    const [selectedUnits, setSelectedUnits] = useState<AosChecklistUnits[]>(unitItems)

    const header = useCallback(() => {
        const title = translate('event-checklists.label')
        const renderRightButton = () => {
            return checklistHeader === AosChecklistFilter.UnitTasks && isAtc ? (
                <CheckboxDropdown
                    width={200}
                    value={selectedUnits}
                    valueRenderer={(i: AosChecklistUnits) =>
                        translate(`unit.${mapChecklistUnitToUserRole[i]}`)
                    }
                    partialContent={i => `Selected: ${i.length}`}
                    onChange={(v: AosChecklistUnits[]) => setSelectedUnits(v)}
                    allContent={translate('event-checklist.all')}
                    items={unitItems}
                />
            ) : isEventAdmin ? (
                <PlusButton
                    onClick={openChecklistSelection}
                    seleniumLocation={`${title}-action-button`}
                />
            ) : null
        }

        return (
            <HeaderContainer
                rightButtonVisible={!!openChecklistSelection}
                title={title}
                rightButton={renderRightButton()}
            />
        )
    }, [isEditable, openChecklistSelection, checklistHeader, isEventAdmin, selectedUnits])

    const apocVersion = () => (
        <>
            <UnderlineTabs<AosChecklistFilter>
                stretch
                underlined
                variant={ThemeVariant.Black}
                nameFormatter={v => translateEnum(AosChecklistFilter, 'checklist-manager.label')(v)}
                value={checklistHeader}
                onChange={setChecklistHeader}
                items={[AosChecklistFilter.UnitTasks, AosChecklistFilter.EventChecklist]}
            />
            {checklistHeader === AosChecklistFilter.UnitTasks ? (
                <UnitTaskChecklist
                    disabled={!isOpen}
                    filter={selectedUnits}
                    isEventAdmin={!!isEventAdmin}
                    unitTasks={unitTasks || []}
                    user={user}
                    changeTask={changeTask}
                />
            ) : isAtc ? (
                <ApocChecklist
                    eventId={eventId}
                    isEventAdmin={!!isEventAdmin}
                    toggleTask={toggleTask}
                    toggleChecklist={toggleChecklist}
                    changeTab={changeTab}
                    copyMessageTemplate={copyMessageTemplate}
                    isEditable={isEditable}
                    checklists={
                        isAtc ? checklists?.filter(q => q.tag === ATCDashboardTag) : checklists
                    }
                    openChecklistSelection={openChecklistSelection}
                />
            ) : (
                <ApocChecklist
                    eventId={eventId}
                    isEventAdmin={!!isEventAdmin}
                    toggleTask={toggleTask}
                    toggleChecklist={toggleChecklist}
                    changeTab={changeTab}
                    copyMessageTemplate={copyMessageTemplate}
                    isEditable={isEditable}
                    checklists={checklists}
                    openChecklistSelection={openChecklistSelection}
                />
            )}
        </>
    )

    const renderContent = () => {
        if (isAtc) {
            if (isEventAdmin) {
                return apocVersion()
            } else {
                return (
                    <UnitTaskChecklist
                        disabled={!isOpen}
                        filter={selectedUnits}
                        isEventAdmin={!!isEventAdmin}
                        unitTasks={unitTasks || []}
                        user={user}
                        changeTask={changeTask}
                    />
                )
            }
        } else {
            return (
                <ApocChecklist
                    eventId={eventId}
                    isEventAdmin={!!isEventAdmin}
                    toggleTask={toggleTask}
                    toggleChecklist={toggleChecklist}
                    changeTab={changeTab}
                    copyMessageTemplate={copyMessageTemplate}
                    isEditable={isEditable}
                    checklists={checklists}
                    openChecklistSelection={openChecklistSelection}
                />
            )
        }
    }

    return <PanelWithHeader header={header()}>{renderContent()}</PanelWithHeader>
}

interface EventChecklistsProps {
    eventId: number
    isEditable: boolean
    isOpen: boolean
    checklists?: EditableChecklist[]
    unitTasks?: AosUnitTaskItem[]
    toggleChecklist(v: number): void
    toggleTask(v: TaskChangeObject): void
    changeTask(itemId: number, item: AosUnitTaskItem): void
    changeTab(v: [number, TaskOrMessageTemplate]): void
    copyMessageTemplate(v: AosChecklistItem): void
    openChecklistSelection?(): void
    isEventAdmin?: boolean
    isAtc?: boolean
}
