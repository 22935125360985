import { setEventMapUserLayersVisibilityAction } from 'aos-services/src/core/eventUserLayersVisibility/actions'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { connect } from 'react-redux'

import {
    activateEventAction,
    changeFormValueAction,
    eventHideModalAction,
    eventModalsAttachmentsParentAction,
    eventModalsMapAction,
    saveLocationAction,
    setExtendedFormPristineAction,
} from '../../core/eventModals/actions'
import { EventModal } from '../../core/eventModals/state'
import { State } from '../../core/state'
import {
    EventModalsDispatchProps,
    EventModalsExtendedFormClass,
    EventModalsProps,
} from './EventModalsClass'
import { EventFormType } from './partial/EventFormProps'

export const ActivateEventModal = connect<EventModalsProps, EventModalsDispatchProps>(
    (state: State) => ({
        simpleForm: state.eventModals.simpleForm,
        extendedForm: state.eventModals.extendedForm,
        currentEvent: state.eventModals.currentEvent,
        attachmentsState: state.eventModals.attachmentsState,
        map: state.eventModals.map,
        parentFeedInId: state.eventModals.parentFeedInId,
        keyPrefix: 'activate-event',
        isOpen: state.eventModals.currentModal === EventModal.ActivateEvent,
        isEditingEvent: true,
        isEventAdmin: state.auth.currentUser.isEventAdmin(),
        modalKind: ModalKind.Big,
        groups: state.commonFrontend.groups,
        bimLayers: state.bimLayers,
        eventMapUserLayerVisibility: state.eventMapUserLayerVisibility,
        formType: EventFormType.ActivateEventModal,
    }),
    {
        saveAction: activateEventAction,
        changeFormValue: changeFormValueAction,
        attachmentsAction: eventModalsAttachmentsParentAction,

        saveLocation: saveLocationAction,
        switchMap: eventModalsMapAction.switchMapAction,
        setLayersVisibility: eventModalsMapAction.setLayersVisibilityAction,
        resetLocation: eventModalsMapAction.resetPickedLocationAction,
        pickLocation: eventModalsMapAction.pickLocationAction,

        hideAction: eventHideModalAction,
        setFormPristine: setExtendedFormPristineAction,
        setEventMapUserLayersVisibilityAction,
    },
)(EventModalsExtendedFormClass)
