import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import styled, { CSSObject } from 'styled-components'

import { Box, BoxProps } from '../../base/Box'

interface CheckableBlockProps extends BoxProps {
    isSelected: boolean
    disabled?: boolean
    color: Color
}

export const CheckableBlock = styled(Box)<CheckableBlockProps>(
    ({ isSelected, height, color, disabled }: CheckableBlockProps) => {
        const styles: CSSObject = {
            borderRadius: 5,
            fontWeight: 500,
            fontSize: 12,
            height: height || 40,
            borderWidth: 1,
            borderStyle: 'solid',
        }

        if (!disabled && !isSelected) {
            styles.color = Color.Grey2
            styles.backgroundColor = Color.White
            styles.borderColor = Color.DisabledText
            styles.cursor = 'pointer'
        }

        if (!disabled && isSelected) {
            styles.color = Color.PrimaryComponentLabel
            styles.backgroundColor = color
            styles.borderColor = 'transparent'
            styles.cursor = 'pointer'
        }

        if (disabled && !isSelected) {
            styles.color = rgba(Color.TimelineBackground, 0.5)
            styles.backgroundColor = Color.White
            styles.borderColor = Color.DisabledText
            styles.opacity = 0.75
        }

        if (disabled && isSelected) {
            styles.color = Color.White
            styles.backgroundColor = rgba(Color.TimelineBackground, 0.5)
            styles.borderColor = 'transparent'
        }

        if (disabled) {
            styles.cursor = 'not-allowed'
        }

        return styles
    },
)
