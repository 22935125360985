import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Regularity } from './Regularity'
import { RegularityTile } from './RegularityTile'

export const getRegularityConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 12, h: 4 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 6 },
            maxSize: { w: 10, h: 10 },
        },
    },
    renderer: Regularity,
    tileRenderer: RegularityTile,
})
