import { MonthAxis } from 'aos-components/src/components/chart/axes/MonthAxis'
import { Chart } from 'aos-components/src/components/chart/Chart'
import { LinearBarSeries } from 'aos-components/src/components/chart/series/LinearBarSeries'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { calculateDomain, domainForTicks } from 'aos-helpers/src/helpers/domain/Domain'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { DateTime, startOfMonths } from 'aos-helpers/src/helpers/Time'
import { formatDay } from 'aos-helpers/src/helpers/TimeFormat'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import {
    getDayColor,
    PaxForecastDaysChartPoint,
} from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { PaxForecastDataSource } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastDataSource'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import { darken } from 'polished'
import React, { PureComponent } from 'react'

import { PaxForecastDaysChartLegend } from './PaxForecastDaysChartLegend'

interface PaxForecastDaysChartProps {
    data: PaxForecastDaysChartPoint[]
}

export class PaxForecastDaysChart extends PureComponent<PaxForecastDaysChartProps> {
    public render() {
        const { data } = this.props
        const y1Domain = calculateDomain(data, this.yAccessor, 7)
        const y2Domain = calculateDomain(data, this.totalFlightsAccessor, 7)
        const xDomain = domainForTicks(data.map(this.xAccessor))
        return (
            <Chart
                flex
                xBandConfig={{
                    domain: xDomain,
                    tickFormat: (t: DateTime) => formatDay(t) + '\n' + t.format('dd'),
                    bandPadding: { x0: { inner: 0.66, outer: 0 } },
                    barCount: 2,
                    tickLabelStyle: (t: DateTime) => ({
                        color:
                            t.isoWeek() % 2 === 0
                                ? darken(0.15, Color.TextSecondary)
                                : Color.TextSecondary,
                        fontSize: 12,
                    }),
                }}
                y1Config={{
                    domain: y1Domain,
                    tickFormat: formatNumber,
                    unit: Unit.Minute,
                    showAxisLine: false,
                }}
                y2Config={{
                    domain: y2Domain,
                    tickFormat: formatNumber,
                    showAxisLine: false,
                }}
                topAxisSize={40}
                bottomAxisSize={60}
                bottomLegend={{ component: <PaxForecastDaysChartLegend />, height: 30 }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { xBandScale, y1Scale, y2Scale, xBandConfig } = props
        if (!xBandScale || !y1Scale || !y2Scale || !xBandConfig) {
            return null
        }
        const { data } = this.props
        const scalesLeft = {
            xScale: xBandScale.x0,
            yScale: y1Scale,
        }
        const scalesRight = {
            xScale: xBandScale.x0,
            yScale: y2Scale,
        }

        return (
            <>
                <MonthAxis
                    axisConfig={{ tickValues: startOfMonths(xBandConfig?.domain.ticks) }}
                    scale={scalesLeft.xScale}
                />
                <LinearBarSeries
                    data={data}
                    seriesConfig={{ color: getDayColor }}
                    accessors={{
                        xAccessor: this.xAccessor,
                        yAccessor: this.yAccessor,
                    }}
                    scales={scalesLeft}
                />

                <LineSeries
                    data={data}
                    accessors={{
                        xAccessor: this.xAccessor,
                        yAccessor: this.totalFlightsAccessor,
                    }}
                    scales={scalesRight}
                    seriesConfig={{
                        color: Color.White,
                        withDots: true,
                    }}
                />
            </>
        )
    }
    private xAccessor = (d: PaxForecastDaysChartPoint) => d.time
    private yAccessor = (d: PaxForecastDaysChartPoint) => d[PaxForecastDataSource.Forecast].pax
    private totalFlightsAccessor = (d: PaxForecastDaysChartPoint) =>
        d[PaxForecastDataSource.Forecast].flightsCount
}
