import { AosSeverity } from '../common/types/AosSeverity'
import { AosAirport, MainAirport, processCategories } from '../flightInformation/types/AosAirport'
import { AosDashboardEvent } from './types/AosDashboardEvent'
import { AosEventVisibility } from './types/AosEventVisibility'
import { AosTimelineEvent } from './types/AosTimelineEvent'

type AosEvent = AosTimelineEvent | AosDashboardEvent
export const filterEventUserVisibility = (
    event: AosEvent,
    siteLocation: AosAirport,
    isEventAdmin: Boolean,
) => {
    const isEmergencyOrDisruption =
        event.severity === AosSeverity.Emergency || event.severity === AosSeverity.Incident
    const isNetworkProcess = !!processCategories[event.process]?.isNetwork

    if (siteLocation === MainAirport) {
        return isPublicEvent(event) || event.hasPrivateChannel || isEventAdmin
    } else {
        if (isPublicEvent(event) || isEventAdmin) {
            return isEmergencyOrDisruption || isNetworkProcess
        } else {
            return event.hasPrivateChannel
        }
    }
}
function isPublicEvent(event: AosEvent): Boolean {
    return event.visibility === AosEventVisibility.Public
}
