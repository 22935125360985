import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'

import { AosSeverity } from '../../common/types/AosSeverity'

export enum TaskStatus {
    Todo = 'TODO',
    InProgress = 'IN_PROGRESS',
    OnHold = 'ON_HOLD',
    Done = 'DONE',
}
export const statusBorderColorsMapping: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.SideMenuInactive,
    [TaskStatus.InProgress]: Color.Green,
    [TaskStatus.OnHold]: Color.TextSecondary,
    [TaskStatus.Done]: Color.ChartBase,
}
export const statusBorderColorMappingInfo: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.TaskBlue,
    [TaskStatus.InProgress]: Color.Green,
    [TaskStatus.OnHold]: Color.TaskBlue,
    [TaskStatus.Done]: Color.ChartBase,
}
export const statusFillColorMappingInfo: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.TaskBlue,
    [TaskStatus.InProgress]: Color.TaskBlue,
    [TaskStatus.OnHold]: Color.TimelineBackground,
    [TaskStatus.Done]: Color.TimelineBackground,
}
export const statusBorderColorMappingNormal: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.Grey3,
    [TaskStatus.InProgress]: Color.Green,
    [TaskStatus.OnHold]: Color.TextSecondary,
    [TaskStatus.Done]: Color.TextSecondary,
}

export const statusFillColorMappingNormal: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.Grey3,
    [TaskStatus.InProgress]: Color.Grey3,
    [TaskStatus.OnHold]: Color.TimelineBackground,
    [TaskStatus.Done]: Color.Grey3,
}

export const statusColorsMapping: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.Grey3,
    [TaskStatus.InProgress]: Color.Green,
    [TaskStatus.OnHold]: Color.TimelineBackground,
    [TaskStatus.Done]: Color.TimelineBackground,
}
export const emergencyStatusBorderColorsMapping: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.Red,
    [TaskStatus.InProgress]: Color.Green,
    [TaskStatus.OnHold]: Color.Red,
    [TaskStatus.Done]: Color.ChartBase,
}
export const emergencyStatusColorsMapping: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.Red,
    [TaskStatus.InProgress]: Color.Red,
    [TaskStatus.OnHold]: Color.TimelineBackground,
    [TaskStatus.Done]: Color.TimelineBackground,
}
export const statusTextColorsMapping: Record<TaskStatus, Color> = {
    [TaskStatus.Todo]: Color.White,
    [TaskStatus.InProgress]: Color.White,
    [TaskStatus.OnHold]: Color.White,
    [TaskStatus.Done]: Color.SideMenuInactive,
}

export const taskStatuses: TaskStatus[] = EnumValues.getValues(TaskStatus)
export const translateTaskTitle = translateEnum(TaskStatus, 'task-status')

export interface TaskDuration {
    hour: number
    minute: number
    startTime?: DateTime
    endTime?: DateTime
}

export interface TaskStatusUpdate {
    status: TaskStatus
    spendTimeInMinutes?: number
    severity: AosSeverity
    startTime?: DateTime
    endTime?: DateTime
}

export type TaskStatusUpdateDto = Omit<TaskStatusUpdate, 'severity'>
