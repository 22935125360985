import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

interface SidebarProps {
    title: ReactNode
    subtitle?: ReactNode
}

export const Sidebar: FCWithChildren<PropsWithChildren<SidebarProps>> = props => (
    <SidebarBox column bg={Color.BackgroundDark}>
        <Text shrink={0} color={Color.White} size={18} paddingVertical={30} paddingHorizontal={30}>
            {props.title}
        </Text>
        <Text shrink={0} color={Color.White} size={14} paddingHorizontal={30}>
            {props.subtitle}
        </Text>
        <Box flex={1}>
            <DarkScrollbar>
                <Box paddingHorizontal={30} fullHeight>
                    {props.children}
                </Box>
            </DarkScrollbar>
        </Box>
    </SidebarBox>
)

const SidebarBox = styled(Box)`
    width: 360px;
    flex-shrink: 0;
`
