import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const t = (suffix: string) => translate(`dashboard.help.pax-forecast.${suffix}`)

export const getPaxForecastHelpConfig = (): HelpModalConfig => ({
    title: t('title'),
    items: [
        {
            title: t('pax-forecast'),
            renderer: lazy(() => import('./renderers/PaxForecastHelp')),
        },
        {
            title: t('time-ranges'),
            renderer: lazy(() => import('./renderers/PaxForecastTimeRangesHelp')),
        },

        {
            title: t('colors'),
            renderer: lazy(() => import('./renderers/PaxForecastColorsHelp')),
        },
        {
            title: t('chart-six-view.title'),
            note: t('chart-six-view.note'),
            renderer: lazy(() => import('./renderers/PaxForecastChartSixWeeksHelp')),
        },
        {
            title: t('chart-day-hours.title'),
            note: t('chart-day-hours.note'),
            renderer: lazy(() => import('./renderers/PaxForecastChartDayHoursHelp')),
        },
        {
            title: t('chart-day-ranges.title'),
            note: t('chart-day-ranges.note'),
            renderer: lazy(() => import('./renderers/PaxForecastChartDayRangesHelp')),
        },
    ],
})
