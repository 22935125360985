import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PropsWithChildren, PureComponent } from 'react'

export class TrainTimeTableRow extends PureComponent<PropsWithChildren> {
    public render() {
        const items = React.Children.toArray(this.props.children)

        return (
            <Box row justify='flex-start'>
                <Box flex={1}>
                    <Text textAlign='left' color={Color.TextSecondary} size={12}>
                        {items[0]}
                    </Text>
                </Box>
                <Box flex={1} row>
                    <Text flex={1} textAlign='center' color={Color.TextSecondary} size={12}>
                        {items[1]}
                    </Text>
                    <Text flex={1} textAlign='center' color={Color.TextSecondary} size={12}>
                        {items[2]}
                    </Text>
                </Box>
            </Box>
        )
    }
}
