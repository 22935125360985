import { mapProvider } from 'aos-map-engine/src'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { useEffect } from 'react'

export const useLoadBimLayers = (
    variant: MapVariant,
    bimMapLayers: BimLayersState,
    loadBimLayersDataAction: () => void,
) => {
    useEffect(() => {
        ;(async () => {
            await mapProvider
            if (bimMapLayers.isLoading) {
                loadBimLayersDataAction()
            }
        })()
    }, [bimMapLayers, variant])
}
