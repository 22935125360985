import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { RestrictionsTile } from './RestrictionsTile'

export const getRestrictionsConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 2, h: 2 },
            maxSize: { w: 6, h: 12 },
        },
    },
    tileRenderer: RestrictionsTile,
})
