import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { WeatherSeries } from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import {
    translateWeatherGroup,
    translateWeatherTimeRange,
    translateWeatherUnit,
    WeatherGroup,
    WeatherTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'

import { Footer } from '../../base/Footer'
import { WeatherConditionsChart } from './WeatherConditionsChart'
import { WeatherWindCloudsChart } from './WeatherWindCloudsChart'

export class WeatherFullView extends PureComponent<WeatherFullViewProps> {
    public render() {
        const {
            groupTab,
            timeRangeTab,
            unitTab,
            weatherSeries,
            onChangeGroup,
            onChangeTimeRange,
            onChangeUnit,
            isMainAirport,
        } = this.props

        const tabsConfig: RoundTabsProps<number | string>[] = [
            {
                items: [WeatherGroup.Conditions, WeatherGroup.WindAndClouds],
                value: groupTab,
                name: 'weather-group-tab',
                formatter: translateWeatherGroup,
                onChange: onChangeGroup,
                tooltip: translate('dashboard.weather.tabs.group-tooltip'),
            },
            {
                items: EnumValues.getValues(WeatherTimeRange),
                value: timeRangeTab,
                name: 'weather-time-range-tab',
                formatter: translateWeatherTimeRange,
                onChange: onChangeTimeRange,
                tooltip: translate('dashboard.weather.tabs.time-tooltip'),
                width: DropdownWidth.Small,
            },
            {
                items: EnumValues.getValues(WeatherUnit),
                value: unitTab,
                name: 'weather-unit-tab',
                formatter: translateWeatherUnit,
                onChange: onChangeUnit,
                tooltip: translate('dashboard.weather.tabs.unit-tooltip'),
                width: DropdownWidth.Small,
            },
        ]

        return (
            <>
                <Box bg={Color.WeatherChartBackground} flex='auto' paddingVertical={20}>
                    <Box relative fullHeight column>
                        {groupTab === WeatherGroup.Conditions && (
                            <WeatherConditionsChart
                                isMainAirport={isMainAirport}
                                unitTab={unitTab}
                                timeRangeTab={timeRangeTab}
                                weatherSeries={weatherSeries}
                            />
                        )}
                        {groupTab === WeatherGroup.WindAndClouds && (
                            <WeatherWindCloudsChart
                                unitTab={unitTab}
                                timeRangeTab={timeRangeTab}
                                weatherSeries={weatherSeries}
                            />
                        )}
                    </Box>
                </Box>
                <Footer>
                    <MultiRoundTabs items={tabsConfig} />
                </Footer>
            </>
        )
    }
}

export interface WeatherFullViewProps {
    isMainAirport: boolean
    weatherSeries: WeatherSeries
    timeRangeTab: WeatherTimeRange
    groupTab: WeatherGroup
    unitTab: WeatherUnit
    onChangeGroup(v: WeatherGroup): void
    onChangeTimeRange(v: WeatherTimeRange): void
    onChangeUnit(v: WeatherUnit): void
}
