import * as Sentry from '@sentry/react'
import { LoggingProvider } from 'aos-helpers/src/helpers/logging/LoggingProvider'
import { createBrowserHistory } from 'history'

import { AppConfig } from '../config/types/ExternalConfig'
import { LoggingConfigService } from './LoggingConfigService'

const history = createBrowserHistory()

class SentryConfigService implements LoggingConfigService {
    public configure(config: AppConfig, useFeedback: boolean): LoggingProvider | null {
        if (config.sentryUrl) {
            const integrations = useFeedback
                ? [
                      Sentry.reactRouterV5BrowserTracingIntegration({
                          history,
                      }),
                      Sentry.feedbackIntegration({
                          colorScheme: 'dark',
                          showBranding: false,
                          formTitle: 'Give Feedback',
                          buttonLabel: 'Give Feedback',
                          submitButtonLabel: 'Send Feedback',
                          nameLabel: 'Username',
                          isEmailRequired: true,
                      }),
                      Sentry.browserApiErrorsIntegration(),
                  ]
                : [
                      Sentry.reactRouterV5BrowserTracingIntegration({
                          history,
                      }),
                      Sentry.browserApiErrorsIntegration(),
                  ]
            Sentry.init({
                dsn: config.sentryUrl,
                environment: config.environmentName,
                release: config.version,
                profilesSampleRate: 0.5,
                integrations,
            })

            return {
                captureException: (ex: Error, options?: any) => {
                    if (options) {
                        Sentry.withScope(scope => {
                            scope.setExtras(options)
                            Sentry.captureException(ex)
                        })
                    } else {
                        Sentry.captureException(ex)
                    }
                },
            }
        }
        return null
    }
}

export const sentryConfigService = new SentryConfigService()
