import { appConfig } from 'appConfig'

import bgLogoAOS from './bg-logo-aos.svg'
import bgLogoAOIMS from './bg-logo-tll.svg'
import errorMessageIcon from './error-message.png'
import logoAOS from './logo.svg'
import logoAOIMS from './logoAOIMS.svg'
import successMessageIconAOS from './success-message.png'
import successMessageIconAOIMS from './success-message-tll.png'

const AOSIcons = {
    logo: logoAOS,
    bgLogo: bgLogoAOS,
    errorMessageIcon,
    successMessageIcon: successMessageIconAOS,
}

const AOIMSIcons = {
    logo: logoAOIMS,
    bgLogo: bgLogoAOIMS,
    errorMessageIcon,
    successMessageIcon: successMessageIconAOIMS,
}

const IconConfig: Record<string, typeof AOSIcons> = {
    AOS: AOSIcons,
    AOIMS: AOIMSIcons,
}

export const Assets: Record<string, any> = {
    ...IconConfig[appConfig.theme],
}
