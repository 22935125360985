import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { getWeatherHelpConfig } from '../../help/weather/weatherHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Weather } from './Weather'
import { WeatherForecast } from './weatherForecast/WeatherForecast'
import { WeatherTile } from './WeatherTile'

export const getWeatherForecastConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 12, h: 3 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 7 },
            maxSize: { w: 10, h: 10 },
        },
        [DashboardItemSizeVariant.Custom1]: {
            minSize: { w: 12, h: 4 },
            maxSize: { w: 12, h: 4 },
        },
        [DashboardItemSizeVariant.Custom2]: {
            minSize: { w: 12, h: 5 },
            maxSize: { w: 12, h: 5 },
        },
        [DashboardItemSizeVariant.Custom3]: {
            minSize: { w: 12, h: 6 },
            maxSize: { w: 12, h: 6 },
        },
    },
    renderer: WeatherForecast,
})
export const getWeatherConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 12, h: 3 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 7 },
            maxSize: { w: 10, h: 10 },
        },
        [DashboardItemSizeVariant.Custom1]: {
            minSize: { w: 12, h: 4 },
            maxSize: { w: 12, h: 4 },
        },
        [DashboardItemSizeVariant.Custom2]: {
            minSize: { w: 12, h: 5 },
            maxSize: { w: 12, h: 5 },
        },
        [DashboardItemSizeVariant.Custom3]: {
            minSize: { w: 12, h: 6 },
            maxSize: { w: 12, h: 6 },
        },
    },
    renderer: Weather,
    helpConfig: getWeatherHelpConfig(),
    tileRenderer: WeatherTile,
})
