import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { emptyText } from 'aos-helpers/src/helpers/Text'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { AosCommonMessage } from 'aos-services/src/services/messages/types/AosCommonMessage'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { MessageBubble } from 'aos-ui/src/components/message/MessageBubble'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

const predefinedBackendTextParts = ['has been added', 'has been changed']

export class MessagesContainer extends PureComponent<MessagesContainerProps> {
    public render() {
        return <Box className={cxp(this.props)}>{this.props.messages.map(this.renderMessage)}</Box>
    }

    private renderMessage = (item: AosCommonMessage) => {
        const messageBackgroundColor = predefinedBackendTextParts.some(q => item.text.includes(q))
            ? Color.MessageSpecificBackgroundColor
            : undefined
        return (
            <Box paddingBottom={30} key={item.id}>
                <MessageBubble
                    isAutomatic={item.automated}
                    text={item.text}
                    recipients={item.group ? aosUserGroupName(item.group, 'message') : emptyText()}
                    time={formatCalendarTime(item.createdAt)}
                    channels={item.channels}
                    variant={this.props.messagesTitleVariant}
                    messageBackgroundColor={messageBackgroundColor}
                />
            </Box>
        )
    }
}

interface MessagesContainerProps extends ClassNameProps {
    messages: AosCommonMessage[]
    messagesTitleVariant: ThemeVariant
}
