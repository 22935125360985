import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { newsFeedSelector } from 'aos-services/src/core/statusDashboardData/newsFeed/selectors'
import { Feed } from 'aos-services/src/services/newsFeed/types/Feed'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { DarkScrollableList } from 'aos-ui/src/components/list/DarkScrollableList'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { chain } from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setNewsFeedFilterAction } from '../../../../core/statusDashboard/actions'
import { NewsFeedItemState } from '../../../../services/statusDashboard/types/itemStates/NewsFeedItemState'
import { Footer } from '../base/Footer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { FeedItemBigWidget } from './partials/FeedItemBigWidget'
import { NewsFeedItem } from './partials/NewsFeedItem'

interface NewsFeedProps extends NewsFeedStateProps, NewsFeedDispatchProps, NewsFeedOwnProps {}

export class NewsFeedClass extends PureComponent<NewsFeedProps> {
    public render() {
        const { newsFeed, itemState, id } = this.props

        return (
            <NewsFeedItem
                newsFeed={newsFeed}
                itemProps={this.props}
                filters={itemState.filters}
                syncId={id}
            >
                {newsFeed.length ? this.renderContent() : this.renderEmptyPlaceholder()}
            </NewsFeedItem>
        )
    }

    private renderEmptyPlaceholder() {
        return (
            <Box className='dark-list-item' flex={1}>
                <PanelPlaceholder
                    label={translate('dashboard.news-feed.placeholder')}
                    svg={SvgIcon.MessagePlaceholder}
                />
            </Box>
        )
    }

    private renderContent() {
        const { newsFeed } = this.props
        const lastUpdate = chain(newsFeed)
            .orderBy(q => q.pubDate, 'desc')
            .first()
            .value().pubDate

        return (
            <>
                <DarkScrollableList>
                    {newsFeed.map(feed => (
                        <FeedItemBigWidget key={feed.link} feed={feed} />
                    ))}
                </DarkScrollableList>
                <Footer>
                    <Box columnGap={8} row flex={1}>
                        <Icon
                            iconVariant={IconVariant.GreyTxt}
                            iconSize={BlockSize.Tiny}
                            svg={SvgIcon.CurrentTime}
                        />
                        <Text
                            lineHeight='large'
                            textClomp={2}
                            color={Color.TextSecondary}
                            size={11}
                        >
                            {translate('dashboard.news-feed.footer', {
                                date: formatCalendarTime(lastUpdate),
                            })}
                        </Text>
                    </Box>
                </Footer>
            </>
        )
    }
}

type NewsFeedOwnProps = StatusDashboardItemCommonProps<NewsFeedItemState>

interface NewsFeedStateProps {
    newsFeed: Feed[]
}

interface NewsFeedDispatchProps {
    setNewsFeedFilterAction: typeof setNewsFeedFilterAction
}

export const NewsFeed = connect<NewsFeedStateProps, NewsFeedDispatchProps>(
    createStructuredSelector({
        newsFeed: newsFeedSelector,
    }),
    {
        setNewsFeedFilterAction,
    },
)(NewsFeedClass)
