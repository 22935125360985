import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

export const VerticalTabText: FCWithChildren<VerticalTabTextProps> = props => {
    const { isActive, title, note, onClick } = props

    const textOpacity = isActive ? 1 : 0.73
    const lightTextOpacity = isActive ? 1 : 0.73

    return (
        <Text
            paddingVertical={8}
            paddingLeft={20}
            cursor='pointer'
            size={14}
            onClick={onClick}
            color={Color.White}
            style={{ opacity: textOpacity }}
        >
            {title}
            {note && (
                <Text
                    as='span'
                    size={10}
                    color={Color.White}
                    paddingLeft={8}
                    style={{ opacity: lightTextOpacity }}
                >{`${note}`}</Text>
            )}
        </Text>
    )
}

interface VerticalTabTextProps {
    title: string
    isActive: boolean
    note?: string
    onClick?(): void
}
