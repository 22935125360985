import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'

import { getBaggageDeliveryConfig } from './renderers/baggage/BaggageDeliveryConfig'
import { getCdmConfig } from './renderers/cdm/CdmConfig'
import { getDelaysConfig } from './renderers/delays/DelaysConfig'
import { getEventsConfig } from './renderers/events/EventsConfig'
import { getFireFightersConfig } from './renderers/Firefighters/FireFightersConfig'
import { getFlightsConfig } from './renderers/flights/FlightsConfig'
import { getNewsFeedConfig } from './renderers/newsFeed/NewsFeedConfig'
import { getNotamConfig } from './renderers/notam/NotamConfig'
import { getOperationalForecastConfig } from './renderers/operationalForecast/OperationalForecastConfig'
import { getPaxConfig } from './renderers/pax/PaxConfig'
import { getPaxForecastConfig } from './renderers/paxForecast/PaxForecastConfig'
import { getPunctualityConfig } from './renderers/punctuality/PunctualityConfig'
import { getQueueingTimeBorderConfig } from './renderers/queueingTime/QueueingTimeBorderConfig'
import { getQueueingTimeSecurityConfig } from './renderers/queueingTime/QueueingTimeSecurityConfig'
import { getRegularityConfig } from './renderers/regularity/RegularityConfig'
import { getRestrictionsConfig } from './renderers/restrictions/RestrictionsConfig'
import { getRunwayConfig } from './renderers/runway/RunwayConfig'
import { getShiftNotesConfig } from './renderers/ShiftNotes/ShiftNotesConfig'
import { getRecentTasksConfig } from './renderers/tasks/recent/RecentTasksConfig'
import { getUpcomingTasksConfig } from './renderers/tasks/upcoming/UpcomingTasksConfig'
import { getWeatherConfig, getWeatherForecastConfig } from './renderers/weather/WeatherConfig'
import { DashboardItemsWeb, StatusDashboardItemConfig } from './StatusDashboardItemConfig'

export type StatusDashboardConfig = Record<DashboardItemsWeb, StatusDashboardItemConfig>

export const getStatusDashboardConfig = (): StatusDashboardConfig => ({
    [DashboardItemType.FireFighters]: getFireFightersConfig(),
    [DashboardItemType.ShiftNotes]: getShiftNotesConfig(),
    [DashboardItemType.Weather]: getWeatherConfig(),
    [DashboardItemType.WeatherForecast]: getWeatherForecastConfig(),
    [DashboardItemType.Events]: getEventsConfig(),
    [DashboardItemType.Runways]: getRunwayConfig(),
    [DashboardItemType.NewsFeed]: getNewsFeedConfig(),
    [DashboardItemType.Pax]: getPaxConfig(),
    [DashboardItemType.Flights]: getFlightsConfig(),
    [DashboardItemType.Delays]: getDelaysConfig(),
    [DashboardItemType.Punctuality]: getPunctualityConfig(),
    [DashboardItemType.Cdm]: getCdmConfig(),
    [DashboardItemType.BaggageDelivery]: getBaggageDeliveryConfig(),
    [DashboardItemType.Regularity]: getRegularityConfig(),
    [DashboardItemType.PaxForecast]: getPaxForecastConfig(),
    [DashboardItemType.OperationalForecast]: getOperationalForecastConfig(),
    [DashboardItemType.QueueingTimeBorder]: getQueueingTimeBorderConfig(),
    [DashboardItemType.QueueingTimeSecurity]: getQueueingTimeSecurityConfig(),
    [DashboardItemType.Restrictions]: getRestrictionsConfig(),
    [DashboardItemType.RecentTasks]: getRecentTasksConfig(),
    [DashboardItemType.UpcomingTasks]: getUpcomingTasksConfig(),
    [DashboardItemType.NOTAM]: getNotamConfig(),
})
