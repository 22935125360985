import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface ReportingItemProps {
    title: string
    description: string
    icon: Svg
    onClick: () => void
}
export const ReportingItem: FC<ReportingItemProps> = ({ title, description, icon, onClick }) => {
    return (
        <HoverableBox
            alignItems='center'
            bg={Color.Grey1}
            marginVertical={16}
            paddingVertical={16}
            paddingHorizontal={12}
            rowGap={16}
            columnGap={16}
            onClick={onClick}
        >
            <IconWrapper alignItems='center' justify='center'>
                <Icon color={Color.TimelineBackground} svg={icon} />
            </IconWrapper>
            <Box rowGap={8} column>
                <Text weight='medium' size={14} color={Color.TimelineBackground}>
                    {title}
                </Text>
                <Text weight='medium' size={12} color={Color.ChartBase}>
                    {description}
                </Text>
            </Box>
        </HoverableBox>
    )
}

const IconWrapper = styled(Box)`
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: ${Color.TimelineBackground};
    padding: 8px;
    width: 40px;
    height: 40px;
`

const HoverableBox = styled(Box)`
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
        border: 1px solid ${Color.DisabledText};
        cursor: pointer;
    }
`
