import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, {
    DependencyList,
    PropsWithChildren,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled, { CSSObject } from 'styled-components'

import { Box, BoxProps, marginStyleGenerator, paddingStyleGenerator } from '../base/Box'
import { textStyleBuilder } from '../base/Text'
import { ThemeVariant } from '../base/ThemeVariant'
import { useAnimationEnd } from '../hooks/useAnimationEnd'
import { useWindowSize } from '../hooks/useWindowSize'
import { Icon } from '../svg/Icon'

export interface UnderlineTabsProps<T> extends BoxProps {
    items: T[]
    value?: T
    stretch?: boolean
    variant?: ThemeVariant
    underlined?: boolean
    nameFormatter?(t: T): string
    iconFormatter?(t: T): Svg
    comparator?(t: T, t2: T | undefined): boolean
    onChange(v: T): void
}

export const UnderlineTabs = <T extends {}>(props: UnderlineTabsProps<T>) => {
    const {
        items,
        value,
        className,
        stretch = false,
        underlined = true,
        variant = ThemeVariant.White,
        onChange,
        nameFormatter,
        iconFormatter,
        ...margins
    } = props
    const wrapperEl = useRef<HTMLDivElement>(null)
    const activeEl = useRef<HTMLButtonElement>(null)
    const { left, width } = useLinePosition(wrapperEl, activeEl, [items, value, stretch])
    const lineStyle = { width, transform: `translateX(${left}px)` }

    return (
        <TabsWrapper
            stretch={stretch}
            underlined={underlined}
            variant={variant}
            ref={wrapperEl}
            {...margins}
        >
            {items.map((item: T, index: number) => (
                <Tab
                    key={index}
                    currentItem={item}
                    value={value}
                    activeRef={activeEl}
                    index={index}
                    onChange={onChange}
                    variant={variant}
                    stretch={stretch}
                    nameFormatter={nameFormatter}
                    iconFormatter={iconFormatter}
                />
            ))}
            <Line bg={config[variant].activeColor} style={lineStyle} />
        </TabsWrapper>
    )
}

interface TabsWrapperProps extends BoxProps {
    stretch: boolean
    variant: ThemeVariant
    underlined?: boolean
}

const config: Record<ThemeVariant, { color: Color; activeColor: Color }> = {
    [ThemeVariant.White]: {
        color: Color.Grey2,
        activeColor: Color.PrimaryDark,
    },
    [ThemeVariant.Black]: {
        color: Color.WhiteOpacity45,
        activeColor: Color.White,
    },
}

const TabsWrapper = styled.div(({ underlined, stretch, variant, ...rest }: TabsWrapperProps) => {
    const margins = marginStyleGenerator(rest)
    const paddings = paddingStyleGenerator(rest)

    const styles: CSSObject = {
        display: 'flex',
        position: 'relative',
    }

    if (underlined && variant === ThemeVariant.White) {
        styles.borderBottom = `1px solid ${Color.DisabledText}`
    }
    if (underlined && variant === ThemeVariant.Black) {
        styles.borderBottom = `1px solid ${Color.TextSecondary}`
    }

    return { ...margins, ...paddings, ...styles }
})

interface TabProps<T> {
    currentItem: T
    activeRef?: RefObject<HTMLButtonElement>
    index: number
    value?: T
    variant: ThemeVariant
    stretch: boolean
    nameFormatter?(t: T): string
    iconFormatter?(t: T): Svg
    onChange(v: T): void
}

const Line = styled(Box)`
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    transition: 0.3s ease all;
`

const Tab = <T extends {}>({
    currentItem,
    value,
    onChange,
    nameFormatter,
    iconFormatter,
    activeRef,
    index,
    variant,
    stretch,
}: TabProps<T>) => {
    const icon = iconFormatter && iconFormatter(currentItem)
    const name = nameFormatter ? nameFormatter(currentItem) : currentItem
    const isActive = currentItem === value
    return (
        <TabsItemWrapper
            as='button'
            onClick={() => onChange(currentItem)}
            isActive={isActive}
            index={index}
            variant={variant}
            stretch={stretch}
            ref={isActive ? activeRef : null}
        >
            <>
                {icon && <Icon iconSize={BlockSize.Std} svg={icon} marginRight={8} />}
                {name}
            </>
        </TabsItemWrapper>
    )
}

interface TabsItemWrapperProps {
    isActive: boolean
    index: number
    variant: ThemeVariant
    stretch: boolean
}

const TabsItemWrapper = styled.div<PropsWithChildren<TabsItemWrapperProps>>(
    ({ isActive, index, variant, stretch }) => {
        const styles: CSSObject = {
            display: 'flex',
            position: 'relative',
            height: '60px',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            textTransform: 'uppercase',
            justifyContent: 'center',
        }
        const themeConfig = config[variant]
        if (index > 0) {
            styles.marginLeft = '30px'
        }
        const fontStyle = textStyleBuilder({
            color: isActive ? themeConfig.activeColor : themeConfig.color,
            size: 14,
        })
        if (stretch) {
            styles.flex = 1
        }
        return { ...styles, ...fontStyle }
    },
)

const useLinePosition = (
    wrapperEl: RefObject<HTMLElement>,
    activeEl: RefObject<HTMLElement>,
    deps: DependencyList,
) => {
    const [left, setLeft] = useState(0)
    const [width, setWidth] = useState(0)
    const { innerWidth } = useWindowSize()
    const { animationEndValue } = useAnimationEnd()

    useEffect(() => {
        if (!activeEl.current || !wrapperEl.current) {
            return
        }
        const activeRect = activeEl.current.getBoundingClientRect()
        const wrapperRect = wrapperEl.current.getBoundingClientRect()

        setLeft(activeRect.left - wrapperRect.left)
        setWidth(activeRect.width)
    }, [...deps, innerWidth, animationEndValue])

    return { left, width }
}
