import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import {
    EditableChecklist,
    TaskChangeObject,
    TaskOrMessageTemplate,
} from 'aos-services/src/services/events/types/AosEventChecklist'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useCallback } from 'react'

import { CollapsableChecklist } from './CollapsableChecklist'

interface EventChecklistsProps {
    checklists?: EditableChecklist[]
    openChecklistSelection?(): void
    isEditable: boolean
    eventId: number
    toggleTask(v: TaskChangeObject): void
    toggleChecklist(checklistId: number): void
    isEventAdmin: boolean
    changeTab(v: [number, TaskOrMessageTemplate]): void
    copyMessageTemplate(v: AosChecklistItem): void
}

export const ApocChecklist: FC<EventChecklistsProps> = ({
    checklists,
    openChecklistSelection,
    isEditable,
    eventId,
    toggleTask,
    isEventAdmin,
    toggleChecklist,
    changeTab,
    copyMessageTemplate,
}) => {
    const renderChecklists = useCallback(
        (checklists: EditableChecklist[]) => {
            return (
                <Box className='event-checklists text--selectable' padding={18}>
                    {checklists.map(checklist => renderChecklist(checklist))}
                </Box>
            )
        },
        [checklists, isEditable],
    )

    const placeholder = useCallback(() => {
        return (
            <Box onClick={openChecklistSelection}>
                <PanelPlaceholder
                    label={translate('event-checklist.add-checklist')}
                    svg={SvgIcon.ChecklistPlaceholder}
                />
            </Box>
        )
    }, [openChecklistSelection])

    const renderChecklist = useCallback(
        (checklist: EditableChecklist) => {
            const onToggle = () => toggleChecklist(checklist.id)
            const onChangeTab = (tab: TaskOrMessageTemplate) => changeTab([checklist.id, tab])
            return (
                <CollapsableChecklist
                    key={checklist.id}
                    checklistId={checklist.id}
                    eventId={eventId}
                    onToggle={onToggle}
                    changeTab={onChangeTab}
                    currentTab={checklist.tab}
                    isEventAdmin={isEventAdmin}
                    name={checklist.name}
                    isCollapsed={checklist.isCollapsed}
                    items={checklist.items}
                    toggleTask={toggleTask}
                    isEditable={isEditable}
                    copyMessageTemplate={copyMessageTemplate}
                />
            )
        },
        [eventId, isEditable, toggleTask, copyMessageTemplate, toggleChecklist, changeTab],
    )

    return checklists && checklists.length ? renderChecklists(checklists) : placeholder()
}
