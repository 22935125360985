import {
    DndDropSpec,
    DndItemType,
    draggableWrapper,
    droppableWrapper,
} from 'aos-components/src/helpers/DnD'
import { DnDWrapper } from 'aos-components/src/helpers/DnDWrapper'
import { BaseDragSourceProps, BaseDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosChecklistTemplate,
    AosSimpleTemplate,
} from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import {
    LabeledTextElement,
    LabeledTextElementSize,
} from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import { SelectableItem } from 'aos-ui/src/components/list/SelectableItem'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { DragSourceSpec } from 'react-dnd'

import { TemplateItemProps } from '../../TemplatesList'

const NEEDS_REVIEW_LIMIT_DATE = moment().subtract(6, 'months')

const isChecklistReviewNeeded = (checklistTemplate: AosSimpleTemplate): boolean =>
    checklistTemplate.reviewedAt?.isBefore(NEEDS_REVIEW_LIMIT_DATE) ?? false

const ChecklistTemplateItemInternal: FC<
    TemplateItemProps<AosChecklistTemplate> & BaseDragSourceProps & BaseDropTargetProps
> = ({ checklistTemplate, canEdit, ...props }) => {
    const onClick = () => props.onSelect(checklistTemplate)
    const onDeleteChecklist = () => props.onDelete!(checklistTemplate)
    const onEditChecklist = () => props.onEdit!(checklistTemplate)
    const onExportChecklist = () => props.onExport(checklistTemplate.id)
    const checklistContent = useMemo(() => {
        return (
            <Box columnGap={8} column={!!checklistTemplate.icon} rowGap={8}>
                <BorderedChip width={checklistTemplate.icon ? 80 : 'auto'}>
                    {checklistTemplate.tag ?? translate('checklist-templates.no-tag')}
                </BorderedChip>
                <Box columnGap={8} row>
                    {checklistTemplate.icon && <Icon svg={checklistTemplate.icon} />}
                    <LabeledTextElement
                        label={checklistTemplate.name}
                        variant={ThemeVariant.Black}
                        size={LabeledTextElementSize.Large}
                        spacing={4}
                    />
                </Box>
            </Box>
        )
    }, [checklistTemplate.icon, checklistTemplate.name, checklistTemplate.tag])

    return (
        <DnDWrapper
            draggable
            droppable
            connectDragSource={props.connectDragSource}
            connectDropTarget={props.connectDropTarget}
        >
            <Box key={checklistTemplate.id} data-test-id='checklist-item-box'>
                <SelectableItem
                    onClick={onClick}
                    isSelected={props.isSelected}
                    paddingHorizontal={30}
                    paddingVertical={20}
                    selectedColor={Color['BackgroundPrimary']}
                >
                    <MoreHover justify='space-between' row>
                        {checklistContent}
                        {canEdit ? (
                            <More>
                                <MoreItem onClick={onDeleteChecklist}>
                                    {translate('checklist-templates.more.delete')}
                                </MoreItem>
                                <MoreItem onClick={onEditChecklist}>
                                    {translate('checklist-templates.more.edit')}
                                </MoreItem>
                                <MoreItem
                                    onClick={onExportChecklist}
                                    disabled={props.exportingChecklistTemplates}
                                >
                                    {translate('checklist-templates.more.export')}
                                </MoreItem>
                            </More>
                        ) : (
                            <More>
                                <MoreItem
                                    onClick={onExportChecklist}
                                    disabled={props.exportingChecklistTemplates}
                                >
                                    {translate('checklist-templates.more.export')}
                                </MoreItem>
                            </More>
                        )}
                    </MoreHover>
                    {isChecklistReviewNeeded(checklistTemplate) && (
                        <>
                            <Text size={12} color={Color.Red} inline>
                                {translate('checklist-manager.checklist-status.needs-review')}{' '}
                            </Text>
                            <Text size={12} color={Color.TextSecondary} inline>
                                {translate('checklist-manager.checklist-status.last-update')}{' '}
                                {checklistTemplate.reviewedAt &&
                                    formatDateTime(checklistTemplate.reviewedAt)}
                            </Text>
                        </>
                    )}
                </SelectableItem>
            </Box>
        </DnDWrapper>
    )
}

const checklistTemplatetemSource: DragSourceSpec<
    TemplateItemProps<AosChecklistTemplate>,
    TemplateItemProps<AosChecklistTemplate>
> = {
    beginDrag(props) {
        return {
            ...props,
        }
    },
    endDrag(props) {
        props.onApplySeqChanges && props.onApplySeqChanges()
    },
}

const checklistTemplateItemTargetSpec: DndDropSpec<TemplateItemProps<AosChecklistTemplate>> = {
    hover(dropElementProps, monitor) {
        const dragElementProps = monitor.getItem() as TemplateItemProps<AosChecklistTemplate>
        if (
            dragElementProps &&
            dragElementProps.seq !== dropElementProps.seq &&
            typeof dragElementProps.onChangeSeq === 'function'
        ) {
            dragElementProps.onChangeSeq({
                fromId: dragElementProps.seq,
                toId: dropElementProps.seq,
            })
        }
    },
}

const dndItem = (type: DndItemType) =>
    droppableWrapper<TemplateItemProps<AosChecklistTemplate>>(
        type,
        checklistTemplateItemTargetSpec,
    )(
        draggableWrapper<
            TemplateItemProps<AosChecklistTemplate>,
            TemplateItemProps<AosChecklistTemplate>
        >(
            type,
            checklistTemplatetemSource,
        )(ChecklistTemplateItemInternal),
    )

export const ChecklistTemplateItem = (withDnd: boolean = true) =>
    withDnd ? dndItem(DndItemType.ChecklistTemplate) : ChecklistTemplateItemInternal
