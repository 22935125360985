import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    canEditTasksSelector,
    isEventAdminSelector,
    isMainAirportSelector,
} from 'aos-services/src/core/auth/state'
import { FeatureFlagType } from 'aos-services/src/dataaccess/firebase/FirebaseFeatureFlagService'
import { taskReportingItems } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FeatureFlag } from 'aos-ui/src/components/featureFlag/FeatureFlag'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    createEventShowModalAction,
    reportSimpleFeedInShowModalAction,
} from '../../core/eventModals/actions'
import { setFodCreateModalOpenAction } from '../../core/fod/actions'
import { closeReportingModalAction } from '../../core/reporting/actions'
import { isReportingModalOpenSelector } from '../../core/reporting/selectors'
import { setTaskCreateModalOpenAction } from '../../core/tasks/actions'
import { openFireFighterFormAction } from '../../core/tasks/firefighters/actions'
import { openShiftNotesModalAction } from '../../core/tasks/shiftNotes/actions'
import { ReportingItem } from './ReportingItem'

const EMERGENCY_NUMBER = '112'
const SECURITY_CONTROL_ROOM_NUMBER = '+358 20 708 3710'

export const ReportingModal = () => {
    const isOpen = useSelector(isReportingModalOpenSelector)
    const dispatch = useDispatch()
    const canEditTasks = useSelector(canEditTasksSelector)
    const isEventAdmin = useSelector(isEventAdminSelector)
    const isMainAirport = useSelector(isMainAirportSelector)

    const onClose = () => {
        dispatch(closeReportingModalAction())
    }

    const reportingItemConfig = useMemo(
        () =>
            [
                {
                    id: 'events',
                    title: translate('reporting.event.title'),
                    description: translate('reporting.event.description'),
                    icon: SvgIcon.Events,
                    onClick: () => {
                        if (isEventAdmin) {
                            dispatch(createEventShowModalAction())
                        } else {
                            dispatch(reportSimpleFeedInShowModalAction())
                        }
                        onClose()
                    },
                    visible: true,
                },
                {
                    id: 'fod',
                    title: translate('reporting.fod.title'),
                    description: translate('reporting.fod.description'),
                    icon: SvgIcon.TaskInspectionFod,
                    onClick: () => {
                        dispatch(setFodCreateModalOpenAction(true))
                        onClose()
                    },
                    visible: true,
                },
                {
                    id: 'task',
                    title: translate('reporting.task.title'),
                    description: translate('reporting.task.description'),
                    icon: SvgIcon.Assignment,
                    onClick: () => {
                        dispatch(setTaskCreateModalOpenAction(true))
                        onClose()
                    },
                    visible: canEditTasks,
                },
                {
                    id: 'shift-note',
                    title: translate('reporting.shift-note.title'),
                    description: translate('reporting.shift-note.description'),
                    icon: SvgIcon.ShiftNote,
                    onClick: () => {
                        dispatch(openShiftNotesModalAction())
                        onClose()
                    },
                    visible: canEditTasks,
                },
                {
                    id: 'firefighters',
                    title: translate('reporting.firefighters.title'),
                    description: translate('reporting.firefighters.description'),
                    icon: SvgIcon.Firefighters,
                    onClick: () => {
                        dispatch(openFireFighterFormAction(undefined))
                        onClose()
                    },
                    visible: canEditTasks,
                },
            ].filter(({ id }) => taskReportingItems.includes(id)),
        [],
    )

    return (
        <Modal
            title={translate('reporting.modal.title')}
            modalKind={ModalKind.Medium}
            isOpen={isOpen}
            closeAction={onClose}
            height={700}
        >
            <LightScrollbar autoHeightMin='100%' autoHeightMax='100%'>
                <Box padding={24} flex={1}>
                    {reportingItemConfig.map(
                        ({ visible, id, ...rest }) =>
                            visible && <ReportingItem key={id} {...rest} />,
                    )}
                    <FeatureFlag flag={FeatureFlagType.ReportingContactsButton}>
                        <Box row>
                            <DividingLine />
                            <Text marginHorizontal={48} size={14} color={Color.TimelineBackground}>
                                {translate('reporting.or')}
                            </Text>
                            <DividingLine />
                        </Box>
                        <ReportingItem
                            title={translate('reporting.contacts.title')}
                            description={translate('reporting.contacts.description')}
                            icon={SvgIcon.Mail}
                            onClick={onClose}
                        />
                    </FeatureFlag>
                </Box>
            </LightScrollbar>
            <Box
                rowGap={12}
                margin={24}
                column
                bg={Color.Yellow}
                paddingHorizontal={18}
                paddingVertical={20}
            >
                <Text size={14} color={Color.Black}>
                    {translate('reporting.emergency-note')}
                </Text>
                <Box columnGap={16} row>
                    <Text size={14} color={Color.Black}>
                        {translate('reporting.emergency-phone')}
                    </Text>
                    <Text size={20} color={Color.Black}>
                        {EMERGENCY_NUMBER}
                    </Text>
                </Box>
                {isMainAirport && (
                    <Box columnGap={16} row>
                        <Text size={14} color={Color.Black}>
                            {translate('reporting.security-phone')}
                        </Text>
                        <Text size={20} color={Color.Black}>
                            {SECURITY_CONTROL_ROOM_NUMBER}
                        </Text>
                    </Box>
                )}
            </Box>
        </Modal>
    )
}

const DividingLine = styled(Box)`
    border-bottom: 1px solid ${Color.TextSecondary};
    width: 100%;
`
