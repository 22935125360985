import { enumRecord } from 'aos-helpers/src/helpers/Enum'
import { isDefined } from 'aos-helpers/src/helpers/Function'
import { recordEntries } from 'aos-helpers/src/helpers/Record'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import {
    DashboardItemStatuses,
    getStatusForItemTypeAndHealth,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import {
    getHealthForItemType,
    WidgetHealths,
} from 'aos-services/src/services/widgetHealths/type/DashboardItemHealths'
import { Box } from 'aos-ui/src/components/base/Box'
import {
    Breakpoint,
    breakpoints,
    dashboardGridColumns,
    ResponsiveGrid,
} from 'aos-ui/src/components/grid/ResponsiveGrid'
import React, { PureComponent } from 'react'
import { Layout, Layouts } from 'react-grid-layout'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import {
    DashboardItem,
    getDashboardItemKey,
    RegularDashboardPresetWithTab,
} from '../../services/statusDashboard/types/DashboardPreset'
import { StatusDashboardConfig } from './StatusDashboardConfig'

interface StatusDashboardGridProps extends SizeMeProps {
    preset: RegularDashboardPresetWithTab
    statuses: DashboardItemStatuses
    healths: WidgetHealths
    isAosAdmin: boolean
    config: StatusDashboardConfig
    onUpdateCurrentLayout(v: Layouts): void
    onDismissAlert(v: DashboardItemType): void
    onChangeSize(v: [number, DashboardItemSizeVariant]): void
    onBreakpointChange(v: Breakpoint): void
    removeItem?(v: number): void
}

class StatusDashboardGridClass extends PureComponent<StatusDashboardGridProps> {
    private lastItemRef = React.createRef<HTMLDivElement>()

    public render() {
        const { preset, onBreakpointChange } = this.props
        const items: DashboardItem[] = preset.items
        const lastIndex = items.length - 1
        const children = items
            .filter(item => !!this.props.config[item.state.type].renderer)
            .map((child, index) => this.getItemContent(child, index === lastIndex))

        return (
            <ResponsiveGrid
                layouts={this.getLayouts()}
                onLayoutChange={this.onLayoutChange}
                onBreakpointChange={onBreakpointChange}
                width={this.getWidth()}
            >
                {children}
            </ResponsiveGrid>
        )
    }

    public componentDidMount(): void {
        this.props.onBreakpointChange(this.getBreakpoint())
    }

    public componentDidUpdate(prevProps: Readonly<StatusDashboardGridProps>): void {
        const breakpoint = this.getBreakpoint()
        const { preset } = this.props
        if (
            prevProps.preset.tab === preset.tab &&
            this.getBreakpoint(prevProps) === breakpoint &&
            preset.items.length > prevProps.preset.items.length
        ) {
            // Sometimes ref points to something else, it looks like not fully rendered widget…
            // Maybe Grid make some updates inside. Let's wait awhile.
            setTimeout(() => {
                if (this.lastItemRef.current) {
                    this.lastItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }, 100)
        }
    }

    private getBreakpoint(props = this.props): Breakpoint {
        return recordEntries(breakpoints).find(
            ([, breakpoint]) => this.getWidth(props) >= breakpoint,
        )![0]
    }

    private getWidth(props = this.props) {
        return props.size.width || 1200
    }

    private onLayoutChange = (allLayouts: Layouts) => {
        this.props.onUpdateCurrentLayout(allLayouts)
    }

    private getLayouts = () => {
        const items: DashboardItem[] = this.props.preset.items
        return enumRecord(Breakpoint, (breakpoint: Breakpoint) =>
            items.map(item => this.getItemLayout(item, breakpoint)).filter(isDefined),
        )
    }

    // getItemLayout && getItemContent are working in correlation
    private getItemLayout = (item: DashboardItem, breakpoint: Breakpoint): Layout | undefined => {
        const config = this.props.config[item.state.type]
        const sizesItemConfig = config ? config.sizes[item.state.size] : undefined

        const layout = {
            i: getDashboardItemKey(item),
            ...item.layouts[breakpoint],
        }
        if (sizesItemConfig) {
            return {
                ...layout,
                minW: Math.min(dashboardGridColumns[breakpoint], sizesItemConfig.minSize.w),
                minH: sizesItemConfig.minSize.h,
                maxW: sizesItemConfig.maxSize.w,
                maxH: sizesItemConfig.maxSize.h,
            }
        }
        return layout
    }

    private getItemContent = (item: DashboardItem, isLast: boolean) => {
        const itemConfig = this.props.config[item.state.type]
        const itemHealth = getHealthForItemType(this.props.healths, item.state.type)
        const itemStatus = getStatusForItemTypeAndHealth(
            this.props.statuses,
            itemHealth,
            item.state.type,
        )
        const key = getDashboardItemKey(item)
        const ref = isLast ? this.lastItemRef : undefined

        return (
            <Box key={key} ref={ref}>
                {itemConfig.renderer && (
                    <itemConfig.renderer
                        id={item.id}
                        onDismissAlert={this.props.onDismissAlert}
                        onChangeSize={this.props.onChangeSize}
                        itemStatus={itemStatus}
                        itemState={item.state}
                        sizes={itemConfig.sizes}
                        helpConfig={itemConfig.helpConfig}
                        itemHealth={itemHealth}
                        isAosAdmin={this.props.isAosAdmin}
                        removeItem={this.props.removeItem}
                    />
                )}
            </Box>
        )
    }
}

export const StatusDashboardGrid = sizeMe()<StatusDashboardGridProps>(StatusDashboardGridClass)
