import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'

import { stringFilter } from '../helpers/filters'
import { createStringSorter, createTimeSorter } from '../helpers/sorters'
import { TableFilter } from '../state'
import {
    bagFilter,
    changedValueFilter,
    equalArrayValueFilter,
    prmFilter,
    routeFilter,
    timeFilter,
} from './flightColumnFilters'
import { FlightInfoField } from './FlightInfoField'
import {
    bagSorter,
    beltAreaSorter,
    checkInSorter,
    flightNumberSorter,
    gateSorter,
    natureOfFlightSorter,
    parkSorter,
    routeSorter,
    statusSorter,
} from './flightSorters'

interface FlightsColumnFunctions {
    filter: (filter: TableFilter<any>, flight: Flight) => boolean
    sorter?: (a: Flight, b: Flight, desc: boolean, now: DateTime) => number
}

export const flightsColumnFunctions: Partial<Record<FlightInfoField, FlightsColumnFunctions>> = {
    [FlightInfoField.sdt]: {
        sorter: createTimeSorter(FlightInfoField.sdt),
        filter: timeFilter,
    },
    [FlightInfoField.hapt]: {
        sorter: createStringSorter(FlightInfoField.hapt),
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.naflt]: {
        sorter: natureOfFlightSorter,
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.fltnr]: {
        sorter: flightNumberSorter,
        filter: stringFilter,
    },
    [FlightInfoField.routes]: {
        sorter: routeSorter,
        filter: routeFilter,
    },
    [FlightInfoField.callsign]: {
        sorter: createStringSorter(FlightInfoField.callsign),
        filter: stringFilter,
    },
    [FlightInfoField.acreg]: {
        sorter: createStringSorter(FlightInfoField.acreg),
        filter: stringFilter,
    },
    [FlightInfoField.actype]: {
        sorter: createStringSorter(FlightInfoField.actype),
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.handl]: {
        sorter: createStringSorter(FlightInfoField.handl),
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.park]: {
        sorter: parkSorter,
        filter: changedValueFilter,
    },
    [FlightInfoField.gate]: {
        sorter: gateSorter,
        filter: changedValueFilter,
    },
    [FlightInfoField.est]: {
        sorter: createTimeSorter(FlightInfoField.est),
        filter: timeFilter,
    },
    [FlightInfoField.pest]: {
        sorter: createTimeSorter(FlightInfoField.pest),
        filter: timeFilter,
    },
    [FlightInfoField.act]: {
        sorter: createTimeSorter(FlightInfoField.act),
        filter: timeFilter,
    },
    [FlightInfoField.ablk]: {
        sorter: createTimeSorter(FlightInfoField.ablk),
        filter: timeFilter,
    },
    [FlightInfoField.paxTotal]: {
        sorter: createStringSorter(FlightInfoField.rwy),
        filter: stringFilter,
    },
    [FlightInfoField.bltarea]: {
        sorter: beltAreaSorter,
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.bag]: {
        sorter: bagSorter,
        filter: bagFilter,
    },
    [FlightInfoField.chkarea]: {
        sorter: checkInSorter,
        filter: stringFilter,
    },
    [FlightInfoField.prm]: {
        sorter: statusSorter,
        filter: prmFilter,
    },
    [FlightInfoField.eibt]: {
        sorter: createTimeSorter(FlightInfoField.eibt),
        filter: timeFilter,
    },
    [FlightInfoField.door]: {
        sorter: createStringSorter(FlightInfoField.door),
        filter: equalArrayValueFilter,
    },
    [FlightInfoField.rwy]: {
        sorter: createStringSorter(FlightInfoField.rwy),
        filter: stringFilter,
    },
    [FlightInfoField.tobt]: {
        sorter: createTimeSorter(FlightInfoField.tobt),
        filter: timeFilter,
    },
    [FlightInfoField.tsat]: {
        sorter: createTimeSorter(FlightInfoField.tsat),
        filter: timeFilter,
    },
}

export const getColumnFunctions = (
    columnName: FlightInfoField | string,
): FlightsColumnFunctions | null => {
    if (!(columnName in flightsColumnFunctions)) {
        return null
    }
    const name = columnName as FlightInfoField
    return flightsColumnFunctions[name] || null
}
