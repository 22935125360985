import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { PaxLocalOrTransfer } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxLocalOrTransfer'
import {
    paxForecastArrivalDepartureColors,
    paxForecastArrivalDepartureTransparentColors,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import { LabeledDispersionBar } from 'aos-ui/src/components/bar/LabeledDispersionBar'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { PaxForecastArrivalDepartureTrend } from './PaxForecastArrivalDepartureTrend'

interface PaxFlightTypeStatsSectionProps extends ClassNameProps {
    type: PaxForecastArrivalOrDeparture
    stats: PaxForecastStats
}

export const PaxFlightTypeStatsSection: FC<PaxFlightTypeStatsSectionProps> = props => (
    <Box className={cxp(props, 'container container--horizontal container--align-start')}>
        <Box marginRight={20}>
            <PaxForecastArrivalDepartureTrend stats={props.stats} type={props.type} />
        </Box>
        <LabeledDispersionBar
            marginTop={2}
            barConfig={{
                leftColor: paxForecastArrivalDepartureTransparentColors[props.type],
                rightColor: Color.BackgroundDark,
                borderColor: paxForecastArrivalDepartureColors[props.type],
                showValues: true,
            }}
            barData={[
                {
                    value: props.stats[props.type][PaxLocalOrTransfer.Local],
                    label: translate('dashboard.pax-forecast.local'),
                },
                {
                    value: props.stats[props.type][PaxLocalOrTransfer.Transfer],
                    label: translate('dashboard.pax-forecast.transfer'),
                },
            ]}
        />
    </Box>
)
