import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { MapMode, positionForMapVariant } from 'aos-services/src/services/mapui/types/BaseMapState'
import { noop } from 'lodash'
import React, { forwardRef, useMemo } from 'react'

import {
    deselectAllFeaturesAction,
    mapPositionChangedAction,
    pickLocationAction,
    selectFeatureAction,
} from '../../core/actions'
import { eventsForMapState, fodsForMapState, tasksForMapState } from '../../core/state'
import { useLoadBimLayers } from './hooks/useLoadBimLayers'
import { useLoadUserBimLayersVisibility } from './hooks/useLoadUserBimLayersVisibility'
import { MapProps } from './MapProps'
import { BimMapRef } from './openlayers/BimMap'
import { PositionTracker } from './openlayers/controls/PositionTracker'
import { CommonMapElements } from './partialMaps/CommonMapElements'
import { TerminalMap } from './partialMaps/TerminalMap'
import { WorldMap } from './partialMaps/WorldMap'

export const MapStack = forwardRef<BimMapRef, MapProps>(
    (
        {
            mapState,
            mapAction,
            layersData,
            onBoundsChanged = noop,
            onOpenFeature,
            bimLayersState,
            loadBimLayersDataAction = noop,
            userLayerVisibility,
            loadUserLayerVisibilityAction = noop,
            selectLayerAction = noop,
            isEventAdmin,
            siteLocation,
        },
        ref,
    ) => {
        const layers = mapState.atcLayerControl
            ? { ...userLayerVisibility, list: mapState.userLayerVisibility.list }
            : userLayerVisibility
        const position = positionForMapVariant(mapState)
        const events = eventsForMapState(mapState)
        const tasks = tasksForMapState(mapState)
        const fods = fodsForMapState(mapState)
        const eventsVisible = layers.list?.includes('events')
        const tasksVisible = layers.list?.includes('tasks')
        const onBoundsChangedCallback = (v: BBox) =>
            mapState.mode === MapMode.Standard && onBoundsChanged(v)
        const onSelectLocation = (l: AosLocation) => mapAction && mapAction(pickLocationAction(l))

        const variant: MapVariant = useMemo(() => {
            switch (mapState.siteLocationCustomization) {
                case MapSiteLocationCustomization.MainAirport:
                    return mapState.variant

                case MapSiteLocationCustomization.Other:
                    return MapVariant.World
            }
        }, [mapState.siteLocationCustomization, mapState.variant])

        useLoadBimLayers(variant, bimLayersState, loadBimLayersDataAction)
        useLoadUserBimLayersVisibility(userLayerVisibility, loadUserLayerVisibilityAction)

        const commonMapElements = (
            <>
                <PositionTracker
                    position={position}
                    onPositionChanged={p =>
                        mapAction && mapAction(mapPositionChangedAction([mapState.variant, p]))
                    }
                    onBoundsChanged={onBoundsChanged}
                />
                <CommonMapElements
                    siteLocation={siteLocation}
                    isEventAdmin={!!isEventAdmin}
                    userLayerVisibility={layers}
                    variant={variant}
                    events={events}
                    tasks={tasks}
                    fods={fods}
                    eventsVisible={eventsVisible}
                    tasksVisible={tasksVisible}
                    isMobile={mapState.isMobile}
                    mode={mapState.mode}
                    atcLayerControl={mapState.atcLayerControl}
                    selectedElement={mapState.selectedElement || undefined}
                    onFeatureSelect={v => mapAction && mapAction(selectFeatureAction(v))}
                    onFeatureReset={() => mapAction && mapAction(deselectAllFeaturesAction())}
                    onSelectLocation={onSelectLocation}
                    onOpenFeature={onOpenFeature}
                    onSelectedLayer={selectLayerAction}
                />
            </>
        )

        switch (variant) {
            case MapVariant.MainAirport:
                return (
                    <TerminalMap
                        siteLocation={siteLocation}
                        ref={ref}
                        mapState={mapState}
                        layersData={layersData}
                        onBoundsChanged={onBoundsChangedCallback}
                        bimLayersState={bimLayersState}
                        userLayerVisibility={layers}
                    >
                        {commonMapElements}
                    </TerminalMap>
                )
            case MapVariant.World:
                return (
                    <WorldMap
                        siteLocation={siteLocation}
                        mapState={mapState}
                        layersData={layersData}
                        onBoundsChanged={onBoundsChangedCallback}
                        bimLayersState={bimLayersState}
                        userLayerVisibility={layers}
                    >
                        {commonMapElements}
                    </WorldMap>
                )

            default:
                return null
        }
    },
)
