import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { supportEmail } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { BlueLink } from '../../components/buttons/BlueLink'
import { toggleWhatsNewAction } from '../../core/layout/actions'
import { resetRemoveAccount } from '../../core/removeAccount/actions'
import { RemoveAccountActionTypes, RemoveAccountStateAware } from '../../core/removeAccount/state'
import { Assets } from './assets'
import { DeleteAccount } from './DeleteAccount'
import { RemoveAccountDialog } from './RemoveAccountDialog'

export const RemoveAccount = () => {
    const result = useSelector((state: RemoveAccountStateAware) => state.removeAccount.result)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(toggleWhatsNewAction(false))
        return () => {
            dispatch(resetRemoveAccount())
        }
    }, [])

    return (
        <Box alignContent='stretch' fullSize column bg={Color.RemoveAccountBackground} padding={40}>
            <SvgImage svg={Assets.logo} />
            <BackgroundImage svg={Assets.bgLogo} />
            <Box row fullSize justify='center'>
                {result === null && <DeleteAccount />}
                {result === RemoveAccountActionTypes.SUCCESS && (
                    <RemoveAccountDialog
                        title={translate('remove-account.success.title')}
                        description={
                            <Text>
                                {translate('remove-account.success.description')}
                                <BlueLink
                                    to='#'
                                    style={{ paddingRight: 4 }}
                                    onClick={() => {
                                        window.location.href = `mailto:${supportEmail}`
                                    }}
                                >
                                    {supportEmail}
                                </BlueLink>
                            </Text>
                        }
                    >
                        <Box row justify='center'>
                            <img
                                alt='success-image'
                                src={Assets.successMessageIcon}
                                style={{ height: 180 }}
                            />
                        </Box>
                    </RemoveAccountDialog>
                )}
                {result === RemoveAccountActionTypes.ERROR && (
                    <RemoveAccountDialog
                        title={translate('remove-account.error.title')}
                        description={
                            <Text>
                                {translate('remove-account.error.description-1')}
                                <BlueLink
                                    to='#'
                                    style={{ paddingRight: 4 }}
                                    onClick={() => {
                                        window.location.href = `mailto:${supportEmail}`
                                    }}
                                >
                                    {supportEmail}
                                </BlueLink>
                                {translate('remove-account.error.description-2')}
                            </Text>
                        }
                    >
                        <Box row justify='center'>
                            <img
                                alt='error-image'
                                src={Assets.errorMessageIcon}
                                style={{ height: 180 }}
                            />
                        </Box>
                    </RemoveAccountDialog>
                )}
            </Box>
        </Box>
    )
}

const BackgroundImage = styled(SvgImage)`
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
`
