import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { PanelWithHeader, PanelWithHeaderProps, PanelWithHeaderVariant } from './PanelWithHeader'

export const MainPanelWithHeader: FCWithChildren<PropsWithChildren<PanelWithHeaderProps>> = ({
    children,
    ...rest
}) => (
    <PanelWithHeaderBox variant={PanelWithHeaderVariant.Std} bg={Color.BackgroundDark} {...rest}>
        {children}
    </PanelWithHeaderBox>
)

const PanelWithHeaderBox = styled(PanelWithHeader)`
    z-index: 1;
`
