import {
    DropTargetOverlay,
    DropTargetOverlayVariant,
} from 'aos-components/src/components/dnd/DropTargetOverlay'
import { BaseDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { conditionalWrapper, optionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosEventGroup } from 'aos-services/src/services/events/types/AosEventGroup'
import { AosFeedIn, isNotActivated } from 'aos-services/src/services/events/types/AosFeedIn'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { EventItem } from './EventItem'
import { droppableEventWrapper } from './EventListDnd'

export class EventItemGroupClass extends PureComponent<EventItemGroupProps, EventItemGroupState> {
    constructor(p: EventItemGroupProps) {
        super(p)
        this.state = {
            isCollapsed: true,
        }
    }

    public render() {
        const { group, isOver, droppable, connectDropTarget } = this.props
        const dropTargetWrapper = conditionalWrapper(droppable, connectDropTarget)
        const onItemClick = optionalFunctionCall1Arg(this.props.onItemClick, group)
        return dropTargetWrapper(
            <div>
                <Box className='event-manager-item-group' paddingHorizontal={8} paddingTop={8}>
                    <EventItem event={group} onClick={onItemClick} />
                    <CollapsibleContainer
                        toggle={this.toggleGroup}
                        label={this.getLabel()}
                        isCollapsed={this.state.isCollapsed}
                        iconSize={BlockSize.Small}
                        iconVariant={IconVariant.White}
                        lineHeightPx={30}
                        variant={CollapsibleButtonVariant.TopBottom}
                    >
                        {this.feedIns.map(this.renderFeedIn)}
                    </CollapsibleContainer>
                    {isOver && this.renderOverPlaceholder()}
                </Box>
            </div>,
        )
    }

    private getLabel = () => {
        const text = this.state.isCollapsed
            ? 'event-manager.show-grouped-items'
            : 'event-manager.hide-grouped-items'
        const count = ` (${this.feedIns.length})`
        return (
            <Text size={13} color={Color.White} paddingVertical={8}>
                {translate(text)}
                <Text as='span' color={Color.TextSecondary}>
                    {count}
                </Text>
            </Text>
        )
    }

    private renderFeedIn = (e: AosFeedIn, index: number) => {
        const onItemClick = this.props.onSubItemClick ? this.props.onSubItemClick(e) : undefined
        return (
            <Box paddingBottom={8} key={index}>
                <EventItem onClick={onItemClick} event={e} draggable={false} />
            </Box>
        )
    }

    private toggleGroup = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed,
        })
    }

    private renderOverPlaceholder = () => (
        <DropTargetOverlay
            label={translate('event-manager.add-to-group')}
            onDrop={this.props.onDrop}
            isOver
            variant={DropTargetOverlayVariant.Dark}
        />
    )

    private get feedIns() {
        return this.props.group.feedIns.filter(isNotActivated)
    }
}

interface EventItemGroupProps extends BaseDropTargetProps {
    group: AosEventGroup
    droppable?: boolean
    onSubItemClick?: f.Func1<AosFeedIn, f.Action | undefined>
    onDrop?(v: AosEvent): void
    onItemClick?(v: AosEvent): void
}

interface EventItemGroupState {
    isCollapsed: boolean
}

export const EventItemGroup = droppableEventWrapper<EventItemGroupProps>()(EventItemGroupClass)
