import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { getRunwaysHelpConfig } from '../../help/runways/runwaysHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Runways } from './Runways'
import { RunwaysTile } from './RunwaysTile'

export const getRunwayConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 3, h: 4 },
            maxSize: { w: 6, h: 6 },
        },
    },
    renderer: Runways,
    tileRenderer: RunwaysTile,
    helpConfig: getRunwaysHelpConfig(),
})
