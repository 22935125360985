import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isMainAirportSelector } from 'aos-services/src/core/auth/state'
import { AirportFilter } from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { HeaderButton } from 'aos-ui/src/components/header/HeaderButton'
import { HeaderButtonWrapper } from 'aos-ui/src/components/header/HeaderButtonWrapper'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { AirportFilterDropdown } from '../../../components/inputs/AirportFilter'
import { QuickSearch } from '../../../components/inputs/QuickSearch'
import {
    lockNowAction,
    setQuickSearchAction,
    showCustomizeModalAction,
    showShareModalAction,
    updateFiltersAction,
} from '../../../core/flightInformation/actions'
import { isNowLockedSelector } from '../../../core/flightInformation/flight/flightSelectors'
import { FiltersObject } from '../../../core/flightInformation/helpers/urlFilters'
import {
    filtersCountSelector,
    flightsUrlFiltersSelector,
    quickSearchQuerySelector,
} from '../../../core/flightInformation/selectors'

class FlightListHeaderFiltersClass extends PureComponent<FlightListHeaderFiltersProps> {
    render() {
        const { type } = this.props
        const isTowingList = type === FlightListType.Towing

        return (
            <>
                {!isTowingList && (
                    <HeaderButtonWrapper>
                        <AirportFilterDropdown
                            value={this.props.filters.airport}
                            onChange={this.changeAirportHandler(type)}
                        />
                    </HeaderButtonWrapper>
                )}
                <HeaderButtonWrapper>
                    <QuickSearch
                        value={this.props.quickSearch}
                        onChange={this.props.setQuickSearch}
                    />
                </HeaderButtonWrapper>
                <HeaderButton
                    onClick={this.handleLockNow}
                    text={translate('flight-information.now')}
                    icon={SvgIcon.CurrentTime}
                    disabled={this.props.nowLocked}
                />
                <HeaderButton
                    onClick={this.toggleFilteringHandler(type)}
                    text={translate('flight-information.toggle-filters')}
                    icon={SvgIcon.SearchFilters}
                    count={this.props.filtersCount}
                />
                {!isTowingList && (
                    <HeaderButton
                        onClick={this.handleShowCustomizeModal}
                        text={translate('flight-information.customize')}
                        icon={SvgIcon.Settings}
                    />
                )}
                <HeaderButton
                    onClick={this.handleShowShareModal}
                    text={translate('flight-information.share')}
                    icon={SvgIcon.Share}
                />
            </>
        )
    }

    private handleLockNow = () => this.props.lockNow(!this.props.nowLocked)

    private changeAirportHandler = (type: FlightListType) => (value: AirportFilter) =>
        this.props.updateFilters([type, { airport: value }])

    private toggleFilteringHandler = (type: FlightListType) => () => {
        const { filtersVisible } = this.props.filters
        this.props.updateFilters([type, { filtersVisible: !filtersVisible }])
    }

    private handleShowShareModal = () => {
        this.props.showShareModal(true)
    }

    private handleShowCustomizeModal = () => {
        this.props.showCustomizeModal(true)
    }
}

interface FlightListHeaderFiltersStateProps {
    filters: FiltersObject
    nowLocked: boolean
    filtersCount: number
    quickSearch: string
}

interface FlightListHeaderFiltersDispatchProps {
    updateFilters: typeof updateFiltersAction
    showShareModal: typeof showShareModalAction
    showCustomizeModal: typeof showCustomizeModalAction
    lockNow: typeof lockNowAction
    setQuickSearch: typeof setQuickSearchAction
}

interface FlightListHeaderFiltersProps
    extends FlightListHeaderFiltersStateProps,
        FlightListHeaderFiltersDispatchProps {
    type: FlightListType
}

export const FlightListHeaderFilters = connect<
    FlightListHeaderFiltersStateProps,
    FlightListHeaderFiltersDispatchProps
>(
    createStructuredSelector({
        filters: flightsUrlFiltersSelector,
        nowLocked: isNowLockedSelector,
        filtersCount: filtersCountSelector,
        quickSearch: quickSearchQuerySelector,
        isMainAirport: isMainAirportSelector,
    }),
    {
        updateFilters: updateFiltersAction,
        showShareModal: showShareModalAction,
        showCustomizeModal: showCustomizeModalAction,
        lockNow: lockNowAction,
        setQuickSearch: setQuickSearchAction,
    },
)(FlightListHeaderFiltersClass)
