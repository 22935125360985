import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
interface AdminRouteProps extends RouteProps {
    component?: any
    isAdmin: boolean
}

const AdminRoute: React.FC<AdminRouteProps> = ({ component: Component, isAdmin, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAdmin ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default AdminRoute
