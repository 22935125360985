import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import {
    flightsSyncAction,
    flightsSyncStopAction,
} from 'aos-services/src/core/flightInformation/actions'
import {
    restrictionsSyncAction,
    restrictionsSyncStopAction,
} from 'aos-services/src/core/restrictions/actions'
import {
    loadUserLayerVisibilityAction,
    setUserLayersVisibilityAction,
} from 'aos-services/src/core/userLayersVisibility/actions'
import { firebaseMapLayersVisibilityRepository } from 'aos-services/src/dataaccess/mapLayers/firebaseMapLayersVisibilityRepository'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { KnownLayerCategories } from 'aos-ui-map/src/components/map/bim/BimLayerCategory'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import {
    closeMapAction,
    loadMapPointsOfInterestAction,
    MapAction,
    openMapAction,
} from 'aos-ui-map/src/core/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { airportMapMapParentAction, syncAirportMapAction } from '../../core/airportMap/actions'
import { State } from '../../core/state'
import { AirportMapHeader } from './AirportMapHeader'
import { LayersVisibilitySidebar } from './LayersVisibilitySidebar'
import { SidebarStack } from './SidebarStack'

export const AirportMap = () => {
    const mapState = useSelector((state: State) => state.airportMap)
    const layersData = useSelector((state: State) => state.layersData)
    const bimLayers = useSelector((state: State) => state.bimLayers)
    const userLayerVisibility = useSelector((state: State) => state.userLayerVisibility)
    const userId = useSelector((state: State) => state.auth.current?.payload.aosId)
    const siteLocation = useSelector(currentUserSiteLocation)
    const dispatch = useDispatch()

    const mapAction = (v: MapAction) => {
        dispatch(airportMapMapParentAction(v))
    }
    const loadBimLayersData = () => {
        dispatch(loadBimLayersDataAction())
    }
    const loadUserLayerVisibility = () => {
        dispatch(loadUserLayerVisibilityAction())
    }

    const changeBimLayersVisibility = async (v: string[]) => {
        dispatch(
            setUserLayersVisibilityAction({
                ...userLayerVisibility,
                list: v,
            }),
        )
        if (mapState.variant === MapVariant.MainAirport && userId) {
            await firebaseMapLayersVisibilityRepository.getRef(userId).set(v)
        }
    }

    const updateFloorNumber = (floorNumber: number) => {
        changeFloorLayerVisibility(floorNumber)
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...userLayerVisibility,
            list: [
                ...userLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        dispatch(setUserLayersVisibilityAction(changedUserLayerVisibilityState))
    }
    const bimMapLayers = bimLayers.list.filter(
        i => i.category && i.category !== KnownLayerCategories.Atc,
    )

    return (
        <SyncWrapper
            onEnter={[
                syncAirportMapAction(true),
                airportMapMapParentAction(openMapAction()),
                flightsSyncAction(FlightListType.Arrivals),
                flightsSyncAction(FlightListType.Departures),
                restrictionsSyncAction('map'),
            ]}
            onLeave={[
                syncAirportMapAction(false),
                airportMapMapParentAction(closeMapAction()),
                flightsSyncStopAction(FlightListType.Arrivals),
                flightsSyncStopAction(FlightListType.Departures),
                restrictionsSyncStopAction('map'),
            ]}
        >
            <Box fullSize column>
                <AirportMapHeader />
                <Box row alignItems='stretch' flex={1}>
                    <Box flex={1}>
                        <MapStack
                            siteLocation={siteLocation}
                            layersData={layersData}
                            mapState={mapState}
                            mapAction={mapAction}
                            onBoundsChanged={v => mapAction(loadMapPointsOfInterestAction(v))}
                            loadBimLayersDataAction={loadBimLayersData}
                            bimLayersState={bimLayers}
                            userLayerVisibility={userLayerVisibility}
                            loadUserLayerVisibilityAction={loadUserLayerVisibility}
                        />
                    </Box>
                    {mapState.layersSidebarOpen ? (
                        <LayersVisibilitySidebar
                            userLayersVisibility={userLayerVisibility}
                            bimMapLayers={bimMapLayers}
                            setBimLayersVisibility={v => changeBimLayersVisibility(v)}
                            setFloorNumber={v => updateFloorNumber(v)}
                        />
                    ) : (
                        <SidebarStack />
                    )}
                </Box>
            </Box>
        </SyncWrapper>
    )
}
