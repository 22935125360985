import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { RunwaysLegend } from './RunwaysLegend'

interface RunwaysContentProps {
    lastChange: DateTime
}

export const RunwaysContent: FC<RunwaysContentProps> = ({ lastChange }) => {
    return (
        <Box className='top-left' column alignItems='flex-start'>
            <Tooltip
                body={translate('dashboard.runways.lastChange.tooltip')}
                placement='top'
                withArrow
            >
                <Box
                    bg={Color.BackgroundLight}
                    marginBottom={20}
                    paddingVertical={6}
                    paddingHorizontal={16}
                    justify='center'
                    rounded
                >
                    {formatDateTime(lastChange, 'DD.MM.YYYY HH:mm')}
                </Box>
            </Tooltip>
            <RunwaysLegend />
        </Box>
    )
}
