import {
    AosEventChecklistItem,
    isMessageTemplate,
    TaskChangeObject,
    TaskOrMessageTemplate,
} from 'aos-services/src/services/events/types/AosEventChecklist'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import React, { useMemo } from 'react'

import { ChecklistTabBar } from './ChecklistTabBar'
import { ChecklistItem } from './items/ChecklistItem'

interface CollapsableChecklistProps {
    name: string
    isCollapsed: boolean
    items: AosEventChecklistItem[]
    checklistId: number
    eventId: number
    currentTab: TaskOrMessageTemplate
    isEditable: boolean
    borderedTop?: boolean
    isEventAdmin?: boolean
    onToggle(): void
    toggleTask(v: TaskChangeObject): void
    changeTab(v: TaskOrMessageTemplate): void
    copyMessageTemplate(v: AosEventChecklistItem): void
}

export const CollapsableChecklist: React.FC<CollapsableChecklistProps> = ({
    onToggle,
    isCollapsed,
    borderedTop,
    isEditable,
    name,
    items,
    checklistId,
    eventId,
    currentTab,
    toggleTask,
    changeTab,
    copyMessageTemplate,
    isEventAdmin,
}) => {
    const messageTemplates = items.filter(isMessageTemplate)

    const notMessageTemplates = items.filter(t => !isMessageTemplate(t))

    const mappedItems = useMemo(
        () => (currentTab === TaskOrMessageTemplate.Task ? notMessageTemplates : messageTemplates),
        [items, currentTab],
    )

    const onToggleTask = (task: AosEventChecklistItem) => (e?: React.MouseEvent<unknown>) => {
        const { done } = task
        const taskChangeObject: TaskChangeObject = {
            eventId,
            checklistId,
            taskId: task.id,
            done: !done,
        }

        if (e) {
            e.preventDefault()
        }
        toggleTask(taskChangeObject)
    }
    const renderHeader = () => (
        <Box row fullWidth>
            <Box flex={1}>
                <Text upper>{name}</Text>
            </Box>
            {isEventAdmin && (
                <ChecklistTabBar
                    changeTab={changeTab}
                    value={currentTab}
                    hasMessageTemplates={items.some(isMessageTemplate)}
                />
            )}
        </Box>
    )

    const renderItem = (task: AosEventChecklistItem, index: number) => (
        <ChecklistItem
            item={task}
            key={index}
            isEditable={isEditable}
            isEven={index % 2 === 0}
            copyMessageTemplate={copyMessageTemplate}
            toggleTask={onToggleTask(task)}
        />
    )

    return (
        <CollapsibleContainer
            label={renderHeader()}
            toggle={onToggle}
            isCollapsed={isCollapsed}
            variant={CollapsibleButtonVariant.RightBottom}
            iconVariant={IconVariant.White}
            paddingVertical={16}
            borderedBottom={isCollapsed || !isEditable}
            borderedTop={borderedTop}
        >
            {mappedItems.map((task, index) => renderItem(task, index))}
        </CollapsibleContainer>
    )
}
