import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    syncStatusDashboardAction,
    syncStatusDashboardStopAction,
} from 'aos-services/src/core/statusDashboard/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { appConfig } from 'appConfig'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import {
    addDashboardItemAction,
    changeSizeVariantAction,
    dismissAlertAction,
    openStatusDashboardTabAction,
    removeDashboardItemAction,
    setCurrentBreakpointAction,
    toggleSidebarAction,
    updateLayoutAction,
} from '../../core/statusDashboard/actions'
import {
    statusDashboardSelector,
    StatusDashboardSelectorState,
} from '../../core/statusDashboard/selectors'
import {
    isPresetTab,
    MyDashboardTab,
    StatusDashboardOverviewTab,
} from '../../services/statusDashboard/types/DashboardPreset'
import { StatusDashboardHeader } from './header/StatusDashboardHeader'
import { NoWidgetsPlaceholder } from './partials/NoWidgetsPlaceholder'
import { StatusDashboardSidebarContent } from './partials/StatusDashboardSidebarContent'
import { DashboardSidebar } from './sidebar/DashboardSidebar'
import { getStatusDashboardConfig } from './StatusDashboardConfig'
import { StatusDashboardGrid } from './StatusDashboardGrid'
import { StatusDashboardOverview } from './StatusDashboardOverview'

interface StatusDashboardProps extends StatusDashboardSelectorState, StatusDashboardDispatchProps {}

class StatusDashboardClass extends PureComponent<StatusDashboardProps> {
    public render() {
        return (
            <SyncWrapper
                onEnter={syncStatusDashboardAction()}
                onLeave={syncStatusDashboardStopAction()}
            >
                <PanelWithHeader header={this.renderHeader()} headerPaddingRight={0} disableScroll>
                    {this.renderContent()}
                </PanelWithHeader>
            </SyncWrapper>
        )
    }

    private renderContent = () => {
        const { tab, preset, isSidebarOpen, addDashboardItem, availablePresetTypes } = this.props
        const isMyDashboard = tab === MyDashboardTab.MyDashboard
        return (
            <Box fullSize row alignItems='flex-start'>
                <DarkScrollbar>
                    <Box fullSize>{this.renderGrid()}</Box>
                </DarkScrollbar>
                {isMyDashboard && preset && (
                    <DashboardSidebar isOpen={isSidebarOpen}>
                        <StatusDashboardSidebarContent
                            states={preset.items.map(item => item.state)}
                            addDashboardItem={addDashboardItem}
                            availableItems={availablePresetTypes}
                        />
                    </DashboardSidebar>
                )}
            </Box>
        )
    }

    private renderGrid = () => {
        const {
            changeSizeVariant,
            dismissAlert,
            setCurrentBreakpoint,
            preset,
            statuses,
            healths,
            isAosAdmin,
            isSidebarOpen,
            removeDashboardItem,
            updateLayout,
            toggleSidebar,
            overviewItems,
            tab,
        } = this.props
        const isOverview = tab === StatusDashboardOverviewTab.Overview

        if (isOverview) {
            return (
                <StatusDashboardOverview
                    items={overviewItems}
                    onDismissAlert={dismissAlert}
                    statuses={statuses}
                    healths={healths}
                    isAosAdmin={isAosAdmin}
                />
            )
        }

        if (!preset || !preset.items.length) {
            return <NoWidgetsPlaceholder toggleSidebar={toggleSidebar} />
        }

        return (
            <StatusDashboardGrid
                onChangeSize={changeSizeVariant}
                onDismissAlert={dismissAlert}
                onBreakpointChange={setCurrentBreakpoint}
                preset={preset}
                statuses={statuses}
                healths={healths}
                isAosAdmin={isAosAdmin}
                removeItem={isSidebarOpen ? removeDashboardItem : undefined}
                onUpdateCurrentLayout={layouts =>
                    isPresetTab(tab) && updateLayout({ layouts, tab })
                }
                config={getStatusDashboardConfig()}
            />
        )
    }

    private renderHeader = () => {
        const { tab, openStatusDashboardTab, isSidebarOpen, toggleSidebar, headerTabStates } =
            this.props
        return (
            <StatusDashboardHeader
                tab={tab}
                onChangeTab={openStatusDashboardTab}
                isSidebarOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}
                headerTabStates={headerTabStates}
            />
        )
    }

    private appVersionText = () => {
        return `${translate('mobile.profile.app-version')}: ${appConfig.version} - ${
            appConfig.gitHash
        } (${appConfig.environmentName})`
    }
    componentDidMount(): void {
        // eslint-disable-next-line no-console
        console.log(this.appVersionText())
    }
}

interface StatusDashboardDispatchProps {
    openStatusDashboardTab: typeof openStatusDashboardTabAction
    dismissAlert: typeof dismissAlertAction
    changeSizeVariant: typeof changeSizeVariantAction
    updateLayout: typeof updateLayoutAction
    toggleSidebar: typeof toggleSidebarAction
    setCurrentBreakpoint: typeof setCurrentBreakpointAction
    addDashboardItem: typeof addDashboardItemAction
    removeDashboardItem: typeof removeDashboardItemAction
}

export const StatusDashboard = connect<StatusDashboardSelectorState, StatusDashboardDispatchProps>(
    statusDashboardSelector,
    {
        openStatusDashboardTab: openStatusDashboardTabAction,
        dismissAlert: dismissAlertAction,
        changeSizeVariant: changeSizeVariantAction,
        updateLayout: updateLayoutAction,
        toggleSidebar: toggleSidebarAction,
        setCurrentBreakpoint: setCurrentBreakpointAction,
        addDashboardItem: addDashboardItemAction,
        removeDashboardItem: removeDashboardItemAction,
    },
)(StatusDashboardClass)
