import { currentUserSiteLocation, isEventAdminSelector } from 'aos-services/src/core/auth/state'
import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import { updateEventLayerVisibilityAction } from 'aos-services/src/core/events/actions'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import {
    atcGridPositionExtent,
    atcMapFitConfig,
    mainAirportAtcCenterPosition,
} from 'aos-services/src/services/map/mapPositions'
import { AosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import { MapMode, SingleMapState } from 'aos-services/src/services/mapui/types/BaseMapState'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import { BimMapRef } from 'aos-ui-map/src/components/map/openlayers/BimMap'
import { atcZoomLevel, MapState } from 'aos-ui-map/src/core/state'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../core/state'

interface EventMapProps {
    event: AosEvent | undefined
    layersVisibility: string[] | undefined
}
export const EventMap: FC<EventMapProps> = ({ event, layersVisibility }) => {
    const mapRef = useRef<BimMapRef>(null)
    const dispatch = useDispatch()
    const siteLocation = useSelector(currentUserSiteLocation)
    const { layersData, bimLayers } = useSelector((state: State) => state)
    const isEventAdmin = useSelector(isEventAdminSelector)
    const partialLayersVisibility = {
        list: [...(layersVisibility || []), 'events'],
        isLoading: bimLayers.isLoading,
    } as UserLayerVisibilityState

    const selectedEvent = event?.hasLocation
        ? {
              id: event?.id,
              location: event?.location,
              severity: event?.severity,
              category: event?.category,
          }
        : null

    const centerLocation = useMemo(
        () =>
            event?.hasLocation
                ? ({
                      ...event.location,
                      zoom: atcZoomLevel,
                  } as MapPosition)
                : mainAirportAtcCenterPosition,
        [event?.location],
    )

    const mapState = useMemo(
        () =>
            ({
                events: [event as AosMapEvent],
                terminalMapState: {
                    position: centerLocation,
                    bimLayersState: bimLayers,
                } as SingleMapState,
                userLayerVisibility: partialLayersVisibility,
                mode: MapMode.ViewSingleLocation,
                pickedEvent: selectedEvent,
                siteLocationCustomization: MapSiteLocationCustomization.MainAirport,
                variant: MapVariant.MainAirport,
                atcLayerControl: true,
            } as MapState),
        [event, centerLocation, partialLayersVisibility, selectedEvent, bimLayers],
    )

    const handleUserLayerVisibilityChange = (layer: BimLayerName) => {
        dispatch(updateEventLayerVisibilityAction(layer))
    }

    useEffect(() => {
        if (mapRef.current?.map) {
            mapRef.current.map.once('rendercomplete', () => {
                mapRef.current?.map.getView().fit(atcGridPositionExtent, {
                    size: mapRef.current?.map.getSize(),
                    ...atcMapFitConfig,
                    padding: [200, 0, 0, 0],
                })
            })
        }
    }, [mapRef.current?.map])

    return (
        <MapStack
            siteLocation={siteLocation}
            ref={mapRef}
            layersData={layersData}
            mapState={mapState}
            isEventAdmin={isEventAdmin}
            bimLayersState={bimLayers}
            loadBimLayersDataAction={() => dispatch(loadBimLayersDataAction())}
            selectLayerAction={handleUserLayerVisibilityChange}
            userLayerVisibility={partialLayersVisibility}
        />
    )
}
