import { DateTime } from 'aos-helpers/src/helpers/Time'
import { isMainAirportSelector } from 'aos-services/src/core/auth/state'
import { weatherTileDataSelector } from 'aos-services/src/core/statusDashboardData/weather/selectors'
import { WeatherLatestInfo } from 'aos-services/src/dataaccess/airportStatus/types/WeatherSeriesDto'
import {
    WeatherCategory,
    WeatherMetric,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { setWeatherTabStateAction } from '../../../../core/statusDashboard/actions'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { WeatherIcon } from './compactView/WeatherIcon'
import { WeatherCarousel } from './tileView/WeatherCarousel'
import { WeatherItem } from './WeatherItem'

interface WeatherTileStateProps {
    weatherData: {
        weatherSeriesMetric: WeatherMetric
        weatherAlert: boolean
        lvpAlert: boolean
        noDataWarning: boolean
        weatherCategory?: WeatherCategory
        weatherInfo?: WeatherLatestInfo
    }
    carouselTrigger: DateTime
    isMainAirport: boolean
}

interface WeatherTileProps extends WeatherTileStateProps, StatusDashboardItemCommonProps {}

const WeatherTileComponent: FC<WeatherTileProps> = ({
    weatherData,
    carouselTrigger,
    isMainAirport,
    ...props
}) => (
    <WeatherItem
        syncId='overview'
        itemProps={pickDashboardItemProps(props)}
        noDataWarning={weatherData.noDataWarning}
        weatherAlert={weatherData.weatherAlert}
        lvpAlert={weatherData.lvpAlert}
        forecastAvailable={!!weatherData.weatherInfo?.additionalInfo && isMainAirport}
        link={link}
    >
        <TileContainer horizontal>
            <WeatherIcon
                weatherCategory={weatherData.weatherCategory}
                lvp={weatherData.weatherSeriesMetric[WeatherValueSeriesType.LvpProbability] || 0}
                thunderstorm={
                    weatherData.weatherSeriesMetric[
                        WeatherValueSeriesType.ThunderstormProbability
                    ] || 0
                }
            />
            <WeatherCarousel
                metric={weatherData.weatherSeriesMetric}
                changeTrigger={carouselTrigger}
            />
        </TileContainer>
    </WeatherItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Weather })

export const WeatherTile = connect<WeatherTileStateProps, {}, StatusDashboardItemCommonProps>(
    createStructuredSelector({
        weatherData: weatherTileDataSelector,
        carouselTrigger: carouselTriggerSelector,
        isMainAirport: isMainAirportSelector,
    }),
    {
        setWeatherTabStateAction,
    },
)(WeatherTileComponent)
