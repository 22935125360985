import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { getOperationalForecastGroupIcon } from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    OperationalForecastGroup,
    OperationalForecastMode,
    operationalForecastModeColors,
    OperationalItem,
    translateOperationalForecastGroup,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface OperationalForecastTileItemProps {
    mode: OperationalForecastMode
    group: OperationalForecastGroup
    size?: IconSize
}

export const OperationalForecastTileIconItem: FC<OperationalForecastTileItemProps> = ({
    mode,
    group,
    size = 'medium',
}) => {
    return (
        <OperationalForecastTileItemBox mode={mode} size={size}>
            <Icon
                svg={SvgIcon[getOperationalForecastGroupIcon(group)]}
                iconSize={size !== 'large' ? BlockSize.Small : BlockSize.XLarge}
            />
        </OperationalForecastTileItemBox>
    )
}

type IconSize = 'small' | 'medium' | 'large'

export const OperationalForecastTileContentItem: FC<
    OperationalForecastTileItemProps & OperationalItem
> = ({ mode, group, title, description, size = 'medium' }) => {
    return (
        <Box column rowGap={8}>
            <Box alignContent='flex-start' row columnGap={24}>
                <OperationalForecastTileItemBox mode={mode} size={size}>
                    <Box flex={1} centered>
                        <Icon
                            svg={SvgIcon[getOperationalForecastGroupIcon(group)]}
                            iconSize={BlockSize.Large}
                        />
                    </Box>
                </OperationalForecastTileItemBox>
                <Box column rowGap={4}>
                    <Text
                        size={11}
                        color={Color.WidgetSecondary}
                        textTransform='uppercase'
                        weight='light'
                        lineHeight={1.2}
                    >
                        {translateOperationalForecastGroup(group)}
                    </Text>
                    <LimitedText weight='light' size={18}>
                        {title}
                    </LimitedText>
                </Box>
            </Box>
            <Text textClomp={1} weight='light' size={14} color={Color.WidgetSecondary}>
                {description}
            </Text>
        </Box>
    )
}

const LimitedText = styled(Text)`
    white-space: pre-wrap;
    overflow: hidden;
    max-height: 60px;
    line-height: 20px;
    text-overflow: ellipsis;
`

const OperationalForecastTileItemBox = styled(Box)<{
    mode: OperationalForecastMode
    size?: IconSize
}>`
    border-color: ${p => operationalForecastModeColors[p.mode]};
    border-width: ${p => (p.size !== 'large' ? 1 : 2)}px;
    border-style: solid;
    border-radius: ${p => (p.size !== 'large' ? 3 : 5)}px;
    padding: 12px;
    width: ${p => (p.size === 'large' ? 80 : p.size === 'medium' ? 50 : 40)}px;
    height: ${p => (p.size === 'large' ? 80 : p.size === 'medium' ? 50 : 40)}px;
    display: flex;
    align-items: center;
    justify-content: center;
`
