import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Punctuality } from './Punctuality'
import { PunctualityTile } from './PunctualityTile'

export const getPunctualityConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 3, h: 3 },
            maxSize: { w: 4, h: 4 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 6 },
            maxSize: { w: 12, h: 12 },
        },
    },
    renderer: Punctuality,
    tileRenderer: PunctualityTile,
})
