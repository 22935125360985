import { isSectionActive, RunwayMode } from 'aos-services/src/services/runways/types/RunwayMode'
import React, { PureComponent } from 'react'

import { RunwayArrow } from './RunwayArrow'

export class RunwayTopSection extends PureComponent<RunwaySectionProps> {
    public render() {
        return (
            <g
                transform={`translate(${this.props.offset.x}, ${this.props.offset.y})`}
                width={24}
                height={60}
            >
                {this.props.modes.map((m, index) => (
                    <RunwayArrow
                        transform={this.props.transform}
                        key={index}
                        inversed={false}
                        mode={m}
                        top={20 - index * 20}
                    />
                ))}
                <text x='12' y='50' className={this.partClass} textAnchor='middle'>
                    {this.props.name}
                </text>
            </g>
        )
    }

    private get partClass() {
        return isSectionActive(this.props.modes) ? 'active' : 'inactive'
    }
}

interface RunwaySectionProps {
    offset: {
        x: number
        y: number
    }
    modes: RunwayMode[]
    name: string
    transform?: string
}
