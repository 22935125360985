import { Flight } from '../../../../services/flightInformation/types/Flight'
import { AirlineFilter } from '../../../../services/statusDashboard/types/filters/AirlineFilter'
import { FilterOptionAll } from '../../../../services/statusDashboard/types/filters/common'
import { HandlingAgentFilter } from '../../../../services/statusDashboard/types/filters/HandlingAgentFilter'

export const flightsPredicate = (
    flight: Flight,
    agent: HandlingAgentFilter,
    airline: AirlineFilter,
): boolean => {
    const agentMatch = agent !== FilterOptionAll.All ? flight.handl === agent : true
    const airlineMatch = airline !== FilterOptionAll.All ? flight.airline?.code === airline : true
    return agentMatch && airlineMatch
}
