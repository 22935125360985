import { createEnumFromKeys, createEnumFromRecord } from 'aos-helpers/src/helpers/Enum'
import { languageProvider } from 'aos-helpers/src/helpers/translations/LanguageProvider'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { JustifyContent } from 'aos-ui/src/components/base/Box'
import { EnumValues } from 'enum-values'
import { capitalize, entries, flatMap, keys, pickBy, reduce } from 'lodash'
import siteLocation from 'siteLocation.json'

import { MapSiteLocationCustomization } from '../../common/types/MapSiteLocationCustomization'
import { AosAirline } from '../../masterData/types/AosAirline'
import { DashboardItemType } from '../../statusDashboard/types/DashboardItemType'

const airportsJson = siteLocation as AirportConfig

type AirportConfig = {
    airports: {
        [key: string]: AirportDetails
    }
    processes: {
        [key: string]: ProcessConfig
    }
    notificationPreferenceTypes: {
        [key: string]: { categories: string }
    }
    handlingAgents: string[]
    operationalForecastGroups: Array<Omit<ProcessCategory, 'hideOnEmergency'>>
    format: string
    loginScreenJustifyContent: JustifyContent
    airlines: AosAirline[]
    runwayConfiguration: RunwayConfig
    helpConfiguration: HelpConfig
    supportEmail: string
    privacyPolicyUrl: { [key: string]: string }
    taskManagerTabs: string[]
    taskReportingItems: string[]
    mapVariants: Record<string, string>
    dashboardGridItems: DashboardItemType[]
    dashboardGridItemsNetwork: DashboardItemType[]
}

export const { runwayConfiguration, helpConfiguration } = airportsJson

export type HelpConfig = {
    [key: string]: {
        title: string
        note?: string
        path: string
    }[]
}

export type RunwayConfig = {
    runways: RunwayItem[]
    height: number
    width: number
    transform: string
    allowedStates: Record<string, string>
}

export type RunwayItem = {
    name: string
    values: [string, string]
    path: string
    labelPlacement: {
        top: LabelPlacement
        bottom: LabelPlacement
    }
}

export type LabelPlacement = {
    x: number
    y: number
    transform?: string
}

export type ProcessCategories = {
    categories: ProcessCategory[]
}
export type ProcessConfig = ProcessCategories & {
    isAtc: boolean
    isNetwork: boolean
    locationRequired: boolean
}

export type ProcessCategory = {
    icon: string
    name: string
    hideOnEmergency?: boolean
}

export const {
    processes: processCategories,
    handlingAgents,
    operationalForecastGroups,
    format,
    loginScreenJustifyContent,
    airlines = [],
    supportEmail,
    privacyPolicyUrl: policyUrl = {},
    taskManagerTabs,
    mapVariants = {},
    taskReportingItems,
    dashboardGridItems,
    dashboardGridItemsNetwork,
} = airportsJson

export const atcProcessCategories = pickBy(processCategories, q => q.isAtc)

export const getAirportByCode = (code: string) => typedAirports.airports[code]
export const getFormattedAirportCode = (airport: AirportDetails) =>
    airport[format as keyof AirportDetails] as string

const iconCategoryMap = flatMap(processCategories, q => q.categories).reduce((acc, q) => {
    acc[q.name] = q.icon
    return acc
}, {} as Record<string, string>)

export const processCategoryIcon = (category: string) => iconCategoryMap[category]

export const typedAirports = airportsJson
export const AosAirport = reduce(
    typedAirports.airports,
    (acc, _, key) => {
        acc[key] = key
        return acc
    },
    {} as Record<string, string>,
)
export type AosAirport = keyof typeof AosAirport

export const MainAirport = entries(typedAirports.airports).find(
    q => q[1].mainAirport,
)?.[0] as AosAirport
export interface AirportDetails {
    iata: string
    icao: string
    name: string
    city: string
    country: string
    latitude: number
    longitude: number
    mainAirport?: boolean // Optional because not all airports might have this field
    atcConfig?: {
        latitude: number
        longitude: number
        zoom: number
        extent: {
            bbox: number[]
            source: string
            destination: string
        }
    }
}

export const allAirports = EnumValues.getValues<AosAirport>(AosAirport)

export const locationName = translateEnum<AosAirport>(AosAirport, 'airports')

export const airportIcon = (e: AosAirport) => capitalize((e as string).toLowerCase())

export const toSiteLocationCustomization = (aosAirport: AosAirport): MapSiteLocationCustomization =>
    aosAirport === MainAirport
        ? MapSiteLocationCustomization.MainAirport
        : MapSiteLocationCustomization.Other

export const NotificationsPreferencesEnum = createEnumFromKeys(
    airportsJson.notificationPreferenceTypes,
)
export type NotificationsPreferencesType = keyof typeof NotificationsPreferencesEnum

export const allNotificationsPreferencesTypes = keys(airportsJson.notificationPreferenceTypes)

export type AosHandlingAgentType = (typeof handlingAgents)[number]

export const AosHandlingAgent = Object.freeze(
    handlingAgents?.reduce((acc, value) => {
        acc[value] = value
        return acc
    }, {} as Record<AosHandlingAgentType, AosHandlingAgentType>),
)
export const mapHandlingAgentToCompany = (agent: AosHandlingAgentType) =>
    translateEnum<AosHandlingAgentType>(
        AosHandlingAgent,
        'flight-information.handling-agent',
        AosHandlingAgent.Unknown,
    )(agent)

export const OperationalForecastGroup = reduce(
    operationalForecastGroups,
    (acc, value) => {
        acc[value.name] = value.name
        return acc
    },
    {} as Record<string, string>,
)

export type OperationalForecastGroup = keyof typeof OperationalForecastGroup

export const getOperationalForecastGroupIcon = (name: OperationalForecastGroup) =>
    operationalForecastGroups.find(el => el.name === name)!.icon

export const MapVariant = createEnumFromRecord(mapVariants)
export type MapVariant = keyof typeof MapVariant

export const getPrivacyPolicyUrl = () => policyUrl[languageProvider.getCurrent()]
