import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../base/Box'
import { Text, TextProps } from '../base/Text'
import { IconButton } from '../buttons/IconButton'
import { IconVariant } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'

interface ChipProps extends TextProps {
    crossed?: boolean
    onRemove?(): void
}

export const Chip: FCWithChildren<ChipProps> = ({
    children,
    onRemove,
    bg = Color.ChartBase,
    color = Color.White,
    size = 12,
    crossed,
    ...textProps
}) => {
    const onClick = (v: React.MouseEvent<any>) => {
        v.stopPropagation()
        if (onRemove) {
            onRemove()
        }
    }
    const withRemove = !!onRemove
    return (
        <ChipWrapper
            row
            bg={bg}
            color={color}
            size={size}
            crossed={crossed}
            paddingLeft={12}
            paddingRight={withRemove ? 4 : 12}
            {...textProps}
        >
            {children}
            {withRemove && (
                <Box shrink={0} paddingLeft={2}>
                    <IconButton
                        padding={4}
                        onClick={onClick}
                        iconSize={BlockSize.XTiny}
                        iconVariant={IconVariant.White}
                        svg={SvgIcon.Close}
                        block
                    />
                </Box>
            )}
        </ChipWrapper>
    )
}

const ChipWrapper = styled(Text)<{ crossed?: boolean }>`
    height: 24px;
    border-radius: 12px;
    ${p =>
        p.crossed &&
        css`
            text-decoration: line-through;
            color: ${Color.TextSecondary};
        `}
`
