import { cx } from 'aos-components/src/components/base/ClassNames'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    allNotificationsPreferencesTypes,
    NotificationsPreferencesEnum,
    NotificationsPreferencesType,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    AdditionalPreferenceItem,
    allNotificationsPreferencesDays,
    AosUserNotificationsPreferences,
    AosUserNotificationsTimePreference,
    defaultDisabledTimeNotificationPreferences,
    defaultEnabledTimeNotificationPreferences,
    NotificationsPreferencesDay,
} from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BlockCheckboxGroup } from 'aos-ui/src/components/form/checkbox/BlockCheckboxGroup'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { TimeInput } from 'aos-ui/src/components/form/datetime/TimeInput'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Info } from 'aos-ui/src/components/ui/Info'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useCallback, useMemo } from 'react'

interface NotificationSettingsFormProps {
    settings: AosUserNotificationsPreferences
    onUpdate: (settings: Partial<AosUserNotificationsPreferences>) => void
}

export const NotificationSettingsForm: React.FC<NotificationSettingsFormProps> = ({
    settings,
    onUpdate,
}) => {
    const translateType = useMemo(
        () =>
            translateEnum<NotificationsPreferencesType>(
                NotificationsPreferencesEnum,
                'notification-settings.type',
            ),
        [],
    )

    const translateDay = useMemo(
        () =>
            translateEnum<NotificationsPreferencesDay>(
                NotificationsPreferencesDay,
                'notification-settings.day',
            ),
        [],
    )

    const optionOn = useMemo<AdditionalPreferenceItem>(() => ({ push: true, email: true }), [])
    const optionOff = useMemo<AdditionalPreferenceItem>(() => ({ push: false, email: false }), [])

    const updateOption = useCallback(
        (type: NotificationsPreferencesType, data: Partial<AdditionalPreferenceItem>) => {
            const newAdditional = {
                ...settings.additional,
                [type]: { ...settings.additional[type], ...data },
            }
            onUpdate({ additional: newAdditional })
        },
        [settings.additional, onUpdate],
    )

    const getOption = useCallback(
        (type: NotificationsPreferencesType): AdditionalPreferenceItem => {
            return (
                settings.additional[type] ?? {
                    push: false,
                    email: false,
                }
            )
        },
        [settings.additional],
    )

    const changeTime = useCallback(
        (type: keyof AosUserNotificationsTimePreference, date?: DateTime) => {
            if (date) {
                const time = date.clone().format('HH:mm')
                onUpdate({
                    timePreference: { ...settings.timePreference, [type]: time },
                })
            }
        },
        [settings.timePreference, onUpdate],
    )

    const updateTimePreference = useCallback(
        (type: keyof AosUserNotificationsTimePreference, value: any) => {
            onUpdate({
                timePreference: { ...settings.timePreference, [type]: value },
            })
        },
        [settings.timePreference, onUpdate],
    )

    const handleToggleTimePicker = useCallback(
        (value: boolean) => {
            onUpdate({
                timePreference: value
                    ? defaultEnabledTimeNotificationPreferences
                    : defaultDisabledTimeNotificationPreferences,
            })
        },
        [onUpdate],
    )

    const handleChangeDay = useCallback(
        (days: NotificationsPreferencesDay[]) => {
            updateTimePreference('days', days)
        },
        [updateTimePreference],
    )

    const renderChecks = useCallback(
        (type: NotificationsPreferencesType) => {
            const value = getOption(type)
            const togglePush = () => updateOption(type, { push: !value.push })
            const toggleEmail = () => updateOption(type, { email: !value.email })
            const toggleAll = () => updateOption(type, value.push ? optionOff : optionOn)

            return (
                <Box className='flex-grid' paddingVertical={8} key={type}>
                    <Text
                        className='col-8 pointer'
                        color={Color.BackgroundDark}
                        size={12}
                        onClick={toggleAll}
                    >
                        {translateType(type)}
                    </Text>
                    <Box className='col-2' centered>
                        <Checkbox position='center' checked={value.push} onChange={togglePush} />
                    </Box>
                    <Box className='col-2' centered>
                        <Checkbox position='center' checked={value.email} onChange={toggleEmail} />
                    </Box>
                </Box>
            )
        },
        [getOption, updateOption, optionOff, optionOn, translateType],
    )

    const renderLeftColumn = useMemo(
        () => (
            <Box fullHeight flex={1}>
                <LightScrollbar autoHeightMin='100%' autoHeightMax='100%'>
                    <Box padding={30}>
                        <h3 className='text__xxl-black'>
                            {translate('notification-settings.set-additional')}
                        </h3>
                        <p className='text__m-ow-pale text--lh-std padding-vertical--std'>
                            {translate('notification-settings.set-additional.info')}
                        </p>
                        <Box
                            className={cx(
                                'flex-grid border-bottom--grey',
                                'padding-bottom--smallest margin-bottom--small',
                                'text__s-black text--bold text--center',
                            )}
                        >
                            <Box className='col-8' />
                            <Box className='col-2'>
                                {translate('notification-settings.push-or-email.push')}
                            </Box>
                            <Box className='col-2'>
                                {translate('notification-settings.push-or-email.email')}
                            </Box>
                        </Box>

                        {allNotificationsPreferencesTypes.map(renderChecks)}
                    </Box>
                </LightScrollbar>
            </Box>
        ),
        [renderChecks],
    )

    const renderTimePicker = useMemo(() => {
        const { timePreference } = settings
        return (
            <Box>
                <Box className='flex-grid' marginTop={16}>
                    <Box className='col-4' marginRight={20}>
                        <Text
                            paddingBottom={8}
                            color={Color.TimelineBackground}
                            size={12}
                            weight='bold'
                        >
                            {translate('notification-settings.from')}
                        </Text>
                        <TimeInput
                            value={dateTime(timePreference.fromTime!, 'HH:mm')}
                            onChange={date => changeTime('fromTime', date)}
                            hideClear
                        />
                    </Box>
                    <Box className='col-4'>
                        <Text
                            paddingBottom={8}
                            color={Color.TimelineBackground}
                            size={12}
                            weight='bold'
                        >
                            {translate('notification-settings.to')}
                        </Text>
                        <TimeInput
                            value={dateTime(timePreference.toTime!, 'HH:mm')}
                            onChange={date => changeTime('toTime', date)}
                            hideClear
                        />
                    </Box>
                </Box>
                <Box paddingVertical={20}>
                    <BlockCheckboxGroup
                        items={allNotificationsPreferencesDays}
                        values={timePreference.days}
                        onChange={handleChangeDay}
                        renderer={item => translateDay(item)}
                    />
                </Box>
                <Info>{translate('notification-settings.additional.note')}</Info>
            </Box>
        )
    }, [settings.timePreference, changeTime, handleChangeDay, translateDay])

    const renderRightColumn = useMemo(() => {
        const { timePreference } = settings
        const timePickerEnabled = !!(timePreference.fromTime || timePreference.toTime)

        return (
            <>
                <Box className='flex-grid'>
                    <Box className='col-10'>
                        <h3 className='text__xxl-black'>
                            {translate('notification-settings.set-time')}
                        </h3>
                    </Box>
                    <Box className='col-2' justify='flex-end'>
                        <Toggle value={timePickerEnabled} onChange={handleToggleTimePicker} />
                    </Box>
                </Box>
                <p className='text__m-ow-pale text--lh-std padding-vertical--std'>
                    {translate('notification-settings.set-time.info')}
                </p>
                {timePickerEnabled && renderTimePicker}
            </>
        )
    }, [settings.timePreference, renderTimePicker, handleToggleTimePicker])

    return (
        <Box flex={1}>
            <Box className='flex-grid' fullHeight alignItems='stretch'>
                <Box className='col-6'>{renderLeftColumn}</Box>
                <Box className='col-6' bg={Color.Grey1} padding={30}>
                    {renderRightColumn}
                </Box>
            </Box>
        </Box>
    )
}
