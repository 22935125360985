import { CommonAuthStateAware, isMainAirportSelector } from 'aos-services/src/core/auth/state'
import { matchPath } from 'react-router'

import { Link } from '../Links'
import { currentPathSelector, CustomRouterStateAware } from './state'

export const urlQueryStringSelector = (state: CustomRouterStateAware) =>
    state.router.location?.search

export const getHiddenRoutes = (state: CommonAuthStateAware): Link[] =>
    isMainAirportSelector(state) ? [] : [Link.FlightsTowingList]

export const pathMatchesAnyRoute = (path: string, routes: Link[]): boolean => {
    return !!routes.find(r => matchPath(path, { exact: false, path: r }))
}

export const isOnHiddenRoute = (state: CustomRouterStateAware & CommonAuthStateAware): boolean =>
    pathMatchesAnyRoute(currentPathSelector(state), getHiddenRoutes(state))

export const getNonHiddenParentPath = (
    state: CustomRouterStateAware & CommonAuthStateAware,
): string => {
    let path = currentPathSelector(state)
    const hiddenRoutes = getHiddenRoutes(state)
    while (pathMatchesAnyRoute(path, hiddenRoutes) && path !== '') {
        path = path.substring(0, path.lastIndexOf('/'))
    }
    return path
}
