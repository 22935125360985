import { appConfig } from 'appConfig'
import { rgba } from 'polished'

const BaseColors = {
    Grey: '#69728B',
    GreyLight: '#abb4c9',

    Black: '#050a10',
    White: '#ffffff',
    WhiteOpacity45: 'rgba(255,255,255, .45)',

    Grey1: '#f7f7fa',
    Grey2: '#858891',
    Grey3: '#303d58',
    Grey4: '#B1B4BD',
    Grey5: '#677497',
    Grey6: '#707688',
    Grey7: '#34435F',
    Grey8: '#9BA2B0',

    Transparent: 'rgba(0,0,0,0)',
    TransparentBlack: 'rgba(0,0,0,0.6)',
    TransparentGrey: '#E5E6EA',

    Green: '#32a538',
    DarkGreen: '#0a6e2c',
    LightGreen: '#45eb4d',
    ToastGreen: '#409244',
    PaleGreen: '#a6d46d',
    VeryPaleOrange: '#F6F0E9',
    UltraPaleGreen: '#E9F6EA',
    SoftGreen: '#81c48d',
    Yellow: '#f8ba00',
    DarkYellow: '#664A02',
    PaleOrange: '#f1a267',
    Orange: '#ff6d00',
    Red: '#e42626',
    PaleRed: '#F6E9E9',
    DarkRed: '#280006',
    Magenta: '#da58ab',
    SoftMagenta: '#d958ab',
    Violet: '#6c55a7',
    SoftBlue: '#4c85ff',
    SeaBlue: '#4dc8c9',
    LightBlue: '#E9EEF6',
    PaleBlue: '#DFE9FF',
    TaskBlue: '#1A62FB',
    IntensiveBlue: '#1D62FB',
    TaskYellow: '#ffd145',
    DarkBlue: '#060B14',
    InfoToastBlue: '#005de5',
    ProgressGreen: '#69F996',
    ProgressRed: '#FF073A',
    ProgressGrey: '#D9D9D9',
    ProgressYellow: '#FFB904',
    ProgressBarGradient: 'linear-gradient(180deg, rgba(108,255,156,1) 0%, rgba(50,165,56,1) 100%)',
    UnitTaskBlack: '#16181C',
    UnitTaskYellow: '#332500',
    UnitTaskGreen: '#163320',
    UnitTaskRed: '#140001',
}

const AosColors = {
    //Primary colors
    Primary: '#005de5',
    PrimaryLight: '#417eff',
    PrimaryDark: '#0f4aca',
    PrimaryPale: '#7ca6ff',

    BackgroundPrimary: '#0b1029',
    BackgroundLight: '#182146',
    BackgroundDark: '#232d43',
    BottomTabsBackground: '#0b1029',
    LoginScreenBackground: '#0b1029',
    RemoveAccountBackground: BaseColors.White,
    RemoveAccountBorder: '#b9b9b9',

    TextPrimary: '#FFFFFF',
    TextSecondary: '#69728B',
    TextTheme: '#FFFFFF',
    TextButtonLabel: '#005de5',

    //App specific colors
    WidgetFontColor: '#FFFFFF',
    FlightsScreenAirportSelectBackground: '#005de5',

    PrimaryButtonHoverBackground: '#417eff',
    PrimaryOutlinedButtonHoverBackground: '#417eff',

    ProgressBarBackground: 'rgba(24, 33, 70, 0.4)',

    ProgressBar: '#FFF',
    MobileReportingItem: '#182146',

    MenuButtonColor: '#6780aa', //UiBlack4
    MessageSpecificBackgroundColor: '#6780aa',
    StatusDashboardProcessLinkBackground: '#6780aa',
    StatusTileNeutral: '#6780aa',
    FireFighterTileBorder: '#6780aa',
    MobileContactDetailsBorder: '#6780aa',
    MobileDescriptionInputBorder: '#6780aa',
    MobileReportingItemIconWrapperBorder: '#6780aa',
    FormListSeparator: '#6780aa',
    FormSwitchBase: '#6780aa',
    BadgeLabelBorder: '#6780aa',
    UserPhotoBase: '#6780aa',
    ToggleBase: '#6780aa',

    ReadOnlyInputBase: '#6780aa',

    SideMenuInactive: '#6780aa',
    WidgetSecondary: '#69728B',
    WidgetPrimary: '#FFFFFF',
    TabBarActive: '#fff',
    TabBarInactive: '#000',
    ChartBase: '#3b4b6a', //UiBlack3
    DashboardItemBackground: '#1B2435',
    DisabledText: '#c6c7cb', //Grey2
    TimelineBackground: '#14202c', // UiBlack1
    FlightGrey: '#879dc1',
    ActiveGrey: '#2D3952',
    ContactListItem: '#20293A',
    SecondaryText: '#838FAE',
    ToastFont: '#232324',
    Tint: '#417eff',
    ChecklistBackground: '#18202a',
    HeaderActiveTabUnderline: BaseColors.White,
    DarkScrollbar: 'rgba(59, 75, 106, .8)',
    ContactFormAddNewButtonColor: '#417eff',
    EventDetailsSelectLocationLinkColor: '#69728B',
    OutlineButtonBackground: '#FFF',
    FlightsChartTitle: '#69728B',
    FlightChartStatsWrapperBackground: '',
    FlightsTableText: '#879dc1',
    PrimaryComponentLabel: BaseColors.White,
    TransparentOutlinedButtonHoverBackground: BaseColors.White,
    DashboardWarningBadge: BaseColors.Yellow,
    RadioSeverityMaintenance: '#6780aa',
    RadioPrimary: '#3b4b6a',

    MobileContactEmailButtonBackground: '#6780aa',
    MobilePrivateChannelInvitationRoleObserveButtonBackground: '#6780aa',

    BottomTabActive: '#FFFFFF',
    BottomTabInactive: '#6780aa',
    DisabledButton: BaseColors.Grey5,
    MapFill: 'rgba(208, 220, 156, 0.5)',
    MapBorder: 'rgba(245, 247, 156, 1)',
    DarkInput: BaseColors.Black,
    WeatherChartBackground: '#1b2437',
    TransparentButtonBorder: BaseColors.White,
    TransparentButtonText: BaseColors.White,
    TransparentButtonTextHover: BaseColors.Black,
    LinkText: '#7ca6ff',
    ChartAxisLineStroke: '#3b4b6a',
    ChartAxisLabel: '#69728b',
    UnderlineTabsActiveColor: '#005de5',
    PrimaryIconBoxButtonTint: BaseColors.White,
    ThemeOnWhite: '#005de5',
    AddAttachmentButton: '#182146',
    AddAttachmentButtonContent: BaseColors.White,

    CheckboxPrimary: '#005de5',
    CheckboxInactiveLight: BaseColors.Grey2,
    CheckboxInactiveDark: BaseColors.Grey2,

    MobileFlightInfoNatureOfFlightBase: '#6780aa',

    ChecklistTaskRadio: '#3b4b6a',

    AirportContactButton: '#182146',
    WeatherIconNormal: '#14202c',
    WeatherIconRain: '#005de5',
    WeatherIconSnow: '#4dc8c9',
    WeatherIconRainSnow: '#6c55a7',
    GraphInactiveColor: '#3C4B69',
    GraphBackground: '#232d43',

    RunwayHelp: '#6780AA',
    RunwayHelpFade: '#3C4B69',
    RunwayLegendDeparture: '#005DE5',
}

const AoimsColors = {
    PrimaryLight: '#FFDD4F',
    Primary: '#FFDD4F',
    PrimaryDark: '#b08f22',
    PrimaryPale: '#2D2D2D',

    BackgroundPrimary: '#0F0F0F',
    BackgroundLight: '#1C1C1C',
    BackgroundDark: '#2D2D2D',
    BottomTabsBackground: '#2D2D2D',
    LoginScreenBackground: '#2D2D2D',
    RemoveAccountBackground: '#EAEEEF',
    RemoveAccountBorder: '#b9b9b9',

    TextPrimary: '#FFFFFF',
    TextSecondary: '#EAEAEA',
    TextTheme: '#0F0F0F',
    TextButtonLabel: '#6A5600',

    WidgetFontColor: '#B9B9B9',
    FlightsScreenAirportSelectBackground: '#2D2D2D',

    PrimaryOutlinedButtonHoverBackground: '#b08f22',
    PrimaryButtonHoverBackground: '#b08f22',

    ProgressBarBackground: '#FFF1B8',
    ProgressBar: '#1C1C1C',

    MobileReportingItem: '#2D2D2D',

    MenuButtonColor: '#FFFFFF',
    MessageSpecificBackgroundColor: '#FFFFFF',
    StatusDashboardProcessLinkBackground: '#FFFFFF',
    StatusTileNeutral: '#FFFFFF',
    FireFighterTileBorder: '#FFFFFF',
    MobileContactDetailsBorder: '#FFFFFF',
    MobileDescriptionInputBorder: '#FFFFFF',
    MobileReportingItemIconWrapperBorder: '#FFFFFF',
    ReadOnlyInputBase: '#FFFFFF',
    FormListSeparator: '#FFFFFF',
    FormSwitchBase: '#FFFFFF',
    BadgeLabelBorder: '#FFFFFF',
    UserPhotoBase: '#FFFFFF',

    ToggleBase: '#FFFFFF',

    SideMenuInactive: '#8b8b8b',
    WidgetSecondary: '#B9B9B9',
    WidgetPrimary: '#FFF',

    TabBarActive: rgba('#000', 1),
    TabBarInactive: rgba('#000', 0.73),
    ChartBase: '#5a5a5a',

    DashboardItemBackground: '#2D2D2D',
    DisabledText: '#c6c7cb',
    TimelineBackground: '#0F0F0F',
    FlightGrey: '#B9B9B9',
    ActiveGrey: '#5a5a5a',
    ContactListItem: '#2D2D2D',
    SecondaryText: '#B9B9B9',
    ToastFont: '#232324',
    Tint: '#2D2D2D',
    ChecklistBackground: '#18202a',
    HeaderActiveTabUnderline: '#0F0F0F',
    DarkScrollbar: 'rgba(50, 50, 50, .8)',
    ContactFormAddNewButtonColor: '#b08f22',
    EventDetailsSelectLocationLinkColor: '#B9B9B9',
    OutlineButtonBackground: '#FFDD4F',
    FlightsChartTitle: BaseColors.White,
    FlightChartStatsWrapperBackground: '#1C1C1C',
    FlightsTableText: BaseColors.White,
    PrimaryComponentLabel: '#0F0F0F',
    TransparentOutlinedButtonHoverBackground: '#FFFFFF80',
    DashboardWarningBadge: '#FF903C',
    RadioSeverityMaintenance: '#5a5a5a',
    RadioPrimary: '#FFDD4F',

    MobileContactEmailButtonBackground: '#5a5a5a',
    MobilePrivateChannelInvitationRoleObserveButtonBackground: '#5a5a5a',

    BottomTabActive: '#FFFFFF',
    BottomTabInactive: '#5a5a5a',
    DisabledButton: '#5a5a5a',

    MapFill: 'rgba(222, 232, 244, 0.5)',
    MapBorder: 'rgba(45, 142, 156, 1)',
    DarkInput: '#1c1c1c',
    WeatherChartBackground: '#1c1c1c',

    TransparentButtonBorder: BaseColors.Black,
    TransparentButtonText: BaseColors.Black,
    TransparentButtonTextHover: BaseColors.White,
    LinkText: '#b08f22',
    ChartAxisLineStroke: '#5A5A5A',
    ChartAxisLabel: '#EAEAEA',
    UnderlineTabsActiveColor: '#b08f22',
    PrimaryIconBoxButtonTint: BaseColors.Black,
    ThemeOnWhite: '#b08f22',
    AddAttachmentButton: '#2D2D2D',
    AddAttachmentButtonContent: BaseColors.White,
    CheckboxPrimary: '#F8BA00',

    CheckboxInactiveLight: '#B9B9B9',
    CheckboxInactiveDark: '#5A5A5A',
    MobileFlightInfoNatureOfFlightBase: '#5A5A5A',

    ChecklistTaskRadio: '#FFDD4F',
    AirportContactButton: '#2D2D2D',

    WeatherIconNormal: '#767676',
    WeatherIconRain: '#1F72EA',
    WeatherIconSnow: '#4C9D9D',
    WeatherIconRainSnow: '#7E6CAE',
    GraphInactiveColor: '#5A5A5A',
    GraphBackground: '#1C1C1C',

    RunwayHelp: '#767676',
    RunwayHelpFade: '#4B4135',
    RunwayLegendDeparture: '#005DE5',
}

const ColorConfig: Record<string, typeof AosColors> = {
    AOS: AosColors,
    AOIMS: AoimsColors,
}

export const Color = {
    ...BaseColors,
    ...ColorConfig[appConfig.theme],
}

export const statusBarColor = {
    AOS: 'light-content',
    AOIMS: 'dark-content',
}[appConfig.theme]

export type Color =
    | (typeof BaseColors)[keyof typeof BaseColors]
    | (typeof AosColors)[keyof typeof AosColors]
    | (typeof AoimsColors)[keyof typeof AoimsColors]
