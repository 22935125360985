import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { getBaggageDeliveryArrivalsHelpConfig } from '../../help/baggageDeliveryArrivals/baggageDeliveryArrivalsHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { BaggageDelivery } from './BaggageDelivery'
import { BaggageDeliveryTile } from './BaggageDeliveryTile'

export const getBaggageDeliveryConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 12, h: 4 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 7 },
            maxSize: { w: 10, h: 10 },
        },
    },
    renderer: BaggageDelivery,
    tileRenderer: BaggageDeliveryTile,
    helpConfig: getBaggageDeliveryArrivalsHelpConfig(),
})
