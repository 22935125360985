import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    isSnowtam,
    NotamTimelineItem,
} from 'aos-services/src/services/flightInformation/types/Notam'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { InfoBarContent, InfoBarText } from 'aos-ui/src/components/infobar/InfoBar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

interface NotamTimelineContentProps {
    item: NotamTimelineItem
    inTooltip?: boolean
}

export const NotamTimelineContent: React.FC<NotamTimelineContentProps> = ({
    item,
    inTooltip = false,
}) => {
    const iconVariant = inTooltip ? IconVariant.Black : IconVariant.White
    const textColor = inTooltip ? Color.Black : Color.White

    return (
        <InfoBarContent inTooltip={inTooltip} paddingHorizontal={12} paddingVertical={6}>
            <Box fullHeight row flex={1}>
                <Icon
                    iconVariant={iconVariant}
                    iconSize={BlockSize.Std}
                    svg={isSnowtam(item.payload) ? SvgIcon.SNOWTAM : SvgIcon.NOTAM}
                />
                <Text size={14} color={textColor} lineHeight='large' paddingLeft={8}>
                    {`(${item.id})`}
                </Text>
                <VerticalDivider />
                <InfoBarText
                    size={14}
                    color={textColor}
                    overflow='hidden'
                    lineHeight='large'
                    paddingLeft={8}
                    data-timeline-text
                >
                    {isSnowtam(item.payload)
                        ? item.payload.situationalAwareness
                        : item.payload.text}
                </InfoBarText>
            </Box>
        </InfoBarContent>
    )
}

const VerticalDivider = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${Color.TextSecondary};
    margin: 0 10px;
`
