import { includesNormalizes } from 'aos-helpers/src/helpers/Filters'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    AosFlightBeltStatus,
    BaggageIndicatorProgress,
} from 'aos-services/src/services/flightInformation/types/AosFlightBeltStatus'
import { AosFlightRoute } from 'aos-services/src/services/flightInformation/types/AosFlightRoute'
import { AosChangedValue } from 'aos-services/src/services/flightInformation/types/AosParkGate'
import { AosPublicRemark } from 'aos-services/src/services/flightInformation/types/AosPublicRemark'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import {
    isWithinRange,
    TimeRangeFilter,
} from 'aos-services/src/services/flightInformation/types/TimeRangeFilter'

import { TableFilter } from '../state'

const filterForComparator =
    <T, V>(comparator: f.Func2<T, V, boolean>) =>
    (filter: TableFilter<T>, flight: Flight): boolean => {
        if (filter && filter.value !== undefined) {
            const id: keyof Flight = filter.id as keyof Flight
            const value = flight[id] as V
            if (value) {
                return comparator(filter.value, value)
            }
            return false
        }
        return true
    }

export const timeFilter = filterForComparator((filterValue: TimeRangeFilter, value: DateTime) =>
    isWithinRange(filterValue, value.utc()),
)

export const routeFilter = filterForComparator((filterValue: string[], value: AosFlightRoute[]) =>
    filterValue.length
        ? filterValue.some(
              filter => value.length > 0 && includesNormalizes(value[0].airport, filter),
          )
        : true,
)

export const prmFilter = filterForComparator(
    (filterValue: string, value: AosPublicRemark) => value.text === filterValue,
)

export const bagFilter = filterForComparator(
    (filterValue: BaggageIndicatorProgress, value: AosFlightBeltStatus) =>
        value.progress === filterValue,
)

export const changedValueFilter = filterForComparator(
    (filterValue: string, value: AosChangedValue) =>
        includesNormalizes(value.current, filterValue) ||
        includesNormalizes(value.previous, filterValue),
)

export const equalArrayValueFilter = filterForComparator(
    (filterValue: string[], value: { value: string }) => {
        const val = typeof value === 'object' ? value.value : value
        return filterValue.length ? filterValue.some(filter => val === filter) : true
    },
)
