import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { restrictionsSelector } from 'aos-services/src/core/restrictions/selectors'
import { TimelineState } from 'aos-services/src/core/timeline/state'
import { flightFilterService } from 'aos-services/src/services/flightInformation/FlightFilterService'
import { bySdtComparator } from 'aos-services/src/services/flightInformation/types/Flight'
import {
    restrictionAreas,
    translateAreaNumberShortLabel,
} from 'aos-services/src/services/restrictions/types/RestrictionArea'
import { RestrictionResourceType } from 'aos-services/src/services/restrictions/types/RestrictionResourceType'
import { byResourceComparator } from 'aos-services/src/services/restrictions/types/RestrictionSorting'
import { Color } from 'aos-ui-common/src/styles/Color'
import { chain } from 'lodash'
import { createSelector } from 'reselect'

import { arrivalsSelector, departuresSelector } from '../flightInformation/flight/flightSelectors'
import { StandsGatesTimelineStateAware } from './state'
import {
    getGroupKey,
    getRootGroupKey,
    StandsGatesTimelineGroup,
} from './types/StandsGatesTimelineGroup'
import { StandsGatesTimelineItem } from './types/StandsGatesTimelineItem'

export interface StandsGatesTimelineSelectorState {
    groups: StandsGatesTimelineGroup[]
    items: StandsGatesTimelineItem[]
    currentTime: DateTime
    timelineState: TimelineState
}

export const isFiltersModalOpenSelector = (state: StandsGatesTimelineStateAware) =>
    state.standsGatesTimeline.isFiltersModalOpen

export const filtersSelector = (state: StandsGatesTimelineStateAware) =>
    state.standsGatesTimeline.filters

export const selectedItemSelector = (state: StandsGatesTimelineStateAware) =>
    state.standsGatesTimeline.selectedItem

export const selectedRestrictionSelector = (state: StandsGatesTimelineStateAware) =>
    state.standsGatesTimeline.selectedItem?.payload

export const selectedItemArrivalsSelector = createSelector(
    selectedRestrictionSelector,
    arrivalsSelector,
    (restriction, arrivals) =>
        restriction &&
        arrivals
            .filter(flightFilterService.isLooseStandRelatedTo(restriction.resourceNumber))
            .sort(bySdtComparator),
)
export const selectedItemDeparturesSelector = createSelector(
    selectedRestrictionSelector,
    departuresSelector,
    (restriction, departures) =>
        restriction &&
        departures
            .filter(
                restriction.resourceType === RestrictionResourceType.Stand
                    ? flightFilterService.isLooseStandRelatedTo(restriction.resourceNumber)
                    : flightFilterService.isGateRelatedTo(restriction.resourceNumber),
            )
            .sort(bySdtComparator),
)

const hiddenRootKeysSelector = createSelector(
    (state: StandsGatesTimelineStateAware) => state.standsGatesTimeline.filters.hiddenAreas,
    areas => areas.map(area => getRootGroupKey(area)),
)

const itemsSelector = createSelector(
    restrictionsSelector,
    (state: StandsGatesTimelineStateAware) => state.standsGatesTimeline.collapsedGroups,
    selectedItemSelector,
    (restrictions, collapsedGroups, selectedItem): StandsGatesTimelineItem[] =>
        restrictions.map(restriction => {
            const rootKey = getRootGroupKey(restriction)

            return {
                id: restriction.id,
                group: collapsedGroups.includes(rootKey) ? rootKey : getGroupKey(restriction),
                outlined: selectedItem?.payload.id !== restriction.id,
                color: Color.TextSecondary,
                startTime: restriction.startDate,
                endTime: restriction.endDate,
                isCollapsed: collapsedGroups.includes(getRootGroupKey(restriction)),
                payload: restriction,
            }
        }),
)

const filteredItemsSelector = createSelector(
    itemsSelector,
    hiddenRootKeysSelector,
    (items, hiddenKeys) =>
        items.filter(item => !hiddenKeys.includes(getRootGroupKey(item.payload))),
)

const subgroupsSelector = createSelector(
    restrictionsSelector,
    (restrictions): StandsGatesTimelineGroup[] =>
        chain(restrictions)
            .sort(byResourceComparator)
            .map(restriction => ({
                id: getGroupKey(restriction),
                isCollapsed: false,
                title: restriction.resourceNumber,
                timelineLabel: restriction.resourceNumber,
                lineHeight: 50,
                minHeight: 55,
                rootKey: getRootGroupKey(restriction),
                isRoot: false,
            }))
            .uniqBy('id')
            .value() as StandsGatesTimelineGroup[],
)

const activeSubgroupsSelector = createSelector(
    (state: StandsGatesTimelineStateAware) => state.standsGatesTimeline.collapsedGroups,
    subgroupsSelector,
    (collapsedGroups, subgroups): StandsGatesTimelineGroup[] =>
        subgroups.filter(subgroup => !collapsedGroups.includes(subgroup.rootKey)),
)

const groupsSelector = createSelector(
    (state: StandsGatesTimelineStateAware) => state.standsGatesTimeline.collapsedGroups,
    activeSubgroupsSelector,
    (collapsedGroups, groups) =>
        restrictionAreas
            .map(area => {
                const rootKey = getRootGroupKey(area)
                const isCollapsed = collapsedGroups.includes(rootKey)
                const title = translateAreaNumberShortLabel(area.areaType, area.areaNumber)

                return {
                    id: getRootGroupKey(area),
                    isCollapsed: isCollapsed,
                    title: title,
                    timelineLabel: title,
                    lineHeight: isCollapsed ? 10 : 55,
                    minHeight: 55,
                    rootKey: getRootGroupKey(area),
                    isRoot: true,
                }
            })
            .map(root => [root, ...groups.filter(group => group.rootKey === root.rootKey)])
            .flat(),
)

const filteredGroupsSelector = createSelector(
    groupsSelector,
    hiddenRootKeysSelector,
    (groups, hiddenKeys) => groups.filter(group => !hiddenKeys.includes(group.rootKey)),
)

export const standsGatesTimelineSelector = createSelector(
    (state: StandsGatesTimelineStateAware) => state.standsGatesTimeline.timeline,
    currentTimeSelector,
    filteredItemsSelector,
    filteredGroupsSelector,
    (timelineState, currentTime, items, groups) => ({
        groups,
        items,
        currentTime,
        timelineState,
    }),
)
