import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { setEventMapUserLayersVisibilityAction } from 'aos-services/src/core/eventUserLayersVisibility/actions'
import { EventMapUserLayerVisibilityState } from 'aos-services/src/core/eventUserLayersVisibility/state'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { isAosEvent } from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { BackButtonTitleSection } from 'aos-ui/src/components/modal/BackButtonTitleSection'
import { DialogView, MapAwareModal } from 'aos-ui/src/components/modal/MapAwareModal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { ModalMapHeader } from 'aos-ui-map/src/components/map/header/ModalMapHeader'
import { setLayersVisibilityAction, switchMapAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import {
    eventPreviewMapAction,
    previewEventHideModalAction,
    setDialogSubContentAction,
} from '../../core/eventPreview/actions'
import { EventPreviewMode, EventPreviewState } from '../../core/eventPreview/state'
import { State } from '../../core/state'
import { EventContent } from './partial/EventContent'
import { EventPreviewMap } from './partial/EventPreviewMap'
import { FeedInContent } from './partial/FeedInContent'

export const EventPreviewClass: FC<EventPreviewProps & EventPreviewDispatchProps> = ({
    event,
    mode,
    isEventAdmin,
    map,
    subContent,
    bimLayers,
    eventMapUserLayerVisibility,
    setEventMapUserLayersVisibilityAction,
    ...props
}) => {
    const changeUserMapLayersVisibility = (v: string[]) => {
        setEventMapUserLayersVisibilityAction({
            ...eventMapUserLayerVisibility,
            list: v,
        })
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...eventMapUserLayerVisibility,
            list: [
                ...eventMapUserLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setEventMapUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    if (!event) {
        return null
    }
    const navigateToContent = () => props.setDialogModeAction(DialogView.Content)
    const mapHeaderInternal = () => (
        <ModalMapHeader
            mapVariant={map.variant}
            onChangeVariant={props.switchMapAction}
            mapFilter={false}
            location={MapSiteLocationCustomization.MainAirport}
            bimLayers={bimLayers}
            userLayersVisibility={eventMapUserLayerVisibility}
            setUserLayersVisibilityAction={changeUserMapLayersVisibility}
            changeFloorLayerVisibility={changeFloorLayerVisibility}
        >
            {event.title}
        </ModalMapHeader>
    )

    const mapHeader = () => {
        if (mode === EventPreviewMode.ContentAndMap) {
            return (
                <BackButtonTitleSection onClick={navigateToContent}>
                    {mapHeaderInternal()}
                </BackButtonTitleSection>
            )
        } else {
            return <Box paddingRight={8}>{mapHeaderInternal()}</Box>
        }
    }

    const contentHeader = () => event.title

    const footer = () =>
        mode === EventPreviewMode.ContentAndMap ? (
            <Box justify='flex-end'>
                {subContent === DialogView.Map && (
                    <GreyTextButton onClick={navigateToContent} label={translate('back')} />
                )}
                <FormButton
                    label={translate('event-preview.ok')}
                    onClick={props.previewEventHideModalAction}
                />
            </Box>
        ) : undefined

    const content = () =>
        isAosEvent(event) ? (
            <EventContent
                event={event}
                setDialogModeAction={props.setDialogModeAction}
                isEventAdmin={isEventAdmin}
            />
        ) : (
            <FeedInContent feedIn={event} setDialogModeAction={props.setDialogModeAction} />
        )

    return (
        <MapAwareModal
            id='create-event-modal'
            isOpen
            modalKind={ModalKind.Big}
            closeAction={props.previewEventHideModalAction}
            view={subContent}
            mapFooter={footer()}
            mapHeader={mapHeader()}
            contentHeader={contentHeader()}
            contentFooter={footer()}
            content={content()}
            map={<EventPreviewMap />}
        />
    )
}

interface EventPreviewProps extends EventPreviewState {
    isEventAdmin: boolean
    bimLayers: BimLayersState
    eventMapUserLayerVisibility: EventMapUserLayerVisibilityState
}

interface EventPreviewDispatchProps {
    previewEventHideModalAction: typeof previewEventHideModalAction
    setDialogModeAction: typeof setDialogSubContentAction
    switchMapAction: typeof switchMapAction
    setLayersVisibilityAction: typeof setLayersVisibilityAction
    setEventMapUserLayersVisibilityAction: typeof setEventMapUserLayersVisibilityAction
}

export const EventPreview = connect<EventPreviewProps, EventPreviewDispatchProps, {}>(
    (state: State) => ({
        ...state.eventPreview,
        isEventAdmin: state.auth.currentUser.isEventAdmin(),
        bimLayers: state.bimLayers,
        eventMapUserLayerVisibility: state.eventMapUserLayerVisibility,
    }),
    {
        previewEventHideModalAction,
        setDialogModeAction: setDialogSubContentAction,
        switchMapAction: eventPreviewMapAction.switchMapAction,
        setLayersVisibilityAction: eventPreviewMapAction.setLayersVisibilityAction,
        setEventMapUserLayersVisibilityAction,
    },
)(EventPreviewClass)
