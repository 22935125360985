import { Box } from 'aos-ui/src/components/base/Box'
import { boxShadow, ZIndex } from 'aos-ui/src/components/base/Theme'
import { HeaderVariant } from 'aos-ui/src/components/header/Header'
import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { CSSObject } from 'styled-components'

import { SideContainerHeader } from '../header/SideContainerHeader'

export enum SideContainerVariant {
    Left = 1,
    Right,
    Pinned,
}

interface ToggableSideContainerProps {
    title: string
    isOpen: boolean
    variant: SideContainerVariant
    close(): void
    onPin?(v: boolean): void
}

export const SideContainer: FCWithChildren<
    PropsWithChildren<ToggableSideContainerProps>
> = props => {
    const { title, isOpen, close, variant, children, onPin } = props
    const isPinned = variant === SideContainerVariant.Pinned
    const headerVariant =
        isPinned || variant === SideContainerVariant.Left
            ? HeaderVariant.Dialog
            : HeaderVariant.Notifications
    return (
        <Wrapper variant={variant} isOpen={isOpen}>
            {!isPinned && <Overlay isOpen={isOpen} onClick={close} />}
            <Box fullHeight column relative>
                <SideContainerHeader
                    variant={headerVariant}
                    title={title}
                    close={close}
                    isPinned={isPinned}
                    onPin={onPin}
                />
                {children}
            </Box>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ variant: SideContainerVariant; isOpen: boolean }>(
    ({ variant, isOpen }) => {
        const styles: CSSObject = {
            transition: 'transform 0.2s ease-in-out',
        }

        switch (variant) {
            case SideContainerVariant.Pinned:
                styles.width = '480px'
                styles.height = '100%'
                styles.borderLeft = `1px solid ${Color.ChartBase};`
                styles.background = Color.BackgroundDark
                break

            case SideContainerVariant.Left:
                styles.position = 'fixed'
                styles.bottom = 0
                styles.left = '96px'
                styles.top = '96px'
                styles.width = '560px'
                styles.transform = 'translateX(-100%);'
                styles.zIndex = ZIndex.GlobalMessaging
                styles.background = Color.BackgroundDark
                break

            case SideContainerVariant.Right:
                styles.position = 'fixed'
                styles.bottom = 0
                styles.top = '0'
                styles.right = '0'
                styles.transform = 'translateX(100%);'
                styles.width = '480px'
                styles.zIndex = ZIndex.Notifications
                styles.background = Color.BackgroundDark
                break
        }

        if (isOpen) {
            styles.transform = 'translateX(0);'
            styles.boxShadow = boxShadow.large
        }

        return styles
    },
)

const Overlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    right: -5000px;
    bottom: 0;
    left: -5000px;
    background-color: ${rgba(Color.BackgroundDark, 0.7)};
    animation: overlayBackground 0.3s ease both;
    display: ${p => (p.isOpen ? 'block' : 'none')};
    @keyframes overlayBackground {
        from {
            background-color: transparent;
        }
        to {
            background-color: ${rgba(Color.BackgroundDark, 0.7)};
        }
    }
`
