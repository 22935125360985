import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const title = (suffix: string) => translate(`dashboard.help.delays.${suffix}`)

export const getDelaysHelpConfig = (): HelpModalConfig => ({
    title: translate('dashboard.help.delays.title'),
    items: [
        {
            title: title('delays'),
            renderer: lazy(() => import('./renderers/DelaysHelp')),
        },
        {
            title: title('delays-chart'),
            renderer: lazy(() => import('./renderers/DelaysChartHelp')),
        },
    ],
})
