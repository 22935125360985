import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FC } from 'react'
import { TableRowProps as ReactVirtualizedTableRowProps } from 'react-virtualized'
import styled from 'styled-components'

import { NowLine } from './NowLine'

export const ROW_HEIGHT = 50
export const NOW_ROW_HEIGHT = 100

interface TableRowProps {
    row: ReactVirtualizedTableRowProps
    now?: DateTime
    nowIndex?: number
}

export const TableRow: FC<TableRowProps> = props => {
    const { row, now, nowIndex } = props
    const showNowLine = row.index === nowIndex && now
    const odd = row.index % 2 === 0

    return (
        <StyledTableRow bg={odd ? Color.BackgroundDark : Color.BackgroundPrimary} style={row.style}>
            {showNowLine && <NowLine now={now!} style={{ height: NOW_ROW_HEIGHT - ROW_HEIGHT }} />}
            <Box className={row.className} style={{ height: ROW_HEIGHT }}>
                {row.columns}
            </Box>
        </StyledTableRow>
    )
}

const StyledTableRow = styled(Box)`
    &:hover {
        background-color: ${rgba(Color.ChartBase, 0.8)};
    }
`
