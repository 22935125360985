import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { NewCommonMessage } from 'aos-services/src/services/messages/input/NewAosMessage'
import { NewGlobalMessage } from 'aos-services/src/services/messages/input/NewGlobalMessage'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserGroupType } from 'aos-services/src/services/users/types/AosUserGroupType'
import { messagingChannels } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { SideContainer, SideContainerVariant } from 'aos-ui/src/components/container/SideContainer'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { ScrollPosition } from 'aos-ui/src/components/scrollbar/Scrollbar'
import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { userGroupsSelector } from '../../core/commonFrontend/state'
import {
    broadcastGlobalMessageRequestAction,
    changeGlobalMessagingFormAction,
    toggleGlobalMessageConfirmationAction,
    toggleGlobalMessagingAction,
} from '../../core/globalMessaging/actions'
import { GlobalMessagingState } from '../../core/globalMessaging/state'
import { State } from '../../core/state'
import { MessageEditor } from '../common/messages/MessageEditor'
import { MessagesContainer } from '../common/messages/MessagesContainer'

class GlobalMessagingContainerClass extends PureComponent<GlobalMessagingContainerProps> {
    public render() {
        const {
            toggleGlobalMessaging,
            groups,
            changeFormAction,
            isAosAdmin,
            toggleConfirmation,
            newMessage,
            messagingState,
        } = this.props

        const { ui, isOpen, messages } = messagingState

        if (!isAosAdmin) {
            return null
        }
        const defaultGroupId = this.getDefaultGroup()
        const groupsLoaded = groups && groups.length > 0 && defaultGroupId
        const closeMessagesContainer = () => toggleGlobalMessaging(false)
        return (
            <SideContainer
                title={translate('menu.messaging')}
                isOpen={isOpen}
                close={closeMessagesContainer}
                variant={SideContainerVariant.Left}
            >
                <Box flex={1} column justify='space-between'>
                    <Box flex={1}>
                        <DarkScrollbar
                            scrollOnChecksumUpdate={ScrollPosition.bottom}
                            startPosition={ScrollPosition.bottom}
                            checksum={messages.length}
                        >
                            <MessagesContainer
                                className='padding--x-large'
                                messages={messages}
                                messagesTitleVariant={ThemeVariant.Black}
                            />
                        </DarkScrollbar>
                    </Box>
                    {groupsLoaded && (
                        <MessageEditor
                            className='padding--x-large'
                            message={newMessage}
                            onChange={changeFormAction}
                            onSubmit={this.sendGlobalMessage(defaultGroupId)}
                            groups={groups}
                            defaultGroupId={defaultGroupId!}
                            availableChannels={messagingChannels}
                            isMessageValid={this.validateGlobalMessage}
                            isSendingMessage={ui.isSendingMessage}
                            isConfirmationVisible={ui.isConfirmationVisible}
                            toggleMessageConfirmation={toggleConfirmation}
                        />
                    )}
                </Box>
            </SideContainer>
        )
    }

    private getDefaultGroup = () => {
        const group = this.props.groups.find(v => v.type === AosUserGroupType.ALL)
        if (group) {
            return group.id
        }
        return
    }

    private sendGlobalMessage(defaultGroupId?: number) {
        return () => {
            const { broadcastMessage, newMessage } = this.props
            return broadcastMessage(_.defaults(newMessage, { groupId: defaultGroupId }))
        }
    }

    private validateGlobalMessage = (message: NewCommonMessage): boolean =>
        message.channels.length > 0
}

interface GlobalMessagingContainerProps
    extends GlobalMessagingContainerStateProps,
        GlobalMessagingContainerDispatchProps {}

interface GlobalMessagingContainerStateProps {
    messagingState: GlobalMessagingState
    newMessage: NewGlobalMessage
    groups: AosUserGroup[]
    isAosAdmin: boolean
}

interface GlobalMessagingContainerDispatchProps {
    changeFormAction: typeof changeGlobalMessagingFormAction
    broadcastMessage: typeof broadcastGlobalMessageRequestAction
    toggleConfirmation: typeof toggleGlobalMessageConfirmationAction
    toggleGlobalMessaging: typeof toggleGlobalMessagingAction
}

export const GlobalMessagingContainer = connect<
    GlobalMessagingContainerStateProps,
    GlobalMessagingContainerDispatchProps
>(
    (state: State) => ({
        messagingState: state.globalMessaging,
        newMessage: state.globalMessaging.newMessage,
        isAosAdmin: state.auth.currentUser.isAosAdmin(),
        groups: userGroupsSelector(state),
    }),
    {
        toggleGlobalMessaging: toggleGlobalMessagingAction,
        changeFormAction: changeGlobalMessagingFormAction,
        broadcastMessage: broadcastGlobalMessageRequestAction,
        toggleConfirmation: toggleGlobalMessageConfirmationAction,
    },
)(GlobalMessagingContainerClass)
