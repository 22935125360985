import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

interface NowLineProps {
    now: DateTime
    style?: CSSProperties
}

export const NowLine: FC<NowLineProps> = props => (
    <NowBox className='flight-table__now' style={props.style}>
        <b>{props.now.format('HH:mm')}</b>
    </NowBox>
)

const NowBox = styled(Box)`
    color: ${Color.Primary};
    position: relative;
    background: ${Color.BackgroundPrimary};

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        height: 2px;
        background: currentColor;
    }

    b {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 30px;
        bottom: 0;
        height: 20px;
        line-height: 20px;
        margin: auto;
        padding: 0 5px;
        font-size: 14px;
        background: ${Color.BackgroundPrimary};
    }
`
