import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import { appConfig } from 'appConfig'
import React, { FC } from 'react'

export const WhatsNewFooter: FC = () => (
    <Text className='text--selectable' textAlign='center' lineHeight='large'>
        <Text className='text--lb' color={Color.White} size={12} paddingBottom={8}>
            {translate('generic.version', {
                version: `${appConfig.version}-${appConfig.gitHash}`,
                env: appConfig.environmentName,
            })}
        </Text>
        <Text className='text--lb' color={Color.TextSecondary} size={12}>
            {translate('generic.copyright')}
        </Text>
    </Text>
)
