import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { NewsFeed } from './NewsFeed'
import { NewsFeedTile } from './NewsFeedTile'

export const getNewsFeedConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 3, h: 3 },
            maxSize: { w: 4, h: 10 },
        },
    },
    renderer: NewsFeed,
    tileRenderer: NewsFeedTile,
})
