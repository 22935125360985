import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

export const RunwaysMetar: FC<RunwaysMetarProps> = props => (
    <Text lineHeight='large' paddingBottom={8} paddingTop={8}>
        <StyledSpanTitle>{translate('dashboard.runways.metar.metar')}</StyledSpanTitle>
        <StyledSpanMetar>{props.metar}</StyledSpanMetar>
    </Text>
)

interface RunwaysMetarProps {
    metar: string
}

const StyledSpanTitle = styled.span`
    font-size: 12px;
    color: ${Color.White};
    margin-right: 4px;
`
const StyledSpanMetar = styled.span`
    font-size: 12px;
    color: ${Color.TextSecondary};
`
