import { zodResolver } from '@hookform/resolvers/zod'
import {
    FooterModalProps,
    FormModal,
} from 'aos-components/src/components/modal/InputModal/FormModal'
import { enumToArray } from 'aos-helpers/src/helpers/Enum'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosChecklistUnits,
    mapChecklistUnitToUserRole,
} from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import {
    AosUnitTaskItemDTO,
    aosUnitTaskItemSchema,
    EventPhase,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import {
    atcEventSeverityTitle,
    emergencyTaskSeverities,
} from 'aos-services/src/services/common/types/AosSeverity'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { CleanableTextarea } from 'aos-ui/src/components/form/input/CleanableTextarea'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { LabeledSeverity } from 'aos-ui/src/components/form/labeled/LabeledSeverity'
import { LabeledToggle } from 'aos-ui/src/components/form/labeled/LabeledToggle'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { DefaultRadioRenderer } from 'aos-ui/src/components/form/radio/DefaultRadioRenderer'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

interface UnitTaskItemModalProps {
    item: AosUnitTaskItemDTO
    isOpen: boolean
    keyPrefix: string
    onHide: () => void
    onSubmit: (item: AosUnitTaskItemDTO) => void
    lockSeverityChange?: boolean
}

export const UnitTaskItemModal: FC<UnitTaskItemModalProps> = ({
    item,
    isOpen,
    keyPrefix,
    onHide,
    onSubmit,
    lockSeverityChange,
}) => {
    const [previousItem, setPreviousItem] = useState<AosUnitTaskItemDTO | null>(item)
    const form = useForm<AosUnitTaskItemDTO>({
        defaultValues: item,
        mode: 'onSubmit',
        resolver: zodResolver(aosUnitTaskItemSchema),
    })

    useEffect(() => {
        if (previousItem?.createAnother) {
            form.reset({
                ...previousItem,
                additionalNote: undefined,
                hasAdditionalNote: false,
                content: undefined,
            })
        } else {
            form.reset(item)
        }
    }, [item])

    const footer = ({ pristine, valid, translateSuffix, submit }: FooterModalProps) => {
        return (
            <Box columnGap={8} className='container container--horizontal full-width'>
                <ValidationInfo pristine={pristine} valid={valid} />
                <Box row columnGap={24}>
                    {!item.id && (
                        <Controller
                            render={({ field }) => (
                                <Box row justify='center' columnGap={8}>
                                    <Checkbox checked={field.value} onChange={field.onChange} />
                                    <Text size={13} color={Color.TimelineBackground}>
                                        {translateSuffix('create-another')}
                                    </Text>
                                </Box>
                            )}
                            name='createAnother'
                        />
                    )}
                    <FormButton label={translateSuffix('ok')} onClick={submit} />
                </Box>
            </Box>
        )
    }

    const unitItems = useMemo(() => enumToArray(AosChecklistUnits), [])

    const submit = form.handleSubmit(formData => {
        onSubmit(formData)
        setPreviousItem(formData)
        if (!formData.createAnother) {
            onHide()
        }
        form.reset({ createAnother: formData.createAnother })
    })

    return (
        <FormProvider {...form}>
            <FormModal
                pristine={!form.formState.isDirty || !form.formState.isSubmitted}
                valid={form.formState.isValid}
                isOpen={isOpen}
                hide={() => {
                    onHide()
                    form.reset()
                    setPreviousItem(null)
                }}
                keyPrefix={keyPrefix}
                submit={submit}
                customFooter={footer}
            >
                <Box paddingBottom={12}>
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledSeverity
                                {...field}
                                {...fieldState}
                                disabled={lockSeverityChange}
                                keyPrefix='create-unit-task.severity'
                                seleniumLocation='severity'
                                severities={emergencyTaskSeverities}
                                isError={!!fieldState.error}
                                translateSeverity={atcEventSeverityTitle}
                                isRequired
                            />
                        )}
                        name='severity'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledSelect
                                maxHeight={300}
                                isError={!!fieldState.error}
                                labelRenderer={(label: AosChecklistUnits) =>
                                    translate(`unit.${mapChecklistUnitToUserRole[label]}`)
                                }
                                items={unitItems}
                                isRequired
                                keyPrefix='create-unit-task.unit'
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                        name='unit'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledFormElement
                                isRequired
                                isError={!!fieldState.error}
                                label={translate('create-unit-task.event-phase.label')}
                            >
                                <BlockRadioGroup
                                    items={enumToArray(EventPhase)}
                                    Renderer={({ isSelected, item, onSelect }) => (
                                        <DefaultRadioRenderer
                                            isSelected={isSelected}
                                            label={translateEnum(
                                                EventPhase,
                                                'create-unit-task.event-phase',
                                            )(item)}
                                            onSelect={onSelect}
                                            color={Color.ChecklistTaskRadio}
                                        />
                                    )}
                                    {...field}
                                    {...fieldState}
                                />
                            </LabeledFormElement>
                        )}
                        name='eventPhase'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledInput
                                isRequired
                                isError={!!fieldState.error}
                                {...field}
                                {...fieldState}
                                keyPrefix='create-unit-task.content'
                            />
                        )}
                        name='content'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <Box flex={1} justify='space-between' row alignContent='center'>
                                <LabeledToggle
                                    horizontal
                                    {...field}
                                    {...fieldState}
                                    keyPrefix='create-unit-task.has-additional-note'
                                />
                                <Tooltip
                                    white
                                    placement='topRight'
                                    body={<div>{translate('create-unit-task.tooltip')}</div>}
                                >
                                    <Box>
                                        <Icon svg={SvgIcon.InfoCircle} color={Color.Grey2} />
                                    </Box>
                                </Tooltip>
                            </Box>
                        )}
                        name='hasAdditionalNote'
                    />
                    {form.watch('hasAdditionalNote') && (
                        <Controller
                            render={({ field, fieldState }) => (
                                <Box>
                                    <CleanableTextarea
                                        {...field}
                                        {...fieldState}
                                        onChangeText={field.onChange}
                                    />
                                    {fieldState.error && (
                                        <Text marginTop={6} size={12} color={Color.Red}>
                                            {fieldState.error?.message}
                                        </Text>
                                    )}
                                </Box>
                            )}
                            name='additionalNote'
                        />
                    )}
                </Box>
            </FormModal>
        </FormProvider>
    )
}
