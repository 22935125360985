import { Role } from '../../auth/types/Role'

export enum AosChecklistUnits {
    APOC = 'APOC',
    APOC_TLL = 'APOC_TLL',
    AIRPORT_SERVICES = 'AIRPORT_SERVICES',
    EQUIPMENT_MAINTENANCE = 'EQUIPMENT_MAINTENANCE',
    APRON_SERVICES = 'APRON_SERVICES',
    APRON_CONTROL = 'APRON_CONTROL',
    RESCUE_SERVICE = 'RESCUE_SERVICE',
    AVIATION_ELECTRICITY = 'AVIATION_ELECTRICITY',
    SECURITY_SERVICES = 'SECURITY_SERVICES',
    AIRFIELD_MAINTENANCE = 'AIRFIELD_MAINTENANCE',
    FACILITY_MAINTENANCE = 'FACILITY_MAINTENANCE',
    CORPORATE_IM_SERVICES = 'CORPORATE_IM_SERVICES',
    COMMUNICATION = 'COMMUNICATION',
}

export const mapChecklistUnitToUserRole: Record<AosChecklistUnits, Role> = {
    [AosChecklistUnits.APOC]: Role.ApocUser,
    [AosChecklistUnits.APOC_TLL]: Role.ApocTllUser,
    [AosChecklistUnits.AIRPORT_SERVICES]: Role.AirportServiceUser,
    [AosChecklistUnits.EQUIPMENT_MAINTENANCE]: Role.EquipmentMaintenanceUser,
    [AosChecklistUnits.APRON_SERVICES]: Role.ApronServicesUser,
    [AosChecklistUnits.APRON_CONTROL]: Role.ApronControlUser,
    [AosChecklistUnits.RESCUE_SERVICE]: Role.RescueServiceUser,
    [AosChecklistUnits.AVIATION_ELECTRICITY]: Role.AviationElectricityUser,
    [AosChecklistUnits.SECURITY_SERVICES]: Role.SecurityServicesUser,
    [AosChecklistUnits.AIRFIELD_MAINTENANCE]: Role.AirfieldMaintenanceUser,
    [AosChecklistUnits.FACILITY_MAINTENANCE]: Role.FacilityMaintenanceUser,
    [AosChecklistUnits.CORPORATE_IM_SERVICES]: Role.CorporateIMServicesUser,
    [AosChecklistUnits.COMMUNICATION]: Role.CommunicationUser,
}
