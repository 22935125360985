import { AnimalSpecie } from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import { Box } from 'aos-ui/src/components/base/Box'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { ReportsFilters } from '../../../../core/tasks/reports/state'
import { TimeRangeDropdowns } from '../components/TimeRangeDropdowns'
import { ReportAirportDropdown } from '../dropdowns/ReportAirportDropdown'
import { ReportAnimalsDropdown } from '../dropdowns/ReportAnimalsDropdown'
import { ReportTypeDropdown } from '../dropdowns/ReportTypeDropdown'

interface AnimalsReportContentProps {
    filters: ReportsFilters
    animals: AnimalSpecie[]
    yearRange: number[]
    onChange(v: ReportsFilters): void
}

export const AnimalsReportContent: FC<AnimalsReportContentProps> = ({
    filters,
    onChange,
    animals,
    yearRange,
}) => {
    const updateFilter = (newFilters: Partial<ReportsFilters>) =>
        onChange({ ...filters, ...newFilters })

    return (
        <Box bg={Color.BackgroundDark}>
            <SeparatedContainer padding={24} row spacing={12} orientation='horizontal'>
                <ReportAirportDropdown
                    value={filters.airport}
                    onChange={airport => updateFilter({ airport })}
                />
                <ReportTypeDropdown
                    value={filters.report}
                    onChange={report => updateFilter({ report })}
                />
                <ReportAnimalsDropdown
                    value={filters.animal}
                    onChange={animal => updateFilter({ animal })}
                    animals={animals}
                />
                <TimeRangeDropdowns
                    filters={filters}
                    yearRange={yearRange}
                    updateFilter={updateFilter}
                />
            </SeparatedContainer>
        </Box>
    )
}
