import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { getPaxForecastHelpConfig } from '../../help/paxForecast/paxForecastHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { PaxForecast } from './PaxForecast'
import { PaxForecastTile } from './PaxForecastTile'

export const getPaxForecastConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 8, h: 3 },
            maxSize: { w: 12, h: 3 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 8, h: 7 },
            maxSize: { w: 12, h: 10 },
        },
    },
    renderer: PaxForecast,
    tileRenderer: PaxForecastTile,
    helpConfig: getPaxForecastHelpConfig(),
})
