import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { channelTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { NewCommonMessage } from 'aos-services/src/services/messages/input/NewAosMessage'
import {
    aosUserGroupName,
    getGroupMembersCountTranslation,
} from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

interface MessageConfirmationProps {
    group: AosUserGroup | undefined
    message: NewCommonMessage
    inProgress: boolean
    onConfirm(): void
}

interface MessageConfirmationState {
    isConfirmed: boolean
}

export class MessageConfirmation extends PureComponent<
    MessageConfirmationProps,
    MessageConfirmationState
> {
    public state = {
        isConfirmed: !this.hasSmsChannel(),
    }

    public render() {
        return this.props.inProgress ? this.renderSendingProgress() : this.renderConfirmation()
    }

    private renderConfirmation = () => {
        const { group, message, onConfirm } = this.props
        const groupName = group && aosUserGroupName(group, 'message')
        const groupMembersCount = group ? group.userCount : 0
        const cost = this.calculateEstimatedMessageCost(
            groupMembersCount,
            groupName || '',
            message.text,
        )
        return (
            <Box flex={1} column>
                {this.hasSmsChannel() && (
                    <Cost
                        color={Color.TimelineBackground}
                        size={14}
                        textAlign='center'
                        weight='regular'
                        centered
                    >
                        {`${translate('message-editor.confirmation.cost')} ${
                            cost < 1 ? '< $1' : `$${cost}`
                        }`}
                    </Cost>
                )}
                <Box column paddingVertical={30} alignItems='center' alignContent='center'>
                    <Text color={Color.Black} size={13} weight='regular' paddingBottom={4}>
                        {translate('message-editor.confirmation.description')}
                    </Text>
                    <Text
                        color={Color.TimelineBackground}
                        size={14}
                        weight='regular'
                        paddingBottom={4}
                    >
                        {this.getSelectedChannelsList()}
                    </Text>
                    <Text color={Color.Black} size={13} weight='regular' paddingBottom={4}>
                        {translate('message.to-group')}
                    </Text>
                    <Text
                        color={Color.TimelineBackground}
                        size={14}
                        weight='regular'
                        paddingBottom={4}
                    >
                        {`${groupMembersCount} ${getGroupMembersCountTranslation(
                            groupMembersCount,
                        )} (${groupName})`}
                    </Text>
                    <Box paddingTop={30} row>
                        {this.hasSmsChannel() && this.renderAdditionalConfirmation()}
                        <FormButton
                            label={translate('message-editor.confirmation.send')}
                            onClick={onConfirm}
                            disabled={!this.state.isConfirmed}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }

    private renderSendingProgress = () => (
        <ProgressBox
            size={14}
            color={Color.Black}
            weight='regular'
            textAlign='center'
            paddingBottom={40}
        >
            {translate('message-editor.confirmation.sending')}
        </ProgressBox>
    )

    private renderAdditionalConfirmation = () => (
        <Box centered paddingRight={20}>
            <Checkbox
                position='left'
                checked={this.state.isConfirmed}
                onChange={this.handleToggleCheckbox}
                checkableLabel
            >
                {translate('message-editor.confirmation.confirmation')}
            </Checkbox>
        </Box>
    )

    private handleToggleCheckbox = () => {
        this.setState({ isConfirmed: !this.state.isConfirmed })
    }

    private calculateEstimatedMessageCost = (
        membersCount: number,
        groupName: string,
        messageText: string,
    ): number => {
        const SmsMessagePriceUSD = 0.082
        const SmsCharactersPerMessage = 160
        const EstimationRatio = 1.1
        const groupNameLength = `[${groupName}]`.length
        return Math.round(
            Math.ceil((groupNameLength + messageText.length) / SmsCharactersPerMessage) *
                membersCount *
                SmsMessagePriceUSD *
                EstimationRatio,
        )
    }

    private getSelectedChannelsList(): string {
        const { message } = this.props
        return message.channels.map(enumToName(AosMessageChannel)).map(channelTitle).join(', ')
    }

    private hasSmsChannel() {
        return this.props.message.channels.includes(AosMessageChannel.Sms)
    }
}

const Cost = styled(Text)`
    height: 40px;
    background-color: ${Color.Yellow};
`

const ProgressBox = styled(Text)`
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${Color.White};
    margin-top: 15px;
`
