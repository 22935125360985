import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { runwayConfiguration } from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    isRunwayActive,
    isRunwayMode,
    Runway,
    RunwayCombination,
    RunwayMode,
} from 'aos-services/src/services/runways/types/RunwayMode'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { RunwayBottomSection } from './RunwayBottomSection'
import { RunwayTopSection } from './RunwayTopSection'

export class RunwaysGraph extends PureComponent<RunwayContentProps> {
    public render() {
        const combination = this.props.combination
        const { runways, height, width, transform } = runwayConfiguration
        const runwayCodes = runwayConfiguration.runways.map(q => q.values)
        const activeRunways = this.getActiveRunways(...runwayCodes)
        const labels = this.getRunwayTooltipLabels(...runwayCodes)
        const activeClass = 'runway-active'
        const inactiveClass = 'runway-inactive'

        return (
            <svg
                viewBox={`0 0 ${width} ${height}`}
                style={{
                    backgroundColor: Color.GraphBackground,
                    flex: 1,
                    marginLeft: -20,
                    marginRight: -20,
                }}
            >
                <style>
                    {
                        // tslint:disable-next-line: jsx-use-translation-function
                        `.active {font: normal 10px montserrat; fill:${Color.TextPrimary} }
                        .inactive {font: normal 10px montserrat; fill:${Color.MenuButtonColor} }
                        .runway-active { fill:${Color.MenuButtonColor} }
                        .runway-inactive { fill:${Color.GraphInactiveColor} }
                    `
                    }
                </style>
                <g
                    stroke={Color.BackgroundDark}
                    fill='none'
                    fillRule='evenodd'
                    transform={transform}
                >
                    {runways.map((runway, index) => {
                        return (
                            <Tooltip
                                key={index}
                                body={translate(labels[index], { nr: `R${index + 1}` })}
                                placement='topRight'
                                withArrow
                            >
                                <path
                                    id={`r${index + 1}`}
                                    d={runway.path}
                                    className={activeRunways[index] ? activeClass : inactiveClass}
                                />
                            </Tooltip>
                        )
                    })}
                </g>
                {runways.map(runway => {
                    return (
                        <>
                            <RunwayTopSection
                                offset={runway.labelPlacement.top}
                                modes={combination[runway.values[0]]}
                                name={runway.values[0]}
                                transform={runway.labelPlacement.top.transform}
                            />
                            <RunwayBottomSection
                                offset={runway.labelPlacement.bottom}
                                modes={combination[runway.values[1]]}
                                name={runway.values[1]}
                                transform={runway.labelPlacement.bottom.transform}
                            />
                        </>
                    )
                })}
            </svg>
        )
    }

    private getActiveRunways(...runways: Runway[][]) {
        const { combination } = this.props
        return runways.map((runway: [Runway, Runway]) => isRunwayActive(combination, runway))
    }

    private getRunwayTooltipLabels(...runways: Runway[][]) {
        const { combination } = this.props
        const base = 'dashboard.runway.tooltip.'

        return runways.map((runway: [Runway, Runway]) => {
            const isArrival = isRunwayMode(combination, runway, RunwayMode.ARRIVAL)
            const isDeparture = isRunwayMode(combination, runway, RunwayMode.DEPARTURE)
            const isConditional = isRunwayMode(combination, runway, RunwayMode.CONDITIONAL)

            if (isConditional) {
                return base + 'conditionally-open'
            }

            if (isArrival || isDeparture) {
                const label = base + 'active.'

                return (
                    label +
                    (isArrival && isDeparture
                        ? 'arrival-and-departure'
                        : isArrival
                        ? 'arrival'
                        : 'departure')
                )
            }

            return base + 'inactive'
        })
    }
}

interface RunwayContentProps {
    combination: RunwayCombination
}
