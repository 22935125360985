import { cx } from 'aos-components/src/components/base/ClassNames'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { partitionGroupList } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { MainPanelWithHeader } from 'aos-ui/src/components/container/MainPanelWithHeader'
import { PanelWithHeaderVariant } from 'aos-ui/src/components/container/PanelWithHeader'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { Searchable } from 'aos-ui/src/components/search/Searchable'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { GroupListItem } from './GroupListItem'

export class GroupList extends PureComponent<GroupListProps> {
    public render() {
        return (
            <MainPanelWithHeader
                variant={PanelWithHeaderVariant.Std}
                header={this.renderHeader()}
                seleniumLocation='group-manager'
            >
                {this.renderContent()}
            </MainPanelWithHeader>
        )
    }

    private renderHeader() {
        return (
            <HeaderContainer
                title={translate('group-manager.title')}
                rightButton={this.renderMore()}
            />
        )
    }

    private renderMore() {
        return (
            <More iconVariant={IconVariant.Smart}>
                <MoreItem onClick={this.props.addGroup}>
                    {translate('group-manager.add-group')}
                </MoreItem>
                <MoreItem
                    onClick={this.props.downloadGroups}
                    disabled={this.props.downloadGroupsDisabled}
                >
                    {translate('group-manager.download-group')}
                </MoreItem>
            </More>
        )
    }

    private renderContent() {
        const collapsed = !this.props.isSystemGroupsSectionExpanded
        return (
            <Searchable list={this.props.groups} extractText={item => item.name}>
                {(filteredGroups, query, setQuery) => {
                    const [predefinedGroups, customGroups] = partitionGroupList(filteredGroups)

                    return (
                        <Box className={cx('group-list', { 'group-list--collapsed': collapsed })}>
                            {this.renderSearchInput(query, setQuery)}
                            <Box className='group-list__section group-list__section--collapsable'>
                                {predefinedGroups.map(this.renderItem)}
                            </Box>
                            <Box className='group-list__section'>
                                {!query && this.renderSeparator(collapsed)}
                                {customGroups.map(this.renderItem)}
                            </Box>
                        </Box>
                    )
                }}
            </Searchable>
        )
    }

    private renderSearchInput(query: string, setQuery: (text: string) => void) {
        return (
            <Box
                className='selectable-item'
                style={{ borderBottom: `1px solid ${Color.ChartBase}` }}
                paddingVertical={20}
                paddingHorizontal={30}
            >
                <CleanableInput
                    type='text'
                    variant={ThemeVariant.Black}
                    placeholder={translate('group-manager.search.placeholder')}
                    value={query}
                    leftSvg={SvgIcon.SearchWhite}
                    onChangeText={e => setQuery(e || '')}
                    small
                />
            </Box>
        )
    }

    private renderSeparator(collapsed: boolean) {
        return (
            <SeparatorButton onClick={this.props.toggleSystemGroups}>
                <Icon
                    iconSize={BlockSize.XTiny}
                    iconVariant={IconVariant.White}
                    svg={collapsed ? SvgIcon.Expand : SvgIcon.Collapse}
                />
            </SeparatorButton>
        )
    }

    private renderItem = (item: AosUserGroup, index: number) => (
        <GroupListItem
            key={index}
            group={item}
            isSelected={item === this.props.selectedGroup}
            onSelect={this.props.onSelect}
            onRemove={this.props.removeUserGroup}
            onRename={this.props.renameUserGroup}
            onEditNotificationSettings={this.props.editNotificationSettings}
        />
    )
}

const SeparatorButton = styled(Button)`
    width: 100%;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Color.ChartBase};

    :hover {
        opacity: 0.4;
    }
`

interface GroupListProps {
    groups: AosUserGroup[]
    selectedGroup?: AosUserGroup
    downloadGroupsDisabled: boolean
    isSystemGroupsSectionExpanded: boolean
    onSelect(v: AosUserGroup): void
    addGroup(): void
    downloadGroups(): void
    removeUserGroup(v: AosUserGroup): void
    renameUserGroup(v: AosUserGroup): void
    editNotificationSettings(v: AosUserGroup): void
    toggleSystemGroups(): void
}
