import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { helpConfiguration } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { lazy } from 'react'

const title = (suffix: string) => translate(`dashboard.help.runways.${suffix}`)
const note = (suffix: string) => translate(`dashboard.help.runways.${suffix}.note`)

export const getRunwaysHelpConfig = (): HelpModalConfig => ({
    title: translate('dashboard.help.runways.title'),
    items: helpConfiguration.runways.map(item => ({
        title: title(item.title),
        note: item.note ? note(item.title) : undefined,
        renderer: lazy(() => import(`${item.path}`)),
    })),
})
