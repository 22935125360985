import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface StatusTileProps {
    status?: Status
    title: ReactNode
    footer?: ReactNode
    children: ReactNode
}

export const StatusTile: FC<StatusTileProps> = ({
    status = 'neutral',
    title,
    children,
    footer,
}) => {
    return (
        <Container status={status}>
            <Box
                bg={Color.BackgroundDark}
                paddingHorizontal={20}
                paddingVertical={24}
                justify='flex-start'
                alignItems='center'
                row
            >
                {title}
            </Box>

            {children}
            {footer && (
                <Box
                    bg={Color.BackgroundDark}
                    paddingHorizontal={20}
                    paddingVertical={24}
                    justify='flex-start'
                    alignItems='center'
                    row
                >
                    {footer}
                </Box>
            )}
        </Container>
    )
}

const statuses = {
    ok: Color.Green,
    warn: Color.Orange,
    neutral: Color.StatusTileNeutral,
}

type Status = keyof typeof statuses

const Container = styled(Box)<{ status: Status }>`
    border: 1px solid ${Color.ChartBase};
    border-left: 4px solid ${p => statuses[p.status]};
`
