import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { runwayConfiguration } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { RunwayMode } from 'aos-services/src/services/runways/types/RunwayMode'
import { Box } from 'aos-ui/src/components/base/Box'
import { LegendItem, Orientation } from 'aos-ui/src/components/ui/LegendItem'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import { values } from 'lodash'
import React, { PureComponent } from 'react'

export class RunwaysLegend extends PureComponent {
    public render() {
        return (
            <Box>
                {this.canRender(RunwayMode.DEPARTURE) &&
                    this.renderItem(
                        Color.RunwayLegendDeparture,
                        translate('dashboard.runways.legend.departures'),
                    )}
                {this.canRender(RunwayMode.CONDITIONAL) &&
                    this.renderItem(
                        Color.Primary,
                        translate('dashboard.runways.legend.conditional'),
                        LegendDotVariant.Stroke,
                    )}
                {this.canRender(RunwayMode.ARRIVAL) &&
                    this.renderItem(Color.Orange, translate('dashboard.runways.legend.arrivals'))}
            </Box>
        )
    }

    private canRender = (state: string) => values(runwayConfiguration.allowedStates).includes(state)

    private renderItem = (color: Color, label: string, variant?: LegendDotVariant) => (
        <LegendItem
            dotConfig={{
                size: BlockSize.Small,
                mainColor: color,
                variant,
            }}
            orientation={Orientation.Horizontal}
            marginBottom={8}
        >
            {label}
        </LegendItem>
    )
}
