import {
    operationalForecastModeColors,
    OperationalItem,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface OperationalForecastListItemProps {
    item: OperationalItem
}

export const OperationalForecastListItem: FC<OperationalForecastListItemProps> = ({ item }) => (
    <Box paddingBottom={20} marginLeft={20}>
        <Box row alignItems='flex-start' paddingLeft={12}>
            <ColorBorder style={{ borderLeftColor: operationalForecastModeColors[item.mode] }} />
            <Text size={12} marginLeft={20}>
                {item.title}
            </Text>
        </Box>
        <Description
            color={Color.TextSecondary}
            size={12}
            lineHeight='standard'
            paddingLeft={12}
            marginLeft={20}
        >
            {item.description}
        </Description>
    </Box>
)

const ColorBorder = styled(Box)`
    height: 20px;
    line-height: 20px;
    border-left-width: 2px;
    border-left-style: solid;
`

const Description = styled(Text)`
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: transparent;
`
