import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { severityColorsMapping } from 'aos-services/src/services/common/types/AosSeverity'
import { assigneesLabel } from 'aos-services/src/services/tasks/types/task/BaseTask'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { translateTaskTitle } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import { DarkListItemIconsSection } from 'aos-ui/src/components/darkListItem/DarkListItemIconsSection'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { TaskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import React, { FC } from 'react'

import { taskLogTypes } from './TaskLastLogTypes'

interface TaskCardProps {
    task: TaskSnapshot
    onOpen(): void
}

export const TaskCard: FC<TaskCardProps> = ({ task, onOpen }) => {
    const LastLogTypeComponent = task.lastLogType && taskLogTypes[task.lastLogType]

    return (
        <DarkListItem
            borderColor={severityColorsMapping[task.severity]}
            onClick={onOpen}
            cursor='pointer'
            lightBg
        >
            <Box paddingVertical={16} paddingLeft={20} paddingRight={16} columnGap={16} row>
                <TaskIcon category={task.category} iconVariant={IconVariant.White} />

                <Box flex={2}>
                    <Text
                        size={14}
                        color={task.overdue ? Color.Yellow : Color.White}
                        overflow='hidden'
                        textNoWrap
                        ellipsis
                    >
                        {task.title}
                    </Text>
                    {task.assignees.length > 0 && (
                        <Text size={12} color={Color.TextSecondary} paddingTop={6}>
                            {assigneesLabel(task.assignees)}
                        </Text>
                    )}
                </Box>

                {task.hasRecurrence && (
                    <Icon
                        svg={SvgIcon.TaskRecurrence}
                        iconVariant={IconVariant.White}
                        iconSize={BlockSize.Std}
                    />
                )}

                <Box minWidth={100}>
                    <BorderedChip>{translateTaskTitle(task.status)}</BorderedChip>
                    <Text size={12} color={Color.White} paddingTop={6}>
                        {formatCalendarTime(task.startTime)}
                    </Text>
                </Box>

                <DarkListItemIconsSection
                    config={[
                        [SvgIcon.Pictures, task.attachmentsLength],
                        [SvgIcon.Location, task.hasLocation],
                        [SvgIcon.Checklist, task.hasChecklist],
                    ]}
                />
            </Box>

            {LastLogTypeComponent && <LastLogTypeComponent task={task} />}
        </DarkListItem>
    )
}
