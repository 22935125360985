import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Pipe } from 'aos-ui/src/components/text/Pipe'
import { TextWithLinks } from 'aos-ui/src/components/text/TextWithLinks'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, useMemo } from 'react'
import styled from 'styled-components'

import { ThemeVariant } from '../base/ThemeVariant'
import { SvgIcon } from '../svg/SvgIcon'
import { messageChannelIcon } from './MessageChannelIcon'

interface MessageBubbleProps {
    text: string
    recipients: string
    time: string
    channels: AosMessageChannel[]
    variant: ThemeVariant
    messageBackgroundColor?: Color
    isAutomatic: boolean
}

export const MessageBubble: FCWithChildren<MessageBubbleProps> = (props: MessageBubbleProps) => {
    const { text, recipients, time, channels, variant, messageBackgroundColor, isAutomatic } = props
    const backgroundColor = messageBackgroundColor ? messageBackgroundColor : Color.White

    const fontColor = useMemo(
        () => (variant === ThemeVariant.Black ? Color.White : Color.Black),
        [variant],
    )

    const header = (
        <Box row justify='space-between' paddingBottom={12}>
            <Text selectable color={fontColor} size={12} lineHeight='standard'>
                <Text as='span' size={12} color={fontColor}>
                    {recipients}
                </Text>
                <Pipe />
                {time}
            </Text>
            <Box>
                {channels.map((c: AosMessageChannel, i: number) => (
                    <Icon
                        key={i}
                        iconSize={BlockSize.Small}
                        iconVariant={IconVariant.BlackGrey}
                        svg={messageChannelIcon(c)}
                    />
                ))}
            </Box>
        </Box>
    )
    return (
        <Box row alignContent='flex-start' columnGap={4} flex={1}>
            {isAutomatic ? (
                <Icon color={fontColor} svg={SvgIcon.AutomaticFeed} />
            ) : (
                <Icon color={fontColor} svg={SvgIcon.Users} />
            )}
            <Box width='90%' flex={1}>
                {header}

                <MessageBubbleBox
                    padding={20}
                    lineHeight='large'
                    weight='light'
                    size={14}
                    color={Color.TimelineBackground}
                    selectable
                    whiteSpace='pre-line'
                    backgroundColor={backgroundColor}
                >
                    <TextWithLinks text={text} />
                </MessageBubbleBox>
            </Box>
        </Box>
    )
}

const MessageBubbleBox = styled(Text)<{ backgroundColor: Color }>`
    position: relative;
    background: ${p => p.backgroundColor};
    word-wrap: break-word;
    &::after {
        display: block;
        position: absolute;
        top: -8px;
        left: 0;
        width: 0;

        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent ${p => p.backgroundColor};
        content: '';
    }
`
