import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../../StatusDashboardItemConfig'
import { UpcomingTasks } from './UpcomingTasks'

export const getUpcomingTasksConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 8, h: 10 },
        },
    },
    renderer: UpcomingTasks,
})
