import { createSelector } from 'reselect'

import {
    dashboardGridItems,
    dashboardGridItemsNetwork,
} from '../../services/flightInformation/types/AosAirport'
import { defaultItemStatuses } from '../../services/statusDashboard/types/DashboardItemStatus'
import { widgetHealthsService } from '../../services/widgetHealths/widgetHealthsService'
import { isAirportNetworkSelector } from '../auth/state'
import { currentTimeSelector } from '../common/selectors'
import { BaseStatusDashboardStateAware } from './state'

export const dashboardStatusesSelector = createSelector(
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.statuses,
    statuses => ({ ...defaultItemStatuses, ...statuses }),
)

export const dashboardHealthsSelector = createSelector(
    currentTimeSelector,
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.healths,
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.healthsForAirport,
    (time, healths, healthsForAirport) =>
        widgetHealthsService.getHealths(healths, healthsForAirport, time),
)

export const availableItems = dashboardGridItems

export const availableItemsForNetwork = dashboardGridItemsNetwork

export const availableItemTypesSelector = createSelector(
    isAirportNetworkSelector,
    isAirportNetwork => {
        if (isAirportNetwork) {
            return availableItemsForNetwork
        }
        return availableItems
    },
)
