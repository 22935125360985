import { xor } from 'lodash'
import React, { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

import { Color } from '../../../../../aos-ui-common/src/styles/Color'
import { IdAware } from '../../base/IdAware'
import { ThemeVariant } from '../../base/ThemeVariant'
import { Checkbox } from '../checkbox/Checkbox'
import { BaseDropdown, DropdownItemProps } from './base/BaseDropdown'
import {
    dropdownHeight,
    DropdownItemWrapper,
    DropdownLabel,
    dropdownSizes,
} from './base/DropdownContent'
import { DropdownPortalType } from './base/DropdownPortal'
import { DropdownVariant } from './base/DropdownVariant'
import { DropdownWidth } from './base/DropdownWidth'

const SplitterComponent = styled.div`
    height: 1px;
    background-color: ${Color.TextSecondary};
`

export interface CheckboxDropdownProps<T> extends IdAware {
    items: T[]
    value: T[]
    allContent: ReactNode

    partialContent: (selectedItems: T[]) => ReactNode
    variant?: DropdownVariant
    width?: DropdownWidth | number
    small?: boolean
    outlined?: boolean
    splitterPosition?: number
    disabled?: boolean

    portalType?: DropdownPortalType
    toggleVisible?: boolean

    preventEmpty?: boolean
    onChange(v: T[]): void

    valueRenderer(v: T): ReactNode
    clearAction?(): void
}

export const CheckboxDropdown = forwardRef(
    <T extends any>(
        props: CheckboxDropdownProps<T>,
        ref:
            | ((instance: HTMLDivElement | null) => void)
            | React.RefObject<HTMLDivElement>
            | null
            | undefined,
    ) => {
        const {
            id,
            items,
            value,
            variant = DropdownVariant.White,
            valueRenderer,
            width,
            onChange,
            clearAction,
            small,
            allContent,
            partialContent,
            toggleVisible,
            portalType,
            preventEmpty = false,
            outlined,
            splitterPosition,
            disabled,
        } = props

        const valueItems = preventEmpty && value.length === 0 ? items : value
        const content =
            items.length === valueItems.length ? (
                <DropdownLabel size={12} as='span' variant={variant} isSelected>
                    {allContent}
                </DropdownLabel>
            ) : (
                <DropdownLabel size={12} as='span' variant={variant} isSelected>
                    {partialContent(valueItems)}
                </DropdownLabel>
            )
        const height = dropdownHeight(variant, small)
        const padding = variant === DropdownVariant.BlackGrey ? 12 : 8
        const checkboxVariant =
            variant === DropdownVariant.White ? ThemeVariant.White : ThemeVariant.Black
        const clearVisible = preventEmpty
            ? items.length !== valueItems.length
            : valueItems.length > 0
        const onChangeValue = (v: T) => {
            if (!disabled) {
                const newValue = xor<T>(value, [v])
                const changeValue = preventEmpty && newValue.length === items.length ? [] : newValue
                if (onChange) {
                    if (preventEmpty && changeValue.length === 0) {
                        onChange(items)
                    } else {
                        onChange(changeValue)
                    }
                }
            }
        }

        const renderCheckbox = (p: DropdownItemProps<T> & { children?: ReactNode }) => {
            const isSelected = valueItems.includes(p.v)
            return (
                <DropdownItemWrapper
                    ref={ref}
                    row
                    onClick={p.onClick}
                    variant={p.variant}
                    isSelected={isSelected}
                    paddingHorizontal={padding}
                    itemHeight={dropdownSizes[height].itemHeight}
                >
                    <Checkbox
                        position='left'
                        checked={isSelected}
                        variant={checkboxVariant}
                        fontSize={12}
                        disabled={disabled}
                    >
                        <DropdownLabel
                            flex={1}
                            paddingLeft={8}
                            size={12}
                            as='span'
                            variant={variant}
                            isSelected={isSelected}
                        >
                            {valueRenderer(p.v)}
                        </DropdownLabel>
                    </Checkbox>
                </DropdownItemWrapper>
            )
        }

        const renderItem = (p: DropdownItemProps<T> & { children?: ReactNode }) => {
            if (splitterPosition !== undefined && p.index === splitterPosition - 1) {
                return (
                    <>
                        {renderCheckbox(p)}
                        <SplitterComponent />
                    </>
                )
            } else {
                return renderCheckbox(p)
            }
        }

        return (
            <BaseDropdown
                items={items}
                variant={variant}
                id={id}
                clearAction={clearAction}
                width={width}
                height={height}
                content={content}
                cleanVisible={clearVisible}
                onItemClick={val => onChangeValue(val)}
                paddingHorizontal={padding}
                type={portalType}
                toggleVisible={toggleVisible}
                outlined={outlined}
                ItemRenderer={q => renderItem(q)}
            />
        )
    },
)
