import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, FocusEvent, forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { Box, MarginBoxProps } from '../../base/Box'
import { ThemeVariant } from '../../base/ThemeVariant'
import { CleanValueButton } from '../../buttons/CleanValueButton'
import { Icon, IconVariant } from '../../svg/Icon'
import { Input } from './Input'

export interface CleanableInputProps extends MarginBoxProps {
    type?: string
    onChangeText?(value: string): void
    onFocus?(e: FocusEvent<HTMLInputElement>): void
    onBlur?(e: FocusEvent<HTMLInputElement>): void
    value?: string | number | null
    placeholder?: string
    leftSvg?: Svg
    svg?: Svg
    variant?: ThemeVariant
    readOnly?: boolean
    onClick?(): void
    small?: boolean
    hideClean?: boolean
    bold?: boolean
    onClear?: () => void
    onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void
    maxLength?: number
}

const variantToIconVariant: Record<ThemeVariant, IconVariant> = {
    [ThemeVariant.White]: IconVariant.Grey,
    [ThemeVariant.Black]: IconVariant.BlackGrey,
}

const variantToLeftIconVariant: Record<ThemeVariant, IconVariant> = {
    [ThemeVariant.White]: IconVariant.Grey,
    [ThemeVariant.Black]: IconVariant.White,
}

export const CleanableInput: FCWithChildren<CleanableInputProps> = forwardRef<
    HTMLInputElement,
    CleanableInputProps
>(
    (
        {
            type = 'text',
            variant = ThemeVariant.White,
            value,
            onChangeText,
            onFocus,
            onBlur,
            placeholder,
            svg,
            leftSvg,
            onClick,
            small,
            hideClean = false,
            bold,
            onClear,
            maxLength,
            ...margins
        },
        forwardRef,
    ) => {
        const rightIcon = !!svg
        const leftIcon = !!leftSvg
        const iconVariant = variantToIconVariant[variant]
        const leftIconVariant = variantToLeftIconVariant[variant]

        const updateValue = (value: string) => onChangeText?.(value)
        const clean = () => {
            updateValue('')
            onClear && onClear()
        }

        const iconVariantForBold = bold ? IconVariant.Black : undefined
        const blackColorForBold = bold ? Color.Black : Color.Grey2

        return (
            <Box relative>
                <CleanInput
                    ref={forwardRef}
                    small={small}
                    onClick={onClick}
                    rightIcon={rightIcon}
                    leftIcon={leftIcon}
                    type={type}
                    value={value || ''}
                    variant={variant}
                    placeholder={placeholder}
                    onChange={e => {
                        updateValue(e.target.value)
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    inputColor={blackColorForBold}
                    maxLength={maxLength}
                    {...margins}
                />
                {!!value && !hideClean && (
                    <CleanButton
                        rightIcon={rightIcon}
                        onClick={clean}
                        iconVariant={iconVariantForBold}
                    />
                )}
                {!!svg && (
                    <CleanIcon svg={svg} iconVariant={iconVariant} iconSize={BlockSize.Std} />
                )}
                {!!leftSvg && (
                    <LeftIcon
                        svg={leftSvg}
                        iconVariant={leftIconVariant}
                        iconSize={BlockSize.Std}
                        color={blackColorForBold}
                    />
                )}
            </Box>
        )
    },
)

const CleanButton = styled(CleanValueButton)<{ rightIcon: boolean }>`
    position: absolute;
    top: 50%;
    right: ${p => (p.rightIcon ? 36 : 8)}px;
    transform: translateY(-50%);
`

const CleanInput = styled(Input)<{ rightIcon: boolean; leftIcon: boolean; inputColor?: string }>`
    color: ${p => p.inputColor};
    padding-right: ${p => (p.rightIcon ? 60 : 36)}px;
    ${p =>
        p.leftIcon &&
        css`
            padding-left: 35px;
        `}
    ::placeholder {
        color: ${p => p.inputColor};
    }
`

const CleanIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
`

const LeftIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
`
