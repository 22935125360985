import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Text } from '../base/Text'

interface RoundTabItemProps {
    active?: boolean
    onClick?(): void
}

export const RoundTabItem: FCWithChildren<PropsWithChildren<RoundTabItemProps>> = ({
    active,
    children,
    onClick,
}) => (
    <RoundTabItemWrapper
        active={active}
        size={12}
        cursor='pointer'
        paddingHorizontal={16}
        onClick={onClick}
    >
        {children}
    </RoundTabItemWrapper>
)

const RoundTabItemWrapper = styled(Text)<RoundTabItemProps>`
    height: 28px;
    line-height: 28px;
    white-space: nowrap;
    border-radius: 14px;
    color: ${p => (p.active ? Color.White : Color.WidgetSecondary)};
    background-color: ${p => (p.active ? Color.BackgroundPrimary : Color.Transparent)};
`
