import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { noop } from 'lodash'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { CleanableInput } from '../input/CleanableInput'

interface LocationProps {
    location?: LonLat
    onClear?(): void
    onEdit?(): void
}

export const Location: FCWithChildren<LocationProps> = props => {
    const text = props.location ? translate('location.location-added') : ''
    const rightSvg = props.location ? undefined : SvgIcon.Location
    const leftSvg = props.location ? SvgIcon.Done : undefined
    const hintText = props.location ? translate('location.location-hint') : ''
    return (
        <>
            <CleanableInput
                readOnly
                value={text}
                placeholder={translate('location.select-on-map')}
                onChangeText={() => (props.onClear || noop)()}
                onClick={props.onEdit}
                svg={rightSvg}
                leftSvg={leftSvg}
                bold={!!props.location}
            />
            {props.location && <HintText>{hintText}</HintText>}
        </>
    )
}

const HintText = styled(Text)`
    font-weight: 400;
    color: ${Color.Grey2};
    font-size: 12px;
    margin-top: 8px;
`
