import { Box } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Icon, IconVariant } from '../svg/Icon'

interface PanelPlaceholderProps extends SvgProps, SeleniumProps {
    label: string
    inline?: boolean
    raw?: boolean
    onClick?(): void
}

export const PanelPlaceholder: FCWithChildren<PanelPlaceholderProps> = ({
    inline,
    onClick,
    svg,
    seleniumLocation,
    label,
    raw = true,
}) => (
    <PanelPlaceholderWrapper pointer={!!onClick} onClick={onClick}>
        <PanelPlaceholderIcon
            svg={svg}
            inline={inline}
            iconVariant={IconVariant.GreyTxt}
            raw={raw}
        />
        <Text
            textAlign='center'
            color={Color.TextSecondary}
            size={14}
            data-test-id={seleniumLocation || 'panel-placeholder'}
        >
            {label}
        </Text>
    </PanelPlaceholderWrapper>
)

const PanelPlaceholderWrapper = styled(Box)`
    opacity: 0.5;
`

const PanelPlaceholderIcon = styled(Icon)<{ inline?: boolean }>`
    display: block;
    width: 52px;
    height: 52px;
    margin: ${p => (p.inline ? '20px auto' : '120px auto 20px')};
`
