import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Cdm } from './Cdm'
import { CdmTile } from './CdmTile'

export const getCdmConfig = (): StatusDashboardItemConfig => ({
    sizes: {
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 12 },
            maxSize: { w: 12, h: 20 },
        },
    },

    renderer: Cdm,
    tileRenderer: CdmTile,
})
