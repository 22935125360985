import { dateTime } from 'aos-helpers/src/helpers/Time'

import {
    OperationalForecastRepository,
    operationalForecastRepository,
} from '../../dataaccess/operationalForecast/operationalForecastRepository'
import { OperationalForecastDto } from '../../dataaccess/operationalForecast/types/OperationalForecastDto'
import { AosAirport } from '../flightInformation/types/AosAirport'
import { DashboardItemMode } from '../statusDashboard/types/DashboardItemMode'
import { DashboardItemType } from '../statusDashboard/types/DashboardItemType'
import { WidgetStatesService, widgetStatesService } from '../widgetStates/widgetStatesService'
import {
    emptyForecast,
    OperationalForecast,
    operationalHighestMode,
    OperationalItem,
} from './types/OperationalForecast'

class OperationalForecastService {
    private repository: OperationalForecastRepository
    private statesService: WidgetStatesService

    constructor(repository: OperationalForecastRepository, statesService: WidgetStatesService) {
        this.repository = repository
        this.statesService = statesService
    }

    public itemForecastFromDto = (dto: OperationalForecastDto | null): OperationalForecast => {
        if (!dto) {
            return emptyForecast
        }
        return {
            lastUpdated: dateTime(dto.lastUpdated),
            items: dto.items?.map(i => i as OperationalItem) || [],
        }
    }

    public save = (location: AosAirport, forecast: OperationalForecast) => {
        return this.repository
            .updateOperationalForecast(location, {
                items: forecast.items,
                lastUpdated: forecast.lastUpdated?.valueOf() || dateTime().valueOf(),
            })
            .then(() =>
                this.statesService.updateStatus(
                    location,
                    DashboardItemType.OperationalForecast,
                    operationalHighestMode(forecast.items) as string as DashboardItemMode,
                ),
            )
    }
}

export const operationalForecastService = new OperationalForecastService(
    operationalForecastRepository,
    widgetStatesService,
)
