import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    getAirportByCode,
    getFormattedAirportCode,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { BaggageIndicatorProgress } from 'aos-services/src/services/flightInformation/types/AosFlightBeltStatus'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { TypedColumn } from 'aos-ui/src/components/table/TypedColumn'
import { EnumValues } from 'enum-values'
import React from 'react'
import { connect } from 'react-redux'

import {
    changeSortingAction,
    lockNowAction,
    updateArrivalsFiltersAction,
} from '../../core/flightInformation/actions'
import { FlightFilters } from '../../core/flightInformation/flight/flightFiltersState'
import { FlightInfoField } from '../../core/flightInformation/flight/FlightInfoField'
import {
    arrivalTableSelector,
    FlightInfoTableSelectorState,
} from '../../core/flightInformation/flight/flightSelectors'
import { FlightSort } from '../../core/flightInformation/state'
import { AreaBeltCell } from './cells/AreaBeltCell'
import { BeltBaggageCell } from './cells/BeltBaggageCell'
import { FlightNumberCell } from './cells/FlightNumberCell'
import { GateCell } from './cells/GateCell'
import { NatureOfFlightCell } from './cells/NatureOfFlightCell'
import { ParkCell } from './cells/ParkCell'
import { RouteCell } from './cells/RouteCell'
import { AbstractFlightInfoTableClass, FlightInfoTableDispatchProps } from './FlightInfoTable'
import { FlightTableHeader, FlightTableHeaderSort } from './header/FlightTableHeader'
import { TableDropdown } from './partial/TableDropdown'

class FlightTableArrivalsClass extends AbstractFlightInfoTableClass {
    private translateBagProgress = translateEnum<BaggageIndicatorProgress>(
        BaggageIndicatorProgress,
        'flight-information.bag-progress-dropdown',
    )

    public type = FlightListType.Arrivals

    public renderColumns(): JSX.Element[] {
        return [
            this.textColumn({
                dataKey: FlightInfoField.paxTotal,
            }),
            this.timeColumn({
                dataKey: FlightInfoField.sdt,
                caption: 'sta',
            }),
            this.checkboxDropdownColumn(
                {
                    dataKey: FlightInfoField.hapt,
                    caption: 'apt',
                },
                {
                    cellRenderer: ({ rowData }) =>
                        getFormattedAirportCode(getAirportByCode(rowData.hapt)),
                },
                (code: string) => getFormattedAirportCode(getAirportByCode(code)),
            ),
            this.checkboxDropdownColumn(
                { dataKey: FlightInfoField.naflt },
                { cellRenderer: ({ rowData }) => <NatureOfFlightCell row={rowData} /> },
            ),
            this.textColumn(
                {
                    dataKey: FlightInfoField.fltnr,
                    width: 90,
                    maxWidth: 120,
                },
                {
                    cellRenderer: ({ rowData }) => (
                        <FlightNumberCell fltnr={rowData.nr} cflight={rowData.cflight} />
                    ),
                },
            ),
            this.checkboxDropdownColumn(
                {
                    dataKey: FlightInfoField.routes,
                    caption: 'origin',
                    width: 50,
                    maxWidth: 100,
                },
                { cellRenderer: ({ rowData }) => <RouteCell row={rowData} /> },
            ),
            this.textColumn({
                dataKey: FlightInfoField.callsign,
                width: 80,
                maxWidth: 120,
            }),
            this.textColumn({
                dataKey: FlightInfoField.acreg,
                width: 70,
                maxWidth: 120,
            }),
            this.checkboxDropdownColumn({
                dataKey: FlightInfoField.actype,
                width: 70,
                maxWidth: 120,
            }),
            this.handlColumn({
                width: 60,
                maxWidth: 120,
            }),
            this.textColumn(
                {
                    dataKey: FlightInfoField.park,
                    caption: 'stand',
                    width: 90,
                    maxWidth: 120,
                },
                { cellRenderer: ({ rowData }) => <ParkCell row={rowData} /> },
            ),
            this.textColumn(
                {
                    dataKey: FlightInfoField.gate,
                    width: 70,
                },
                { cellRenderer: ({ rowData }) => <GateCell row={rowData} /> },
            ),
            this.timeColumn({
                dataKey: FlightInfoField.est,
                caption: 'eldt',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.act,
                caption: 'aldt',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.eibt,
                caption: 'eibt',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.ablk,
                caption: 'aibt',
            }),
            this.checkboxDropdownColumn(
                {
                    dataKey: FlightInfoField.bltarea,
                    width: 70,
                    maxWidth: 100,
                },
                { cellRenderer: ({ rowData }) => <AreaBeltCell row={rowData} /> },
            ),
            this.bagColumn(),
            this.checkboxDropdownColumn({
                dataKey: FlightInfoField.door,
                width: 50,
            }),
            this.dropdownColumn({
                dataKey: FlightInfoField.rwy,
                width: 50,
            }),
            this.prmStatusColumn(),
        ]
    }

    protected override sortBy(sortKey: keyof FlightFilters): FlightTableHeaderSort {
        return {
            sortKey,
            sort: {
                key: this.props.sorting.field as FlightInfoField,
                direction: this.props.sorting.desc ? 'desc' : 'asc',
            },
            onChange: v =>
                this.props.changeSorting({
                    value: { field: v.key, desc: v.direction === 'desc' },
                    type: FlightSort.ARRIVALS,
                }),
        }
    }

    private bagColumn() {
        const { filtersVisible } = this.props.filters
        return (
            <TypedColumn<Flight, FlightInfoField.bag>
                {...this.defaultColumnProps}
                width={120}
                flexGrow={140}
                key={FlightInfoField.bag}
                dataKey={FlightInfoField.bag}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={this.sortBy(FlightInfoField.bag)}
                        caption='bag'
                        filter={
                            filtersVisible && (
                                <TableDropdown
                                    items={EnumValues.getValues(BaggageIndicatorProgress)}
                                    onChange={this.updateFilterHandler(FlightInfoField.bag)}
                                    value={this.props.filters.bag}
                                    valueRenderer={this.translateBagProgress}
                                />
                            )
                        }
                    />
                )}
                cellRenderer={({ rowData }) => (
                    <BeltBaggageCell row={rowData} now={this.props.now} />
                )}
            />
        )
    }
}

export const FlightTableArrivals = connect<
    FlightInfoTableSelectorState,
    FlightInfoTableDispatchProps
>(arrivalTableSelector, {
    updateFilters: updateArrivalsFiltersAction,
    lockNow: lockNowAction,
    changeSorting: changeSortingAction,
})(FlightTableArrivalsClass)
