import { Color } from 'aos-ui-common/src/styles/Color'
import { darken, lighten, rgba } from 'polished'
import styled, { CSSObject } from 'styled-components'

import { Box } from '../../../base/Box'
import { Text, textStyleBuilder } from '../../../base/Text'
import { boxShadow, ZIndex } from '../../../base/Theme'
import { IconVariant } from '../../../svg/Icon'
import { SvgIcon } from '../../../svg/SvgIcon'
import { DropdownHeight } from './DropdownHeight'
import { DropdownVariant } from './DropdownVariant'

interface DropdownStyle {
    borderRadius: number
    background: Color
    hoverColor: Color
    disabledColor?: Color

    color: Color
    selectedColor: Color

    toggleSvg: Svg
    toggleVariant: IconVariant
    separatorStyle: CSSObject
    placeholderStyle: CSSObject
    borderColor?: Color
}

interface DropdownSizeStyle {
    itemHeight: number
}

export const dropdownSizes: Record<DropdownHeight, DropdownSizeStyle> = {
    [DropdownHeight.Small]: {
        itemHeight: 28,
    },
    [DropdownHeight.Std]: {
        itemHeight: 32,
    },
    [DropdownHeight.Big]: {
        itemHeight: 40,
    },
}

export const dropdownHeight = (variant: DropdownVariant, small?: boolean) => {
    if ((variant !== DropdownVariant.White && variant !== DropdownVariant.Black) || small) {
        return DropdownHeight.Small
    }
    return small ? DropdownHeight.Std : DropdownHeight.Big
}

export const dropdownPadding = (variant: DropdownVariant) =>
    variant === DropdownVariant.BlackGrey ? 12 : 8

export const dropdownStyles: Record<DropdownVariant, DropdownStyle> = {
    [DropdownVariant.White]: {
        borderRadius: 5,
        background: Color.White,
        hoverColor: Color.Grey1,

        color: Color.Black,
        selectedColor: Color.Grey2,

        toggleSvg: SvgIcon.Chevron,
        toggleVariant: IconVariant.Grey,
        separatorStyle: {
            boxShadow: boxShadow.stdInset,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
        borderColor: Color.DisabledText,
    },
    [DropdownVariant.Black]: {
        borderRadius: 5,
        background: Color.DarkInput,
        hoverColor: lighten(0.05, Color.TimelineBackground) as Color,

        color: Color.TextSecondary,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Chevron,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.TimelineBackground)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [DropdownVariant.BlackGrey]: {
        borderRadius: 14,
        background: Color.BackgroundPrimary,
        hoverColor: lighten(0.05, Color.BackgroundPrimary) as Color,

        color: Color.WidgetSecondary,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Arrow,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.BackgroundPrimary)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [DropdownVariant.Red]: {
        borderRadius: 5,
        borderColor: Color.ProgressRed,
        background: Color.DarkRed,
        hoverColor: lighten(0.05, Color.DarkRed) as Color,
        disabledColor: Color.UnitTaskRed,

        color: Color.White,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Arrow,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.ProgressRed)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [DropdownVariant.Green]: {
        borderRadius: 5,
        borderColor: Color.ProgressGreen,
        background: Color.DarkGreen,
        hoverColor: lighten(0.05, Color.DarkGreen) as Color,
        disabledColor: Color.UnitTaskGreen,

        color: Color.White,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Arrow,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.ProgressGreen)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [DropdownVariant.Yellow]: {
        borderRadius: 5,
        borderColor: Color.ProgressYellow,
        background: Color.DarkYellow,
        hoverColor: lighten(0.05, Color.DarkYellow) as Color,
        disabledColor: Color.UnitTaskYellow,

        color: Color.White,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Arrow,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.ProgressYellow)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
    [DropdownVariant.Grey]: {
        borderRadius: 5,
        borderColor: Color.DisabledText,
        background: Color.Grey6,
        hoverColor: lighten(0.05, Color.Grey6) as Color,
        disabledColor: Color.UnitTaskBlack,

        color: Color.White,
        selectedColor: Color.White,

        toggleSvg: SvgIcon.Arrow,
        toggleVariant: IconVariant.White,
        separatorStyle: {
            borderTop: `1px solid ${darken(0.05, Color.Grey1)}`,
        },
        placeholderStyle: textStyleBuilder({ color: Color.Grey2, size: 12 }),
    },
}

interface DropdownContentProps {
    isOpen: boolean
    variant: DropdownVariant
    minHeight: DropdownHeight
    outlined?: boolean
    disabled?: boolean
}

export const DropdownTopContent = styled(Box)<DropdownContentProps>(
    ({ isOpen, variant, outlined, minHeight, disabled }) => {
        const itemStyles = dropdownStyles[variant]

        const styles: CSSObject = {
            borderRadius: itemStyles.borderRadius,
            cursor: disabled ? 'not-allowed' : 'pointer',
            background: disabled ? itemStyles.disabledColor : itemStyles.background,
            minHeight,
        }

        if (outlined && itemStyles.borderColor) {
            styles.border = `1px ${disabled ? 'dashed' : 'solid'} ${
                disabled ? darken(0.3)(itemStyles.borderColor) : itemStyles.borderColor
            }`
        }

        if (isOpen) {
            styles.borderBottomLeftRadius = 0
            styles.borderBottomRightRadius = 0
            styles.borderBottomWidth = 0
        }
        return styles
    },
)

interface DropdownBottomContentProps {
    variant: DropdownVariant
    minWidth?: number
    outlined?: boolean
}

export const DropdownBottomContent = styled(Box)<DropdownBottomContentProps>(
    ({ variant, outlined, minWidth }) => {
        const itemStyles = dropdownStyles[variant]

        let styles: CSSObject = {
            overflow: 'hidden',
            background: itemStyles.background,
            borderBottomLeftRadius: itemStyles.borderRadius,
            borderBottomRightRadius: itemStyles.borderRadius,
            minWidth,
        }

        if (outlined && itemStyles.borderColor) {
            styles.border = `1px solid ${itemStyles.borderColor}`
            styles.borderTopColor = rgba(itemStyles.borderColor, 0.5)
        } else {
            styles = { ...styles, ...itemStyles.separatorStyle }
        }
        return styles
    },
)

export const DropdownContent = styled(Box)<{ variant: DropdownVariant }>`
    z-index: ${ZIndex.DropdownMenu};
    border-radius: ${p => dropdownStyles[p.variant].borderRadius}px;
    background: ${p => dropdownStyles[p.variant].background};
    box-shadow: ${boxShadow.std};
`

export const DropdownItemWrapper = styled(Text)<{
    variant: DropdownVariant
    isSelected?: boolean
    itemHeight: number
    separator?: boolean
}>`
    height: ${p => p.itemHeight}px;
    flex-shrink: 0;
    cursor: ${p => (p.isSelected ? 'initial' : 'pointer')};
    border-top: ${p => (p.separator ? `1px solid ${Color.DisabledText}` : 'none')};
    :hover {
        background: ${p =>
            p.isSelected ? Color.Transparent : dropdownStyles[p.variant].hoverColor};
    }
`

// allows to pass isHover prop
export const DropdownItemWrapperHoverable = styled(Text)<{
    variant: DropdownVariant
    isSelected?: boolean
    isHovered?: boolean
    itemHeight: number
}>`
    height: ${p => p.itemHeight}px;
    flex-shrink: 0;
    cursor: ${p => (p.isSelected ? 'initial' : 'pointer')};
    background: ${p =>
        p.isSelected || !p.isHovered ? Color.Transparent : dropdownStyles[p.variant].hoverColor};
`

export const DropdownLabel = styled(Text)<{ variant: DropdownVariant; isSelected?: boolean }>`
    white-space: nowrap;
    color: ${p =>
        p.isSelected ? dropdownStyles[p.variant].selectedColor : dropdownStyles[p.variant].color};
`

export const DropdownPlaceholder = styled(Text)<{ variant: DropdownVariant }>(
    ({ variant }) => dropdownStyles[variant].placeholderStyle,
)

export const DropdownInput = styled.input<{ variant: DropdownVariant }>`
    width: 100%;
    background: transparent;
    color: ${p => dropdownStyles[p.variant].selectedColor};
    font-size: 12px;
    font-weight: 500;
    ::placeholder {
        ${p => dropdownStyles[p.variant].placeholderStyle};
    }
`
