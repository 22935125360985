import { BlockSize } from 'aos-helpers/src/helpers/Block'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'

import { Color } from '../../../../../aos-ui-common/src/styles/Color'
import { Box } from '../../base/Box'
import { IconButton } from '../../buttons/IconButton'
import { Header, HeaderVariant } from '../../header/Header'
import { IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'
import { ModalTitle } from './ModalTitle'

export interface ModalHeaderProps {
    title?: ReactNode
    closeAction(): void
    isNonDismissible?: boolean
}

export const ModalHeader: FCWithChildren<PropsWithChildren<ModalHeaderProps>> = ({
    title,
    closeAction,
    isNonDismissible,
}) => (
    <Header variant={HeaderVariant.Modal} paddingHorizontal={20} shadowed row>
        <ModalTitle>{title}</ModalTitle>
        {!isNonDismissible && (
            <Box shrink={0}>
                <IconButton
                    iconColor={Color.TextTheme}
                    onClick={closeAction}
                    iconSize={BlockSize.Small}
                    iconVariant={IconVariant.White}
                    svg={SvgIcon.Close}
                    seleniumLocation='modal-container-button-close'
                />
            </Box>
        )}
    </Header>
)
