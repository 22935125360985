import { BoxProps } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

interface SectionHeaderProps extends BoxProps {
    title: string
    numberNote?: number
}

/* eslint-disable react/jsx-no-literals */
export const SectionHeader: FCWithChildren<SectionHeaderProps> = ({
    title,
    numberNote,
    ...props
}) => (
    <Text
        color={Color.Black}
        paddingBottom={20}
        size={18}
        weight='regular'
        lineHeight='large'
        {...props}
    >
        {title}
        {numberNote !== undefined && (
            <Text as='span' color={Color.Black} size={18} weight='regular'>
                &nbsp;({numberNote})
            </Text>
        )}
    </Text>
)
