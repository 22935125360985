import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

interface GreyTextButtonProps extends ButtonProps {
    label: string
    size?: FormComponentSize
    bare?: boolean
}

export const GreyTextButton: FCWithChildren<GreyTextButtonProps> = ({
    size = FormComponentSize.Std,
    onClick,
    label,
    bare,
}) => (
    <ButtonWrapper onClick={onClick} formComponentSize={size} bare={bare}>
        <Text size={14} color={Color.Grey2} lineHeightPx={40}>
            {label}
        </Text>
    </ButtonWrapper>
)

const ButtonWrapper = styled(Button)<{ formComponentSize?: FormComponentSize; bare?: boolean }>`
    padding: 0 ${p => (p.bare ? 0 : p.formComponentSize)}px;
`
