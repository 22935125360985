import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    UnitAirportContact,
    UnitRow,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

interface ContactUnitRowProps {
    row: UnitRow
    style: React.CSSProperties
    isExpanded: boolean
    handleToggleExpand: (unitId: number) => void
    onEdit: (unit: UnitAirportContact) => void
    onDelete: (unit: UnitAirportContact) => void
    isAosAdmin: boolean
}
export const ContactUnitRow: FC<ContactUnitRowProps> = ({
    row,
    style,
    isExpanded,
    handleToggleExpand,
    onDelete,
    onEdit,
    isAosAdmin,
}) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <HoverableBox
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            rounded
            paddingHorizontal={16}
            row
            cursor='pointer'
            onClick={() => handleToggleExpand(row.unit.id)}
            style={{ ...style, height: 40, marginTop: 20 }}
        >
            <Box columnGap={8} row flex={1}>
                <IconButton
                    iconSize={BlockSize.XTiny}
                    iconVariant={IconVariant.White}
                    svg={isExpanded ? SvgIcon.Expand : SvgIcon.Collapse}
                />
                <Box columnGap={8} row>
                    <Text size={14}>{row.unit.name}</Text>
                    <Text size={14} color={Color.SecondaryText}>
                        {row.unit.description}
                    </Text>
                </Box>
            </Box>
            {isHovered && isAosAdmin && (
                <Box paddingRight={8} row columnGap={8}>
                    <IconButton
                        iconVariant={IconVariant.White}
                        svg={SvgIcon.EditDark}
                        onClick={e => {
                            e.stopPropagation()
                            onEdit(row.unit)
                        }}
                    />
                    <IconButton
                        iconVariant={IconVariant.White}
                        svg={SvgIcon.Delete}
                        onClick={e => {
                            e.stopPropagation()
                            onDelete(row.unit)
                        }}
                    />
                </Box>
            )}
        </HoverableBox>
    )
}

const HoverableBox = styled(Box)`
    &:hover {
        background-color: ${Color.ActiveGrey};
    }
`
